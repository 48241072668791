import * as Sentry from '@sentry/nextjs'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_REAL_ENV
const release =
  process.env.NEXT_PUBLIC_BUILD_ID && environment === 'production'
    ? process.env.NEXT_PUBLIC_BUILD_ID
    : undefined

if (dsn && (environment !== 'development' || Number(process.env.NEXT_PUBLIC_WITH_SENTRY) > 0)) {
  Sentry.init({
    dsn,
    environment,
    normalizeDepth: 5,
    release,
  })
}
