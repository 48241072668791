import React from 'react'
import styled from 'styled-components'
import { detect } from 'detect-browser'
import { filter, propEq, not, compose } from 'ramda'

import { fontWeight } from 'uikit/styles/theme'

import iconChrome from './images/chrome.svg'
import iconSafari from './images/safari.svg'
import iconFirefox from './images/firefox.svg'
import iconEdge from './images/edge.svg'

const browser = detect()

const browsersArray = [
  {
    title: 'Google Chrome',
    icon: iconChrome,
    url: 'https://www.google.com/chrome/',
  },
  {
    title: 'Apple Safari',
    icon: iconSafari,
    url: 'https://www.apple.com/safari/',
  },
  {
    title: 'Mozilla Firefox',
    icon: iconFirefox,
    url: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  {
    title: 'Microsoft Edge',
    icon: iconEdge,
    url: 'https://www.microsoft.com/en-US/windows/microsoft-edge',
  },
]

const isWindows = browser.os.includes('Windows')
const withoutSafari = filter(compose(not, propEq('title', 'Apple Safari')))
const withoutEdge = filter(compose(not, propEq('title', 'Microsoft Edge')))
const browsersArrayFiltered = (isWindows ? withoutSafari : withoutEdge)(browsersArray)

const StyledItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTitle = styled.div`
  color: ${props => props.theme.colors.mineShaft};
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;
  font-weight: ${fontWeight.light};
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;
  transition: 150ms;
  &:hover {
    transform: scale(1.15);
  }
`

const StyledIconLink = styled.a`
  display: block;
  border-radius: 50%;
  padding: 13px;
  background: ${props => props.theme.colors.white};
`

const StyledIcon = styled.img`
  display: block;
  width: 54px;
  height: 54px;
`

const Icon = ({ icon, title, url }) => (
  <StyledIconLink href={url}>
    <StyledIcon src={icon} alt={title} title={title} />
  </StyledIconLink>
)

const StyledItemTitle = styled.a`
  display: block;
  margin-top: 16px;
  font-size: 16px;
  font-weight: ${fontWeight.light};
  color: ${props => props.theme.colors.stTropaz};
  &:hover {
    text-decoration: none;
  }
`

const Item = ({ icon, title, url }) => (
  <StyledItem>
    <Icon icon={icon} title={title} url={url} />
    <StyledItemTitle href={url}>{title}</StyledItemTitle>
  </StyledItem>
)

const GreatBrowsers = props => {
  const { title = 'Download the latest version of one of these great browsers to use TAN:' } = props
  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
      <StyledItemsWrapper>
        {browsersArrayFiltered.map((item, index) => (
          <Item key={index} icon={item.icon} title={item.title} url={item.url} />
        ))}
      </StyledItemsWrapper>
    </div>
  )
}

export default GreatBrowsers
