import axios from 'axios'

import config from '../config'
import { loginToken } from '../utils'

const url = path => `${config.apiPath}${path}`

export const fetchAlerts = () => {
  const token = loginToken.get()

  return axios({
    url: url('/alerts'),
    method: 'get',
    headers: { Authorization: `Bearer ${token}` },
  }).then(response => response.data)
}

export const dismissAlert = id => {
  const token = loginToken.get()

  return axios({
    url: url(`/alerts/${id}`),
    method: 'patch',
    data: { alertStatus: 'dismissed' },
    headers: { Authorization: `Bearer ${token}` },
  }).then(r => r.data)
}

export const dismissAlertGroup = group => {
  const token = loginToken.get()

  return axios({
    url: url(`/alerts?group=${group}`),
    method: 'patch',
    data: { alertStatus: 'dismissed' },
    headers: { Authorization: `Bearer ${token}` },
  }).then(r => r.data)
}
