import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import createSentryMiddleware from 'redux-sentry-middleware'
import * as Sentry from '@sentry/nextjs'

import rootReducer from '../reducers/index'

const environment = process.env.NEXT_PUBLIC_REAL_ENV

const sagaMiddleware = createSagaMiddleware()

export default function configureStore() {
  const middlewares = [
    thunkMiddleware,
    sagaMiddleware,
    createSentryMiddleware(Sentry, {
      stateTransformer: () => undefined,
    }),
  ]
  if (environment === 'development') {
    middlewares.push(
      createLogger({
        collapsed: true,
        predicate: () => typeof window !== 'undefined',
      })
    )
  }
  const createStoreWithMiddleware = compose(
    applyMiddleware(...middlewares),
    typeof window !== 'undefined' && window.devToolsExtension ? window.devToolsExtension() : f => f
  )(createStore)

  const store = createStoreWithMiddleware(rootReducer)

  store.runSaga = sagaMiddleware.run

  return store
}
