import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makeSecondaryButton = component => {
  const SecondaryButton = styled(component)`
    && {
      border-color: ${props =>
        props.disabled ? rgba(props.theme.colors.alto, 0.5) : props.theme.colors.alto};
      color: ${props =>
        props.disabled ? rgba(props.theme.colors.doveGray, 0.5) : props.theme.colors.doveGray};
      background-color: ${props =>
        props.disabled ? rgba(props.theme.colors.white, 0.5) : props.theme.colors.white};
      @media (hover: hover) {
        &:hover {
          border-color: ${props => props.theme.colors.stTropaz};
          color: ${props => props.theme.colors.white};
          background-color: ${props => props.theme.colors.stTropaz};
        }
      }
    }
  `

  SecondaryButton.propTypes = {
    appearance: PropTypes.string,
  }

  SecondaryButton.defaultProps = {
    appearance: 'secondary',
  }

  SecondaryButton.displayName = 'SecondaryButton'
  return SecondaryButton
}
export default makeSecondaryButton(Button)
