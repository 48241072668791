import { createSelector } from 'reselect'

/**
 * Get searchterm and query for an autocomplete control
 *
 * @param {string} cid - the client uuid for the control
 * @param {Immutable.Map} autoCompleteStates - the states of autocomplate controls
 */
function getAutoCompleteState(cid, autoCompleteStates) {
  const state = autoCompleteStates.get(cid)
  return state ? state.toJS() : {}
}

/**
 * Get data for a query from the cache
 *
 * @param {string} cacheKey - The cacheKey such as "city:sa"
 * @param {Immutable.Map} locationQueries - The cache keyed by the queries
 *
 * For example
 * getLocationQuery("city:sa", locationQueries)
 */
function getLocationQuery(cacheKey, locationQueries) {
  if (cacheKey) {
    const cache = locationQueries.get(cacheKey)

    return cache ? cache.toJS() : {}
  }

  return {}
}

function createLocationInput(cid, autoCompleteStates, locationQueries, locations) {
  const state = getAutoCompleteState(cid, autoCompleteStates)
  const cache = getLocationQuery(state.cacheKey, locationQueries)

  let data = cache.data || []
  data = data.map(e => locations.get(e.toString()).toJS())

  return {
    cid,
    isLoading: false,
    isLoaded: false,
    ...state,
    ...cache,
    data,
  }
}

const selectBuyerNeedsData = state => state.buyerNeedsForm

export const selectLocationQueries = state => state.locationQueries
export const selectLocationsAutoComplete = state => state.locationsAutoComplete

const selectBuyerNeeds = createSelector([selectBuyerNeedsData], form => form.buyerNeeds.toJS())

const selectLocationInputs = createSelector([selectBuyerNeedsData], form =>
  form.locationInputs.toJS()
)

const selectVisited = createSelector([selectBuyerNeedsData], form => form.visited.toJS())

export const selectLocations = state => state.locations

const selectBuyerNeedsLocationInputs = createSelector(
  [selectLocationInputs, selectLocationsAutoComplete, selectLocationQueries, selectLocations],

  (locationInputs, locationInputsStates, locationQueries, locations) => {
    return locationInputs.map(locationInput => {
      const city = createLocationInput(
        locationInput.cityCid,
        locationInputsStates,
        locationQueries,
        locations
      )
      const neighborhoods = createLocationInput(
        locationInput.neighborhoodsCid,
        locationInputsStates,
        locationQueries,
        locations
      )

      return { city, neighborhoods }
    })
  }
)

function getCityOrCityId(locations, cityId) {
  const city = locations.get(cityId.toString())

  return city ? city.toJS() : cityId
}

export const selectBuyerNeedsForm = createSelector(
  [selectBuyerNeeds, selectBuyerNeedsLocationInputs, selectVisited, selectLocations],
  (buyerNeeds, locationInputs, visited, locations) => {
    let buyerNeedsLocations = buyerNeeds.locations

    buyerNeedsLocations = buyerNeedsLocations.map((location, index) => {
      const locationInput = locationInputs[index]

      const value = location.cityId ? getCityOrCityId(locations, location.cityId) : null
      const city = { ...location.city, ...locationInput.city, value }
      const neighborhoodValues = location.neighborhoodIds
        ? location.neighborhoodIds.map(id => locations.get(id.toString()).toJS())
        : null
      const neighborhoods = { ...locationInput.neighborhoods, value: neighborhoodValues }

      return { city, neighborhoods }
    })

    return { formData: buyerNeeds, ...buyerNeeds, locations: buyerNeedsLocations, visited }
  }
)
