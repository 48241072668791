import { is, isNil } from 'ramda'

/**
 * Determines if the action is an error
 *
 * @param {object} action The action to test
 * @returns {boolean} True if the action is an error, false otherwise.
 */
export const isError = action => action.error === true

const isErrorPayload = is(Error)

/**
 * Construct an action object
 *
 * @param {string} type The type
 * @param {object} payload The payload
 * @param {object} meta Any metadata
 * @param {boolean} error Set to true if the action is an error, if unset
 *  error will be determinied from the contents of the payload
 *
 * @return {object} The action
 */
export const action = (type, payload, meta, error) => ({
  type,
  payload,
  meta,
  error: isNil(error) && isErrorPayload(payload) ? true : error,
})
