import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

import View from '../../helpers/View'
import Label from './Label'
import HelpLabel from './HelpLabel'
import { defaultInputStyles } from 'uikit/styles/theme'

export const StyledTextarea = styled.textarea`
  ${defaultInputStyles}
  font-family: inherit;
  border-color: ${props => {
    if (props.status) {
      switch (props.status) {
        case 'ok':
          return props.theme.colors.chelseaCucumber
        case 'error':
          return props.theme.colors.crimson
        default:
          return props.theme.colors.alto
      }
    }
    return props.theme.colors.alto
  }};
  resize: none;
  &:disabled {
    color: ${props => props.theme.colors.gray};
    background-color: ${props => props.theme.colors.alabasterAlt};
    border-color: ${props => props.theme.colors.gallery};
  }
`

const TextareaField = props => {
  const {
    labelText,
    optionalText,
    helpText,
    placeholderText,
    onChange,
    onKeyDown,
    onBlur,
    onFocus,
    status,
    value,
    rows,
    disabled,
    inputRef,
    id,
  } = props

  return (
    <View borderColor={themeGet('colors.alto')} bg="transparent" border="none" py={0}>
      {labelText || optionalText || helpText ? (
        <div>
          <Label htmlFor={id} labelText={labelText} optionalText={optionalText} />
          {helpText ? <HelpLabel>{helpText}</HelpLabel> : null}
        </div>
      ) : null}
      <View alignItems="center">
        <StyledTextarea
          disabled={disabled}
          placeholder={placeholderText}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          status={status}
          value={value}
          rows={rows || 5}
          type="text"
          ref={inputRef}
          id={id}
        />
      </View>
    </View>
  )
}

export default TextareaField
