import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'

const StyledTipBoxHeader = styled.div`
  background: ${props => props.theme.colors.alabaster};
  position: relative;
  height: auto;
  width: 44px;
  flex-shrink: 0;
`

const IconContainer = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: auto;
  right: -11px;
  width: 44px;
  height: 44px;
  font-size: 28px;
  line-height: 44px;
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  background: ${props => props.theme.colors.tussock};
  align-items: center;
  display: flex;
  justify-content: center;
`

const StyledTipBoxBody = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 20px 15px 20px 30px;
  color: ${props => props.theme.colors.doveGray};
  font-size: 14px;
  line-height: 20px;
`

const StyledTipBoxTitle = styled.h3`
  font-size: 22px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 300;
  color: ${props => props.theme.colors.tuatara};
`

const StyledTipBox = styled.div`
  box-shadow: 1px 1px 5px rgba(46, 46, 45, 0.07);
  display: flex;
  @media (min-width: 767px) {
    ${StyledTipBoxBody} {
      padding: 30px 15px 30px 30px;
    }
    ${StyledTipBoxTitle} {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 992px) {
    flex-direction: column;
    ${StyledTipBoxHeader} {
      height: 44px;
      width: auto;
    }
    ${IconContainer} {
      left: 0;
      right: 0;
      bottom: -22px;
    }
    ${StyledTipBoxBody} {
      padding: 30px;
    }
    ${StyledTipBoxTitle} {
      font-size: 26px;
      text-align: center;
    }
  }
`

const TipBox = ({ title, children }) => (
  <StyledTipBox>
    <StyledTipBoxHeader>
      <IconContainer>
        <Icon icon="light-bulb" size={28} />
      </IconContainer>
    </StyledTipBoxHeader>
    <StyledTipBoxBody>
      <StyledTipBoxTitle>{title}</StyledTipBoxTitle>
      {children}
    </StyledTipBoxBody>
  </StyledTipBox>
)

export default TipBox
