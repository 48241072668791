import { combineReducers } from 'redux'

import {
  CREATE_SESSION_SUCCESS,
  INIT,
  INIT_COMPLETE,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
} from '../constants/actionTypes'
import { IDLE, INITIALIZING, INITIALIZED } from '../constants/initializationStatus'

function isLoggedIn(state = false, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_SESSION_SUCCESS:
      return true

    case LOGOUT:
      return false

    default:
    // Do Nothing
  }

  return state
}

function initializing(state = false, action) {
  switch (action.type) {
    case INIT:
      return true

    case INIT_COMPLETE:
      return false

    default:
    // Do Nothing
  }

  return state
}

function isLoggingOut(state = false, action) {
  switch (action.type) {
    case LOGOUT:
      return true

    case LOGOUT_SUCCESS:
      return false

    default:
    // Do Nothing
  }

  return state
}

function initializationStatus(state = IDLE, action) {
  switch (action.type) {
    case INIT:
      return INITIALIZING

    case INIT_COMPLETE:
      return INITIALIZED

    default:
    // Do Nothing
  }

  return state
}

function token(state = null, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_SESSION_SUCCESS:
      return action.data.accessToken

    case LOGOUT:
      return null

    default:
    // Do Nothing
  }

  return state
}

function expiresAt(state = null, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_SESSION_SUCCESS:
      return action.data.expiresAt

    case LOGOUT:
      return null

    default:
    // Do Nothing
  }

  return state
}

function user(state = null, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case CREATE_SESSION_SUCCESS:
      return action.data.user

    case LOGOUT:
      return null

    default:
      return state
  }
}

export default combineReducers({
  expiresAt,
  initializing,
  initializationStatus,
  isLoggedIn,
  isLoggingOut,
  token,
  user,
})
