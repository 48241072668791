// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var FocusVisible = require("../../helpers/FocusVisible.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function Touchable__Jsx3(props) {
  var __focusStyle = props.focusStyle;
  var __onPress = props.onPress;
  var __m = props.m;
  var __p = props.p;
  var __position_ = props.position;
  var __style_ = props.style;
  var style_ = __style_ !== undefined ? __style_ : /* [] */0;
  var position_ = __position_ !== undefined ? __position_ : "relative";
  var p = __p !== undefined ? __p : "zero";
  var m = __m !== undefined ? __m : "zero";
  var onPress = __onPress !== undefined ? __onPress : (function (prim) {
        
      });
  var focusStyle = __focusStyle !== undefined ? __focusStyle : FocusVisible.focusStyle;
  var match = FocusVisible.useFocusVisible(undefined);
  return JsxRuntime.jsx("button", {
              children: props.children,
              className: Curry._1(Css.style, Core__List.concatMany([
                        {
                          hd: Css.position(position_),
                          tl: {
                            hd: Css.border("zero", "none", "transparent"),
                            tl: {
                              hd: Css.unsafe("color", "inherit"),
                              tl: {
                                hd: Css.unsafe("font", "inherit"),
                                tl: {
                                  hd: Css.textAlign(Css.inherit_),
                                  tl: {
                                    hd: Css.unsafe("WebkitFontSmoothing", "inherit"),
                                    tl: {
                                      hd: Css.unsafe("WebkitAppearance", "none"),
                                      tl: {
                                        hd: Css.lineHeight("normal"),
                                        tl: {
                                          hd: Css.background("transparent"),
                                          tl: {
                                            hd: Css.overflow("visible"),
                                            tl: {
                                              hd: Css.width("auto"),
                                              tl: {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.padding(p),
                                                  tl: {
                                                    hd: Css.margin(m),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        focusStyle,
                        style_
                      ])) + match.className,
              id: props.id,
              disabled: props.disabled,
              onFocus: match.onFocus,
              onBlur: match.onBlur,
              onClick: (function (e) {
                  var target = e.currentTarget;
                  target.blur();
                  Curry._1(onPress, e);
                })
            });
}

var make = Touchable__Jsx3;

var $$default = Touchable__Jsx3;

exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* Css Not a pure module */
