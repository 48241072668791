// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRender = require("../../../../reason/utils/ReactRender.bs.js");
var FocusVisible = require("../../helpers/FocusVisible.bs.js");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../../reason/common/Theme/Theme__Color__Vars.bs.js");

var hiddenRadio = Curry._1(Css.style, {
      hd: Css.border("zero", "none", "transparent"),
      tl: {
        hd: Css.unsafe("clip", "rect(1px, 1px, 1px, 1px)"),
        tl: {
          hd: Css.height({
                NAME: "px",
                VAL: 1
              }),
          tl: {
            hd: Css.overflow("hidden"),
            tl: {
              hd: Css.padding("zero"),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.whiteSpace("nowrap"),
                  tl: {
                    hd: Css.width({
                          NAME: "px",
                          VAL: 1
                        }),
                    tl: {
                      hd: Css.opacity(0),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "px",
            VAL: 4
          }, {
            NAME: "px",
            VAL: 10
          }),
      tl: {
        hd: Css.height({
              NAME: "px",
              VAL: 30
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.color(Theme__Color__Vars.doveGray),
              tl: {
                hd: Css.border({
                      NAME: "px",
                      VAL: 1
                    }, "solid", Theme__Color__Vars.alto),
                tl: {
                  hd: Css.borderLeftWidth({
                        NAME: "px",
                        VAL: 0
                      }),
                  tl: {
                    hd: Css.fontWeight({
                          NAME: "num",
                          VAL: 500
                        }),
                    tl: {
                      hd: Css.firstOfType({
                            hd: Css.borderLeftWidth({
                                  NAME: "px",
                                  VAL: 1
                                }),
                            tl: {
                              hd: Css.borderTopLeftRadius({
                                    NAME: "px",
                                    VAL: 4
                                  }),
                              tl: {
                                hd: Css.borderBottomLeftRadius({
                                      NAME: "px",
                                      VAL: 4
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: {
                        hd: Css.lastOfType({
                              hd: Css.borderTopRightRadius({
                                    NAME: "px",
                                    VAL: 4
                                  }),
                              tl: {
                                hd: Css.borderBottomRightRadius({
                                      NAME: "px",
                                      VAL: 4
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.backgroundColor(Theme__Color__Vars.white),
                          tl: {
                            hd: Css.selector("input:checked + &", {
                                  hd: Css.borderColor(Theme__Color__Vars.stTropaz),
                                  tl: {
                                    hd: Css.backgroundColor(Theme__Color__Vars.stTropaz),
                                    tl: {
                                      hd: Css.color(Theme__Color__Vars.white),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: {
                              hd: Css.selector("input:checked + &:not(:first-of-type)", {
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box({
                                              NAME: "px",
                                              VAL: 1
                                            }, {
                                              NAME: "px",
                                              VAL: 0
                                            }, undefined, undefined, undefined, Theme__Color__Vars.stTropaz)),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.transitionList({
                                      hd: Css_Legacy_Core.Transition.shorthand(300, undefined, undefined, "color"),
                                      tl: {
                                        hd: Css_Legacy_Core.Transition.shorthand(300, undefined, undefined, "background-color"),
                                        tl: {
                                          hd: Css_Legacy_Core.Transition.shorthand(300, undefined, undefined, "border-color"),
                                          tl: {
                                            hd: Css_Legacy_Core.Transition.shorthand(300, undefined, undefined, "box-shadow"),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.selector(".data-focus-visible + &", {
                                          hd: FocusVisible.focusVisibleFilterShadow,
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  hiddenRadio: hiddenRadio,
  label: label
};

function RadioButton(props) {
  var additionalLabelStyle = props.additionalLabelStyle;
  var id = props.id;
  var match = FocusVisible.useFocusVisible(undefined);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("input", {
                      className: Curry._1(Css.merge, {
                            hd: hiddenRadio,
                            tl: {
                              hd: match.className,
                              tl: /* [] */0
                            }
                          }),
                      id: id,
                      checked: props.checked,
                      name: props.name,
                      type: "radio",
                      onFocus: match.onFocus,
                      onBlur: match.onBlur,
                      onChange: props.onChange
                    }),
                JsxRuntime.jsxs("label", {
                      children: [
                        ReactRender.fromOption(props.label, (function (label) {
                                return label;
                              })),
                        ReactRender.fromOption(props.children, (function (children) {
                                return children;
                              }))
                      ],
                      className: additionalLabelStyle !== undefined ? Curry._1(Css.merge, {
                              hd: label,
                              tl: {
                                hd: additionalLabelStyle,
                                tl: /* [] */0
                              }
                            }) : label,
                      htmlFor: id
                    })
              ]
            });
}

function RadioButton$RadioButtonGroup(props) {
  var __fullWidth = props.fullWidth;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  return JsxRuntime.jsx(View__Jsx3.make, {
              flexDirection: "row",
              className: Curry._1(Css.style, fullWidth ? ({
                        hd: Css.selector("& > label", {
                              hd: Css.flexGrow(1.0),
                              tl: {
                                hd: Css.flexBasis({
                                      NAME: "percent",
                                      VAL: 0.0
                                    }),
                                tl: {
                                  hd: Css.flexShrink(1.0),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }) : /* [] */0),
              children: Caml_option.some(props.children)
            });
}

var RadioButtonGroup = {
  make: RadioButton$RadioButtonGroup
};

var make = RadioButton;

var $$default = RadioButton;

exports.Styles = Styles;
exports.make = make;
exports.RadioButtonGroup = RadioButtonGroup;
exports.default = $$default;
exports.__esModule = true;
/* hiddenRadio Not a pure module */
