import { fromJS } from 'immutable'
import { combineReducers } from 'redux'

import {
  Alert,
  AlertMap,
  Agent,
  AgentMap,
  AgentMatchLog,
  AgentMatchLogMap,
  Client,
  ClientMap,
  Contact,
  ContactMap,
} from './models'

import { LOGOUT, NEW_ALERT, FETCH_ALERTS_SUCCESS } from '../../constants/actionTypes'
import * as t from '../../modules/NotificationAlert/actionTypes'

const createEntityReducer = (entityKey, EntityClass, MapClass) => {
  const mergeEntities = (state, newEntities) =>
    state.merge(newEntities.map(entity => new EntityClass(entity)))

  const mergePartialEntities = (state, newEntities) =>
    state.merge(
      newEntities.map((entity, id) => {
        const existing = state.get(id, new EntityClass())

        return existing.merge(entity)
      })
    )

  const initialState = new MapClass()

  return (state = initialState, action) => {
    if (action.type === NEW_ALERT) {
      if (!action.data.entities[entityKey]) {
        return state
      }

      return mergeEntities(state, fromJS(action.data.entities[entityKey]))
    }

    if (action.type === LOGOUT) {
      return initialState
    }

    if (action.type === FETCH_ALERTS_SUCCESS) {
      if (!action.data.entities[entityKey]) {
        return state
      }

      return mergeEntities(state, fromJS(action.data.entities[entityKey]))
    }

    if (action.type === t.REFRESH_ALERT) {
      if (!action.data.entities[entityKey]) {
        return state
      }

      return mergePartialEntities(state, fromJS(action.data.entities[entityKey]))
    }

    if (action.type === t.DISMISS_ALERT_GROUP_SUCCESS) {
      if (!action.data.entities[entityKey]) {
        return state
      }

      return mergeEntities(state, fromJS(action.data.entities[entityKey]))
    }

    return state
  }
}

const alerts = createEntityReducer('alerts', Alert, AlertMap)
const agentMatchLogs = createEntityReducer('agentMatchLogs', AgentMatchLog, AgentMatchLogMap)
const clients = createEntityReducer('clients', Client, ClientMap)
const contacts = createEntityReducer('contacts', Contact, ContactMap)
const agents = createEntityReducer('agents', Agent, AgentMap)

const alertEntities = combineReducers({
  alerts,
  agents,
  agentMatchLogs,
  clients,
  contacts,
})

export default alertEntities
