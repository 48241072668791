// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

var Chapter = {};

function toString(x) {
  if (x === "INITIATE_PROPERTY_CONVERSATION") {
    return "INITIATE_PROPERTY_CONVERSATION";
  } else if (x === "SHARE_WITH_CLIENT") {
    return "SHARE_WITH_CLIENT";
  } else if (x === "VIEWING_AGENT") {
    return "VIEWING_AGENT";
  } else if (x === "GNS") {
    return "GNS";
  } else if (x === "CLIENT_ACCESS") {
    return "CLIENT_ACCESS";
  } else if (x === "FORWARD_PROPERTY_EMAIL") {
    return "FORWARD_PROPERTY_EMAIL";
  } else if (x === "PROPERTY_DETAILS") {
    return "PROPERTY_DETAILS";
  } else if (x === "HIDE_PROPERTY") {
    return "HIDE_PROPERTY";
  } else {
    return "FULL_AGENT_PAGE";
  }
}

function ofString(x) {
  switch (x) {
    case "CLIENT_ACCESS" :
        return "CLIENT_ACCESS";
    case "FORWARD_PROPERTY_EMAIL" :
        return "FORWARD_PROPERTY_EMAIL";
    case "FULL_AGENT_PAGE" :
        return "FULL_AGENT_PAGE";
    case "GNS" :
        return "GNS";
    case "HIDE_PROPERTY" :
        return "HIDE_PROPERTY";
    case "INITIATE_PROPERTY_CONVERSATION" :
        return "INITIATE_PROPERTY_CONVERSATION";
    case "PROPERTY_DETAILS" :
        return "PROPERTY_DETAILS";
    case "SHARE_WITH_CLIENT" :
        return "SHARE_WITH_CLIENT";
    case "VIEWING_AGENT" :
        return "VIEWING_AGENT";
    default:
      return ;
  }
}

var Feature = {
  toString: toString,
  ofString: ofString
};

function isFree(x) {
  return x.type_ === "FREE";
}

function isTrial(x) {
  return x.handle === "PRO_TRIAL";
}

function hasFeature(plan, feature) {
  return Core__Option.isSome(Core__List.getBy(plan.features, (function (enabledFeature) {
                    return enabledFeature === feature;
                  })));
}

var Plan_defaultPlan = {
  type_: "FREE",
  handle: "FREE",
  onProFree: false,
  features: /* [] */0
};

var Plan = {
  Feature: Feature,
  defaultPlan: Plan_defaultPlan,
  isFree: isFree,
  isTrial: isTrial,
  hasFeature: hasFeature
};

var Permission = {};

var Agent = {};

var Client = {};

exports.Chapter = Chapter;
exports.Plan = Plan;
exports.Permission = Permission;
exports.Agent = Agent;
exports.Client = Client;
/* No side effect */
