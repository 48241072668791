import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { themeGet, space, borders, fontSize, style } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})

const StyledTextLabel = styled.div.withConfig({ shouldForwardProp })`
  display: inline-flex;
  align-items: center;
  height: 26px;
  font-weight: ${themeGet('fontWeight.bold')};
  line-height: 1;
  position: ${props => (props.explanationMark ? 'relative' : 'initial')};
  border-style: solid;
  border-width: 1px;
  padding: 1px 10px;
  cursor: default;
  white-space: nowrap;
  flex-shrink: 0;
  ${space};
  ${borders};
  ${fontSize};
  ${textTransform};
  background-color: ${props => props.bg};
  border-color: ${props => props.borderColor};
  color: ${props => props.color};
`

const StyledExplanationText = styled.div.withConfig({ shouldForwardProp })`
  display: none;
  position: absolute;
  padding: 8px 12px;
  background: ${props => rgba(props.theme.colors.mineShaft, 0.95)};
  line-height: 1.25;
  text-transform: none;
  min-width: 150px;
  left: 9px;
  bottom: calc(100% + 13px);
  transform: translate(-50%, 0);
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 8px 0 8px;
    border-color: ${props => rgba(props.theme.colors.mineShaft, 0.95)} transparent transparent
      transparent;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`

const StyledExplanationMark = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${props => props.theme.colors.crimson};
  top: -9px;
  right: -10px;
  font-weight: ${themeGet('fontWeight.medium')};
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  &:hover > div {
    display: block;
  }
`

/* eslint-disable no-shadow */
const TextLabel = ({
  color = themeGet('colors.tuatara'),
  borderColor = themeGet('colors.alto'),
  borderRadius = '4px',
  bg = themeGet('colors.white'),
  fontSize = '12px',
  textTransform = 'uppercase',
  explanationMark,
  children,
  ...etc
}) => {
  return (
    <StyledTextLabel
      color={color}
      borderColor={borderColor}
      borderRadius={borderRadius}
      bg={bg}
      fontSize={fontSize}
      textTransform={textTransform}
      {...etc}
      explanationMark={explanationMark}
    >
      {children}
      {explanationMark && (
        <StyledExplanationMark>
          <span>!</span>
          <StyledExplanationText>{explanationMark}</StyledExplanationText>
        </StyledExplanationMark>
      )}
    </StyledTextLabel>
  )
}

TextLabel.displayName = 'TextLabel'

export default TextLabel
