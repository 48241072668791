import React from 'react'
import styled from 'styled-components'
import VisuallyHidden from '@reach/visually-hidden'
import { space, themeGet } from 'styled-system'
import Touchable from '../../reason/atoms/Touchable/Touchable__Jsx3.bs'
import Icon from '../Icon'

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 10px;
`

const StyledPillLabel = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  padding: 6px 6px 6px 8px;
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.alto};
  background: ${props => props.theme.colors.wildSand};
  color: ${props => props.theme.colors.jumbo};
  white-space: nowrap;
`

export const PillLabel = ({ children, closeHandler }) => {
  return (
    <StyledPillLabel>
      <div>{children}</div>
      {closeHandler && (
        <Touchable onPress={closeHandler}>
          <VisuallyHidden>Remove</VisuallyHidden>
          <StyledIcon aria-hidden icon="cancel-circled" color={themeGet('colors.chicago')} />
        </Touchable>
      )}
    </StyledPillLabel>
  )
}

const StyledPillLabelsGroup = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  li {
    margin-bottom: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
    display: flex;
  }
  ${space}
`

export const PillLabelsGroup = ({ children, ariaLabel, ...restProps }) => (
  <StyledPillLabelsGroup {...restProps} aria-label={ariaLabel}>
    {React.Children.map(children, child => (
      <li>{child}</li>
    ))}
  </StyledPillLabelsGroup>
)

export default PillLabel
