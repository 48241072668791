import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { themeGet } from 'styled-system'
import { useFocusVisible } from '../../helpers/FocusVisible'
import Image from 'next/image'

import { mediaBreakpointUp } from 'styles/media'

import Icon from '../../atoms/Icon'

const HiddenInput = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border: none;
  z-index: 0;
`
HiddenInput.displayName = 'HiddenInput'

const SelectableCardOuterWrapper = styled.div`
  & + & {
    margin-top: 20px;
  }
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`
SelectableCardOuterWrapper.displayName = 'SelectableCardOuterWrapper'

export const ContentHolder = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px;
`

ContentHolder.displayName = 'ContentHolder'

const SelectableCardOverlay = styled.div`
  &:hover {
    background-color: rgba(0, 0, 0, 0.01);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  transition: all 0.15s ease;
  grid-area: 1/1;
`
SelectableCardOverlay.displayName = 'SelectableCardOverlay'

const SelectableCardWrapper = styled.label`
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: all 0.15s ease;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border-style: solid;
  color: ${props => props.theme.mineShaft};
  border-color: ${props => props.borderColor || props.theme.colors.alto};
  border-width: 1px;
  /* This component has to be a label to make selection work, but that inherits
   * font-weight from the global styles, so we unset here to get normal appearance. */
  font-weight: unset;
  /* The grid is used to make the overlay work without stacking conflicts, which were occuring
   * when implementing this with position:absolute causing mouse events on children 
   * (specifically a ReachUI tooltip on a label) to stop working. */
  display: grid;
  & > ${ContentHolder} {
    grid-area: 1/1;
  }
  @media (hover: none) {
    /* Apply a shadow on touch devices only to make the element look touchable, since
     * they don't have the hover state */
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  }
  &:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%), 0 3px 7px -3px rgb(0 0 0 / 30%);
    transform: translateY(1px);
  }
  &:hover ${SelectableCardOverlay} {
    background-color: rgba(0, 0, 0, 0.01);
  }
  &:active ${SelectableCardOverlay} {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-color: transparent;
    border-style: solid;
    border-width: unset;
  }
  &:after {
    content: none;
    font-size: 13px;
    line-height: 11px;
    font-weight: bold;
    color: white;
  }
  ${HiddenInput}:checked + &:after {
    content: '✓';
  }
  &:before {
    border-radius: 0;
    border-width: 13px;
    border-color: transparent;
    transition: all 0.15s ease;
  }
  ${HiddenInput}:checked + &:before {
    border-left-color: var(--color-wedgewood);
    border-top-color: var(--color-wedgewood);
  }
  ${HiddenInput}:checked + & {
    border-color: var(--color-wedgewood);
  }
  [data-focus-visible] + & {
    box-shadow: 0px 0px 6px rgb(47 113 165 / 90%);
  }
`

SelectableCardWrapper.displayName = 'SelectableCardWrapper'

const SelectableCardContentHolder = styled.div`
  padding: 20px;
`
SelectableCardContentHolder.displayName = 'SelectableCardContentHolder'

const ImageHolder = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 200px;
  background: ${props => props.theme.colors.alto};
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaBreakpointUp('sm')`
    height: 300px;
  `};
  ${mediaBreakpointUp('md')`
    height: auto;
    width: 250px;
  `};
  ${mediaBreakpointUp('lg')`
    width: 314px;
  `};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
`

ImageHolder.displayName = 'ImageHolder'

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 20px;
  }
  ${mediaBreakpointUp('md')`
    flex-direction: row;
  `};
  border-style: solid;
  border-color: ${props => props.borderColor || props.theme.colors.alto};
  background-color: ${props => props.backgroundColor || props.theme.colors.white};
  border-width: 1px;
  box-shadow: ${props => (props.withShadow ? '1px 1px 3px rgba(51,51,51,.08)' : 'none')};
`

CardWrapper.displayName = 'CardWrapper'

const ImageDescription = styled.div`
  background: ${props => rgba(props.theme.colors.tuatara, 0.7)};
  height: 30px;
  display: flex;
  align-items: center;
  padding: 2px 16px;
  color: ${themeGet('colors.white')};
  font-size: 13px;
  line-height: 1.25;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`

ImageDescription.displayName = 'ImageDescription'

export const Card = ({ children, withShadow, borderColor, backgroundColor }) => {
  return (
    <CardWrapper
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      withShadow={withShadow}
    >
      <ContentHolder>{children}</ContentHolder>
    </CardWrapper>
  )
}

export const SelectableCard = ({
  children,
  withShadow,
  borderColor,
  selected,
  onChange,
  id,
  onFocus,
  onBlur,
}) => {
  const focusProps = useFocusVisible({ onFocus, onBlur })
  return (
    <SelectableCardOuterWrapper>
      <HiddenInput
        type="checkbox"
        checked={selected ? 'checked' : ''}
        onChange={onChange}
        id={id}
        {...focusProps}
      />
      <SelectableCardWrapper
        selected={selected}
        borderColor={borderColor}
        withShadow={withShadow}
        htmlFor={id}
      >
        <SelectableCardContentHolder>{children}</SelectableCardContentHolder>
        <SelectableCardOverlay />
      </SelectableCardWrapper>
    </SelectableCardOuterWrapper>
  )
}

export const CardWithImage = ({
  imageSrc,
  imageOnClick,
  fallbackIcon,
  imageDescription,
  children,
}) => (
  <CardWrapper>
    <ImageHolder onClick={imageOnClick}>
      {imageSrc ? (
        <Image alt="" layout="fill" objectFit="cover" unoptimized src={imageSrc} />
      ) : (
        <Icon icon={fallbackIcon || 'image'} size={78} color={themeGet('colors.gray')} />
      )}
      {imageDescription && <ImageDescription>{imageDescription}</ImageDescription>}
    </ImageHolder>
    <ContentHolder>{children}</ContentHolder>
  </CardWrapper>
)

// Auixiliary components below this point

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaBreakpointUp('lg')`
    flex-direction: row;
  `};
`

CardHeader.displayName = 'CardHeader'

export const CardTitle = styled.h3`
  font-size: 16px;
  line-height: 1.25;
  font-weight: ${themeGet('fontWeight.medium')};
  color: ${themeGet('colors.tuatara')};
  margin: 0;
  ${mediaBreakpointUp('sm')`
    font-size: 22px;
  `};
`

CardTitle.displayName = 'CardTitle'

export const Address = styled.h6`
  color: ${themeGet('colors.gray')};
  font-weight: ${themeGet('fontWeight.regular')};
  font-size: 14px;
  line-height: 1;
`
Address.displayName = 'Address'

export const CardTitleHolder = styled.div`
  flex-grow: 1;
`

CardTitleHolder.displayName = 'CardTitleHolder'

export const ButtonsHolder = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  ${mediaBreakpointUp('sm')`
    flex-direction: row;
    align-items: flex-end;
  `};
  flex-grow: 2;
`

ButtonsHolder.displayName = 'ButtonsHolder'

export const HiddenOnTabletText = styled.span`
  display: inline;
  margin-left: 4px;
  ${mediaBreakpointUp('md')`
    display: none;
  `};
  ${mediaBreakpointUp('lg')`
    display: inline;
  `};
`

HiddenOnTabletText.displayName = 'HiddenOnTabletText'
