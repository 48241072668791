// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var GraphQLConverter = require("../GraphQLConverter.bs.js");
var Utils__AttachmentUrl = require("../../../utils/Utils__AttachmentUrl.bs.js");

function isImage(param) {
  return param.contentType.startsWith("image/");
}

function make(id, key, fileName, contentType) {
  return {
          id: id,
          key: key,
          fileName: fileName,
          contentType: contentType
        };
}

function decode(obj) {
  var partial_arg = GraphQLConverter.Helpers.some(obj.contentType)("missing contentType");
  var partial_arg$1 = GraphQLConverter.Helpers.some(obj.fileName)("missing filename");
  var partial_arg$2 = GraphQLConverter.Helpers.some(obj.key)("missing key");
  var partial_arg$3 = GraphQLConverter.Helpers.$$const(obj.id);
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$3, param);
  };
  var partial_arg$5 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$4, partial_arg$2, param);
  };
  var partial_arg$6 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$5, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(make)(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$6, partial_arg, param);
            });
}

function encode(v) {
  return {
          id: v.id,
          key: v.key,
          fileName: v.fileName,
          contentType: v.contentType
        };
}

var ppx_printed_query = "fragment attachment on Attachment   {\nid  \nkey  \nfileName  \ncontentType  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$2 = Js_dict.get(value$1, "id");
  var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$3 = Js_dict.get(value$1, "key");
  var field_key = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
  var value$4 = Js_dict.get(value$1, "fileName");
  var field_fileName = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
  var value$5 = Js_dict.get(value$1, "contentType");
  var field_contentType = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
  return {
          id: field_id,
          key: field_key,
          fileName: field_fileName,
          contentType: field_contentType
        };
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Attachment = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "attachment",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "fragment decodedAttachment on Attachment   {\nid  \nkey  \nfileName  \ncontentType  \n}\n";

function parse$1(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "key");
    var field_key = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "fileName");
    var field_fileName = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "contentType");
    var field_contentType = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    tmp = {
      id: field_id,
      key: field_key,
      fileName: field_fileName,
      contentType: field_contentType
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var DecodedAttachment = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: "decodedAttachment",
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var Fragment = {
  Attachment: Attachment,
  DecodedAttachment: DecodedAttachment
};

function makeURL(width, height, crop, embed, ratio, background, v) {
  return Utils__AttachmentUrl.makeAttachmentUrl(width, height, crop, embed, ratio, background, v.key);
}

exports.isImage = isImage;
exports.make = make;
exports.decode = decode;
exports.encode = encode;
exports.Fragment = Fragment;
exports.makeURL = makeURL;
/* GraphQLConverter Not a pure module */
