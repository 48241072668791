import styled from 'styled-components'

const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const baseBr = styled.br`
  display: none;
`

const BrXs = styled(baseBr)`
  @media (max-width: ${breakPoints.sm - 1}px) {
    display: block;
  }
`

const BrSm = styled(baseBr)`
  @media (min-width: ${breakPoints.sm}px) and (max-width: ${breakPoints.md - 1}px) {
    display: block;
  }
`

const BrMd = styled(baseBr)`
  @media (min-width: ${breakPoints.md}px) and (max-width: ${breakPoints.lg - 1}px) {
    display: block;
  }
`

const BrLg = styled(baseBr)`
  @media (min-width: ${breakPoints.lg}px) and (max-width: ${breakPoints.xl - 1}px) {
    display: block;
  }
`

const BrXl = styled(baseBr)`
  @media (min-width: ${breakPoints.xl}px) {
    display: block;
  }
`

export { BrXs, BrSm, BrMd, BrLg, BrXl }
