// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var white = {
  NAME: "var",
  VAL: "--color-white"
};

var black = {
  NAME: "var",
  VAL: "--color-black"
};

var alabaster = {
  NAME: "var",
  VAL: "--color-alabaster"
};

var alabasterAlt = {
  NAME: "var",
  VAL: "--color-alabaster-alt"
};

var wildSand = {
  NAME: "var",
  VAL: "--color-wild-sand"
};

var gallery = {
  NAME: "var",
  VAL: "--color-gallery"
};

var alto = {
  NAME: "var",
  VAL: "--color-alto"
};

var silverSand = {
  NAME: "var",
  VAL: "--color-silver-sand"
};

var silver = {
  NAME: "var",
  VAL: "--color-silver"
};

var gray = {
  NAME: "var",
  VAL: "--color-gray"
};

var jumbo = {
  NAME: "var",
  VAL: "--color-jumbo"
};

var doveGray = {
  NAME: "var",
  VAL: "--color-dove-gray"
};

var chicago = {
  NAME: "var",
  VAL: "--color-chicago"
};

var mineShaft = {
  NAME: "var",
  VAL: "--color-mine-shaft"
};

var tuatara = {
  NAME: "var",
  VAL: "--color-tuatara"
};

var oldLace = {
  NAME: "var",
  VAL: "--color-old-lace"
};

var citrineWhite = {
  NAME: "var",
  VAL: "--color-citrine-white"
};

var chino = {
  NAME: "var",
  VAL: "--color-chino"
};

var driftwood = {
  NAME: "var",
  VAL: "--color-driftwood"
};

var tussock = {
  NAME: "var",
  VAL: "--color-tussock"
};

var goldTips = {
  NAME: "var",
  VAL: "--color-gold-tips"
};

var galliano = {
  NAME: "var",
  VAL: "--color-galliano"
};

var darkGoldenrod = {
  NAME: "var",
  VAL: "--color-dark-goldenrod"
};

var aquaHaze = {
  NAME: "var",
  VAL: "--color-aqua-haze"
};

var mystic = {
  NAME: "var",
  VAL: "--color-mystic"
};

var wedgewood = {
  NAME: "var",
  VAL: "--color-wedgewood"
};

var calypso = {
  NAME: "var",
  VAL: "--color-calypso"
};

var stTropaz = {
  NAME: "var",
  VAL: "--color-st-tropaz"
};

var astral = {
  NAME: "var",
  VAL: "--color-astral"
};

var biscay = {
  NAME: "var",
  VAL: "--color-biscay"
};

var bigStone = {
  NAME: "var",
  VAL: "--color-big-stone"
};

var bigStoneAlt = {
  NAME: "var",
  VAL: "--color-big-stone-alt"
};

var bigStoneDark = {
  NAME: "var",
  VAL: "--color-big-stone-dark"
};

var peppermint = {
  NAME: "var",
  VAL: "--color-peppermint"
};

var chelseaCucumber = {
  NAME: "var",
  VAL: "--color-chelsea-cucumber"
};

var apple = {
  NAME: "var",
  VAL: "--color-apple"
};

var vidaLoca = {
  NAME: "var",
  VAL: "--color-vida-loca"
};

var crimson = {
  NAME: "var",
  VAL: "--color-crimson"
};

var monza = {
  NAME: "var",
  VAL: "--color-monza"
};

var carmine = {
  NAME: "var",
  VAL: "--color-carmine"
};

var cherub = {
  NAME: "var",
  VAL: "--color-cherub"
};

var whiteLinen = {
  NAME: "var",
  VAL: "--color-white-linen"
};

var facebook = {
  NAME: "var",
  VAL: "--color-facebook"
};

var twitter = {
  NAME: "var",
  VAL: "--color-twitter"
};

var instagram = {
  NAME: "var",
  VAL: "--color-instagram"
};

var yelp = {
  NAME: "var",
  VAL: "--color-yelp"
};

var linkedin = {
  NAME: "var",
  VAL: "--color-linkedin"
};

var youtube = {
  NAME: "var",
  VAL: "--color-youtube"
};

var pinterest = {
  NAME: "var",
  VAL: "--color-pinterest"
};

exports.white = white;
exports.black = black;
exports.alabaster = alabaster;
exports.alabasterAlt = alabasterAlt;
exports.wildSand = wildSand;
exports.gallery = gallery;
exports.alto = alto;
exports.silverSand = silverSand;
exports.silver = silver;
exports.gray = gray;
exports.jumbo = jumbo;
exports.doveGray = doveGray;
exports.chicago = chicago;
exports.mineShaft = mineShaft;
exports.tuatara = tuatara;
exports.oldLace = oldLace;
exports.citrineWhite = citrineWhite;
exports.chino = chino;
exports.driftwood = driftwood;
exports.tussock = tussock;
exports.goldTips = goldTips;
exports.galliano = galliano;
exports.darkGoldenrod = darkGoldenrod;
exports.aquaHaze = aquaHaze;
exports.mystic = mystic;
exports.wedgewood = wedgewood;
exports.calypso = calypso;
exports.stTropaz = stTropaz;
exports.astral = astral;
exports.biscay = biscay;
exports.bigStone = bigStone;
exports.bigStoneAlt = bigStoneAlt;
exports.bigStoneDark = bigStoneDark;
exports.peppermint = peppermint;
exports.chelseaCucumber = chelseaCucumber;
exports.apple = apple;
exports.vidaLoca = vidaLoca;
exports.crimson = crimson;
exports.monza = monza;
exports.carmine = carmine;
exports.cherub = cherub;
exports.whiteLinen = whiteLinen;
exports.facebook = facebook;
exports.twitter = twitter;
exports.instagram = instagram;
exports.yelp = yelp;
exports.linkedin = linkedin;
exports.youtube = youtube;
exports.pinterest = pinterest;
/* No side effect */
