/* Colors */

/* http://chir.ag/projects/name-that-color is used to get colors names */
// Basic colors
export const white = '#1a1a1a'
export const black = '#fff'

// Gray Colors
export const alabaster = '#111'
export const alabasterAlt = '#222'
export const wildSand = '#333'
export const gallery = '#444'
export const alto = '#555'
export const silverSand = '#bbb'
export const silver = '#ccc'
export const gray = '#cfcfcf'
export const jumbo = '#d5d5d5'
export const doveGray = '#e0e0e0'
export const chicago = '#f0f0f0'

// Dark Colors
export const mineShaft = '#ddd'
export const tuatara = '#eee'

// Yellow Colors
export const oldLace = '#fdfaec'
export const citrineWhite = '#f9f2d1'
export const chino = '#d2ccb0'
export const driftwood = '#a88542'
export const tussock = '#c99f50'
export const goldTips = '#e9be14'
export const galliano = '#dfbc0c'
export const darkGoldenrod = '#b68f11'

// Blue Colors
export const aquaHaze = '#e9eff3'
export const mystic = '#e3ebf0'
export const wedgewood = '#44799b'
export const calypso = '#2c6585'
export const stTropaz = '#a2d0f3'
export const astral = '#2f71a5'
export const biscay = '#1e4a6b'
export const bigStone = '#1b3549'
export const bigStoneAlt = '#162b3b'
export const bigStoneDark = '#10202c'

// Green Colors
export const peppermint = '#eaf4e4'
export const chelseaCucumber = '#74b34c'
export const apple = '#61a03a'
export const vidaLoca = '#46861f'

// Red Colors
export const crimson = '#dc1a43'
export const monza = '#c20031'
export const carmine = '#a3001c'

// Pale Colors
export const cherub = '#fadde3'
export const whiteLinen = '#f7f1e5'

// Brand Colors
export const facebook = '#3b5998'
export const twitter = '#1da1f2'
export const instagram = '#e1306c'
export const yelp = '#af0606'
export const linkedin = '#0077b5'
export const youtube = '#cd201f'
export const pinterest = '#bd081c'
