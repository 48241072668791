import React from 'react'

import PropTypes from 'prop-types'

import { H1, H2, H3, H4, H5, H6 } from './Headings'

const componentMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
}

const Heading = ({ as, ...props }) => {
  const Comp = componentMap[as] ? componentMap[as] : componentMap.h3

  return <Comp {...props} />
}

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

export default Heading
