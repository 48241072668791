import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { mediaBreakpointUp } from 'styles/media'
import Stat from 'uikit/atoms/Stat'

export const StatsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  ${mediaBreakpointUp('md')`
    flex-direction: row;
  `};
  ${space};
`

StatsWrapper.displayName = 'StatsWrapper'

export const StatsWidget = ({ stats, ...restProps }) => {
  return (
    <StatsWrapper {...restProps}>
      {stats.map(stat => (
        <Stat key={stat.label + stat.value} label={stat.label} value={stat.value} />
      ))}
    </StatsWrapper>
  )
}

export default StatsWidget
