import { all, call, put, fork, takeEvery } from 'redux-saga/effects'

import {
  CREATE_SESSION_SUCCESS,
  LOGIN_SUCCESS,
  NEW_ALERT,
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_ERROR,
} from '../constants/actionTypes'

import * as t from '../modules/NotificationAlert/actionTypes'

import * as api from '../api/alertsApi'

import { subscribe } from '../utils/socket'

import { normalizeAlert, normalizerFor, normalizeAlerts } from '../schemas/alerts'

const augmentConversationMessageAlert = r =>
  r.alertType === 'ConversationMessageNotification' && r.alertStatus !== 'dismissed'
    ? {
        ...r,
        agent: r.message.sender,
        conversation: {
          ...r.conversation,
          context: { ...r.context, id: `${r.context.context_type}:${r.context.context_id}` },
          message: r.message,
        },
      }
    : r

function* handler(update) {
  const { alertType } = update

  yield put({
    type: NEW_ALERT,
    data: normalizerFor(alertType)(augmentConversationMessageAlert(update)),
  })
}

function* fetchAlerts(action) {
  const userRole = action.data.user.role

  if (userRole === 'agent') {
    yield put({ ...action, type: FETCH_ALERTS_REQUEST })

    try {
      const result = (yield call(api.fetchAlerts)).map(augmentConversationMessageAlert)

      yield put({
        type: FETCH_ALERTS_SUCCESS,
        data: normalizeAlerts(result),
      })
    } catch (e) {
      yield put({
        type: FETCH_ALERTS_ERROR,
        error: e,
      })
    }
  }
}

function* dismissAlert(action) {
  const { id, previousStatus } = action.payload
  yield put({
    type: t.REFRESH_ALERT,
    data: normalizeAlert({ id, alertStatus: 'dismissed' }),
  })

  try {
    const results = yield call(api.dismissAlert, id)

    yield put({
      type: t.DISMISS_ALERT_SUCCESS,
      data: normalizeAlert(results),
    })
  } catch (e) {
    yield put({
      type: t.REFRESH_ALERT,
      data: normalizeAlert({ id, alertStatus: previousStatus }),
    })

    yield put({
      type: t.DISMISS_ALERT_ERROR,
      error: e,
    })
  }
}

function* dismissAlertGroup(action) {
  const { groupName } = action.payload

  try {
    const results = yield call(api.dismissAlertGroup, groupName)
    yield put({
      type: t.DISMISS_ALERT_GROUP_SUCCESS,
      data: normalizeAlerts(results),
    })
  } catch (e) {
    yield put({
      type: t.DISMISS_ALERT_GROUP_ERROR,
      error: e,
    })
  }
}

function* subscribeToAlerts(action) {
  const userRole = action.data.user.role

  function* reconnect() {
    yield call(fetchAlerts, action)
  }

  if (userRole === 'agent') {
    yield fork(subscribe, '/alerts', handler, { reconnect })
  }
}

function* watchSessionCreation() {
  yield takeEvery(CREATE_SESSION_SUCCESS, subscribeToAlerts)
  yield takeEvery(CREATE_SESSION_SUCCESS, fetchAlerts)
}

function* watchLoginSuccess() {
  yield takeEvery(LOGIN_SUCCESS, subscribeToAlerts)
  yield takeEvery(LOGIN_SUCCESS, fetchAlerts)
}

function* watchDismissAlert() {
  yield takeEvery(t.DISMISS_ALERT, dismissAlert)
}

function* watchDismissAlertGroup() {
  yield takeEvery(t.DISMISS_ALERT_GROUP, dismissAlertGroup)
}

export default function* alerts() {
  yield all([
    fork(watchSessionCreation),
    fork(watchLoginSuccess),
    fork(watchDismissAlert),
    fork(watchDismissAlertGroup),
  ])
}
