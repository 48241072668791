// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var V4 = require("uuid/v4");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var JsxRuntime = require("react/jsx-runtime");

var _map = {"info":"info","success":"success","error":"error","warning":"warning"};

function type_ToJs(param) {
  return param;
}

function type_FromJs(param) {
  return _map[param];
}

function make(id, type_, text, time, renderButtons, dismissableOpt, param) {
  var dismissable = dismissableOpt !== undefined ? dismissableOpt : true;
  return {
          id: id !== undefined ? id : V4(),
          type_: type_,
          text: text,
          time: time,
          renderButtons: renderButtons,
          dismissable: dismissable
        };
}

var Message = {
  make: make
};

function defaultValue_addMessage(param) {
  
}

function defaultValue_addMessageJs(param) {
  
}

function defaultValue_removeMessage(param) {
  
}

function defaultValue_setDimensions(param) {
  
}

var defaultValue = {
  addMessage: defaultValue_addMessage,
  addMessageJs: defaultValue_addMessageJs,
  removeMessage: defaultValue_removeMessage,
  messages: /* [] */0,
  dimensions: undefined,
  setDimensions: defaultValue_setDimensions
};

var systemMessagesContext = React.createContext(defaultValue);

var make$1 = systemMessagesContext.Provider;

var Provider = {
  make: make$1
};

function SystemMessages$Provider(props) {
  var match = React.useState(function (param) {
        return /* [] */0;
      });
  var setMessages = match[1];
  var match$1 = React.useState(function (param) {
        
      });
  var setDimensions = match$1[1];
  var addMessage = React.useCallback((function (message) {
          Curry._1(setMessages, (function (state) {
                  return Core__List.add(state, message);
                }));
        }), [setMessages]);
  var removeMessage = React.useCallback((function (id) {
          Curry._1(setMessages, (function (state) {
                  return Core__List.filter(state, (function (x) {
                                return x.id !== id;
                              }));
                }));
        }), [setMessages]);
  var addMessageJs = React.useCallback((function (text) {
          Curry._1(addMessage, make(undefined, "warning", text, undefined, undefined, undefined, undefined));
        }), [addMessage]);
  var value_messages = match[0];
  var value_dimensions = match$1[0];
  var value_setDimensions = function (d) {
    Curry._1(setDimensions, (function (param) {
            return d;
          }));
  };
  var value = {
    addMessage: addMessage,
    addMessageJs: addMessageJs,
    removeMessage: removeMessage,
    messages: value_messages,
    dimensions: value_dimensions,
    setDimensions: value_setDimensions
  };
  return JsxRuntime.jsx(make$1, {
              value: value,
              children: props.children
            });
}

var Provider$1 = {
  Provider: Provider,
  make: SystemMessages$Provider
};

function useSystemMessages(param) {
  return React.useContext(systemMessagesContext);
}

var providerJs = SystemMessages$Provider;

exports.type_ToJs = type_ToJs;
exports.type_FromJs = type_FromJs;
exports.Message = Message;
exports.defaultValue = defaultValue;
exports.systemMessagesContext = systemMessagesContext;
exports.Provider = Provider$1;
exports.providerJs = providerJs;
exports.useSystemMessages = useSystemMessages;
/* systemMessagesContext Not a pure module */
