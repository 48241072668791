import { combineReducers } from 'redux'
import numeral from 'numeral'

import session from './sessionReducer'
import { draft } from './draftReducer'
import { clientEntities } from './clients'

import buyerNeedsPreview from './buyerNeedsReducer'

import { alertEntities } from './alerts'

// configure numeral
numeral.zeroFormat('')

export default combineReducers({
  session,
  alertEntities,
  draft,
  clientEntities,
  buyerNeedsPreview,
})
