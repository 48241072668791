import * as React from 'react'

import { connect } from 'react-redux'

import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { theme, darkTheme } from 'uikit'
import { FocusVisibleManager } from 'uikit/helpers/FocusVisible'
import * as Sentry from '@sentry/nextjs'
import { detect } from 'detect-browser'

import { init } from '../actions/initActions'

import FeatureFlipperProvider from '../reason/featureFlipper/FeatureFlipperProvider'
import { providerJs as SystemMessagesProvider } from '../reason/systemMessages/SystemMessages.bs'

import OldBrowserWarning from '../modules/BrowserWarning'

function isSupportedBrowser() {
  const browser = detect()
  const supportedBrowsers = {
    safari: 14.1,
    edge: 88,
    chrome: 88,
    firefox: 82,
    ios: 14.0,
    crios: 88,
    fxios: 82,
    'ios-webview': 605,
    'edge-chromium': 88.0,
    'edge-ios': 88.0,
    'chromium-webview': 88,
    samsung: 15.0,
    opera: 80,
  }

  if (
    typeof window !== 'undefined' &&
    (!browser ||
      !supportedBrowsers[browser.name] ||
      parseFloat(browser.version) < supportedBrowsers[browser.name])
  ) {
    Sentry.withScope(scope => {
      scope.setExtra('detected_browser', JSON.stringify(browser))
      const name = browser && browser.name ? browser.name : 'unknown'
      const version = browser && browser.version ? browser.version : 'unknown'
      Sentry.captureMessage(`The browser ${name} at version ${version} was forbidden`, 'info')
    })
    return false
  }
  return true
}

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-white: ${props => props.theme.colors.white};
    --color-black: ${props => props.theme.colors.black};
    
    --color-alabaster: ${props => props.theme.colors.alabaster};
    --color-alabaster-alt: ${props => props.theme.colors.alabasterAlt};
    --color-wild-sand: ${props => props.theme.colors.wildSand};
    --color-gallery: ${props => props.theme.colors.gallery};
    --color-alto: ${props => props.theme.colors.alto};
    --color-silver-sand: ${props => props.theme.colors.silverSand};
    --color-silver: ${props => props.theme.colors.silver};
    --color-gray: ${props => props.theme.colors.gray};
    --color-jumbo: ${props => props.theme.colors.jumbo};
    --color-dove-gray: ${props => props.theme.colors.doveGray};
    --color-chicago: ${props => props.theme.colors.chicago};
    
    --color-mine-shaft: ${props => props.theme.colors.mineShaft};
    --color-tuatara: ${props => props.theme.colors.tuatara};
    
    --color-old-lace: ${props => props.theme.colors.oldLace};
    --color-citrine-white: ${props => props.theme.colors.citrineWhite};
    --color-chino: ${props => props.theme.colors.chino};
    --color-driftwood: ${props => props.theme.colors.driftwood};
    --color-tussock: ${props => props.theme.colors.tussock};
    --color-gold-tips: ${props => props.theme.colors.goldTips};
    --color-galliano: ${props => props.theme.colors.galliano};
    --color-dark-goldenrod: ${props => props.theme.colors.darkGoldenrod};
    
    --color-aqua-haze: ${props => props.theme.colors.aquaHaze};
    --color-mystic: ${props => props.theme.colors.mystic};
    --color-wedgewood: ${props => props.theme.colors.wedgewood};
    --color-calypso: ${props => props.theme.colors.calypso};
    --color-st-tropaz: ${props => props.theme.colors.stTropaz};
    --color-astral: ${props => props.theme.colors.astral};
    --color-biscay: ${props => props.theme.colors.biscay};
    --color-big-stone: ${props => props.theme.colors.bigStone};
    --color-big-stone-alt: ${props => props.theme.colors.bigStoneAlt};
    --color-big-stone-dark: ${props => props.theme.colors.bigStoneDark};
    
    --color-peppermint: ${props => props.theme.colors.peppermint};
    --color-chelsea-cucumber: ${props => props.theme.colors.chelseaCucumber};
    --color-apple: ${props => props.theme.colors.apple};
    --color-vida-loca: ${props => props.theme.colors.vidaLoca};
    
    --color-crimson: ${props => props.theme.colors.crimson};
    --color-monza: ${props => props.theme.colors.monza};
    --color-carmine: ${props => props.theme.colors.carmine};
    
    --color-cherub: ${props => props.theme.colors.cherub};
    --color-white-linen: ${props => props.theme.colors.whiteLinen};

    background: ${props => props.theme.colors.white};
  }
`

class App extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.init()
  }
  render() {
    return (
      <FeatureFlipperProvider>
        <FocusVisibleManager>
          <ThemeHandler>
            <GlobalStyle />
            <SystemMessagesProvider>
              {isSupportedBrowser() ? this.props.children : <OldBrowserWarning />}
            </SystemMessagesProvider>
          </ThemeHandler>
        </FocusVisibleManager>
      </FeatureFlipperProvider>
    )
  }
}

function ThemeHandler(props) {
  const [themeKey, setThemeKey] = React.useState('light')
  React.useEffect(() => {
    const handleKeypress = e => {
      if (e.metaKey && e.key === 'd') {
        e.preventDefault()
        e.stopPropagation()
        setThemeKey(key => {
          const newVal = key === 'dark' ? 'light' : 'dark'
          return newVal
        })
      }
    }
    window.addEventListener('keydown', handleKeypress)
    return () => window.removeEventListener('keydown', handleKeypress)
  }, [])

  return (
    <ThemeProvider key={themeKey} theme={themeKey === 'dark' ? darkTheme : theme}>
      {props.children}
    </ThemeProvider>
  )
}

export default connect(null, { init })(App)
