import { createSelector } from 'reselect'

const denormalizeBuyerNeed = (buyerNeed, buyerNeedLocations) => {
  let newBuyerNeed = buyerNeed
  const normalizedLocations = buyerNeed.get('locations')

  if (normalizedLocations && normalizedLocations.size > 0) {
    newBuyerNeed = newBuyerNeed.set(
      'locations',
      normalizedLocations.map(id => buyerNeedLocations.get(id.toString()))
    )
  }

  return newBuyerNeed
}

const denormalizeClient = (client, buyerNeeds, contacts) => {
  let newClient = client
  const normalizedBuyerNeeds = client.get('buyerNeeds')
  const normalizedContacts = client.get('contacts')

  if (normalizedBuyerNeeds && normalizedBuyerNeeds.size > 0) {
    newClient = newClient.set(
      'buyerNeeds',
      normalizedBuyerNeeds.map(id => buyerNeeds.get(id.toString()))
    )
  }

  if (contacts && contacts.size > 0) {
    newClient = newClient.set(
      'contacts',
      normalizedContacts.map(id => contacts.get(id.toString()))
    )
  }

  return newClient
}

const selectClientEntities = state => state.clientEntities

const selectNormalizedClients = createSelector(
  selectClientEntities,
  clientEntities => clientEntities.clients
)

const selectNormalizedBuyerNeedLocations = createSelector(
  selectClientEntities,
  clientEntities => clientEntities.buyerNeedLocations
)

const selectNormalizedBuyerNeeds = createSelector(
  selectClientEntities,
  clientEntities => clientEntities.buyerNeeds
)

const selectContacts = createSelector(
  selectClientEntities,
  clientEntities => clientEntities.contacts
)

const selectBuyerNeeds = createSelector(
  [selectNormalizedBuyerNeeds, selectNormalizedBuyerNeedLocations],
  (buyerNeeds, buyerNeedLocations) =>
    buyerNeeds.map(bn => denormalizeBuyerNeed(bn, buyerNeedLocations))
)

const selectClients = createSelector(
  [selectNormalizedClients, selectBuyerNeeds, selectContacts],
  (clients, buyerNeeds, contacts) =>
    clients.map(client => denormalizeClient(client, buyerNeeds, contacts))
)

export default selectClients
