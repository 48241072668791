import React, { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { QueryParamProvider as ContextProvider } from 'use-query-params'

export function QueryParamProvider(props) {
  const { children, ...rest } = props
  const router = useRouter()

  const location = useMemo(
    () => ({
      search: router.asPath.replace(/[^?]+/u, ''),
    }),
    [router.asPath]
  )

  const history = useMemo(() => {
    const [path, hash] = router.asPath.split('#')
    const match = path.match(/[^?]+/)
    const pathWithoutQuery = match ? match[0] : path
    return {
      push: ({ search }) => {
        router.push({ pathname: pathWithoutQuery, search, hash })
      },
      replace: ({ search }) => {
        router.replace({ pathname: pathWithoutQuery, search, hash }, null, { scroll: false })
      },
    }
  }, [router])

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  )
}

export default memo(QueryParamProvider)
