import { themeGet } from 'styled-system'
import styled from 'styled-components'
import Button from './Button'

export const makeLinkButton = component => {
  const LinkButton = styled(component)`
    color: ${themeGet('colors.stTropaz')};
    background: none;
    border: none;
    text-transform: none;
    font-weight: normal;
    border-radius: 0;

    /* Create some room for the focus styles */
    padding: 2px;
    margin: -2px;

    &[data-focus-visible][data-focus-visible] {
      box-shadow: 0 0 5px 1px ${themeGet('colors.stTropaz')};
      filter: none;
      border-radius: 3px;
    }
  `
  LinkButton.displayName = 'LinkButton'
  return LinkButton
}

export default makeLinkButton(Button)
