// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ReactApollo = require("react-apollo");
var ReactHooks = require("@apollo/react-hooks");

var make = ReactApollo.ApolloConsumer;

function useApolloClient(prim) {
  return ReactHooks.useApolloClient();
}

exports.make = make;
exports.useApolloClient = useApolloClient;
/* make Not a pure module */
