// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var DateFns = require("date-fns");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

function isInvalidDate(d) {
  return isNaN(d.getTime());
}

function parse(date, format, reference) {
  var tmp;
  try {
    tmp = {
      TAG: "Ok",
      _0: DateFns.parse(date, format, reference)
    };
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      var e$1 = e._1;
      var name = Core__Option.getOr(e$1.name, "Nameless JS exn");
      var message = Core__Option.getOr(e$1.message, "Empty message");
      tmp = {
        TAG: "Error",
        _0: name + (": " + message)
      };
    } else {
      throw e;
    }
  }
  return Core__Result.flatMap(tmp, (function (d) {
                if (isNaN(d.getTime())) {
                  return {
                          TAG: "Error",
                          _0: "Invalid date"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: d
                        };
                }
              }));
}

var _map = {"date":"date","complete":"complete"};

var _revMap = {"date":"date","complete":"complete"};

function representationToJs(param) {
  return _map[param];
}

function representationFromJs(param) {
  return _revMap[param];
}

function formatISO(date, representation, param) {
  var tmp = {};
  var tmp$1 = Core__Option.map(representation, representationToJs);
  if (tmp$1 !== undefined) {
    tmp.representation = Caml_option.valFromOption(tmp$1);
  }
  return DateFns.formatISO(date, tmp);
}

exports.isInvalidDate = isInvalidDate;
exports.parse = parse;
exports.representationToJs = representationToJs;
exports.representationFromJs = representationFromJs;
exports.formatISO = formatISO;
/* date-fns Not a pure module */
