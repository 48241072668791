import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import Cleave from 'cleave.js/react'

import Label from './Label'
import { defaultInputStyles } from 'uikit/styles/theme'

const StyledInputHolder = styled.div`
  display: flex;
`

const StyledInput = styled.input`
  ${defaultInputStyles}
  min-width: 50px;
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  border-color: ${props => {
    if (props.status) {
      switch (props.status) {
        case 'ok':
          return props.theme.colors.chelseaCucumber
        case 'error':
          return props.theme.colors.crimson
        default:
          return props.theme.colors.alto
      }
    }

    return props.theme.colors.alto
  }};
`

const StyledPrepend = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: ${props => props.theme.colors.alto};
  border-width: 1px 0 1px 1px;
  background: ${props => props.theme.colors.wildSand};
  color: ${props => props.theme.colors.mineShaft};
  min-width: 35px;
  padding: 0 6px;
  font-size: 14px;
`

const StyledAppend = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-color: ${props => props.theme.colors.alto};
  border-width: 1px 1px 1px 0;
  background: ${props => props.theme.colors.wildSand};
  color: ${props => props.theme.colors.mineShaft};
  min-width: 35px;
  padding: 0 6px;
  font-size: 14px;
`

const StyledInputField = styled.div`
  ${space};
`

const InputField = React.forwardRef(
  (
    {
      autocomplete,
      autofocus,
      disabled,
      labelText,
      tooltipText,
      optionalText,
      value,
      onChange,
      onBlur,
      type,
      placeholderText,
      status,
      prepend,
      append,
      onFocus,
      className,
      id,
      ...etc
    },
    ref
  ) => {
    return (
      <StyledInputField className={className} {...etc}>
        {labelText && (
          <Label
            htmlFor={id}
            labelText={labelText}
            optionalText={optionalText}
            tooltipText={tooltipText}
          />
        )}
        <StyledInputHolder>
          {prepend && <StyledPrepend>{prepend}</StyledPrepend>}
          <StyledInput
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type || 'text'}
            placeholder={placeholderText}
            autoComplete={autocomplete}
            autoFocus={autofocus}
            disabled={disabled}
            status={status}
            id={id}
            ref={ref}
          />
          {append && <StyledAppend>{append}</StyledAppend>}
        </StyledInputHolder>
      </StyledInputField>
    )
  }
)

InputField.displayName = 'InputField'

export const PriceInputField = props => {
  const {
    labelText,
    tooltipText,
    optionalText,
    initialValue,
    onChange,
    onBlur,
    type,
    // type_ is here, because when we bind from Reason with [@react.component] we cannot use [@bs.as], as [@bs.obj] doesn't support it yet
    type_,
    placeholderText,
    status,
    prepend,
    append,
    onFocus,
    ...etc
  } = props
  return (
    <StyledInputField {...etc}>
      {labelText && (
        <Label
          labelText={labelText}
          tooltipText={tooltipText}
          optionalText={optionalText}
          mb="7px"
        />
      )}
      <StyledInputHolder>
        {prepend && <StyledPrepend>{prepend}</StyledPrepend>}
        <StyledInput
          value={initialValue}
          onChange={e => {
            if (onChange) {
              onChange(e.target.rawValue)
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type || type_ || 'text'}
          placeholder={placeholderText}
          status={status}
          as={Cleave}
          options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
        />
        {append && <StyledAppend>{append}</StyledAppend>}
      </StyledInputHolder>
    </StyledInputField>
  )
}

export const CCInputField = props => {
  const {
    labelText,
    tooltipText,
    optionalText,
    initialValue,
    onChange,
    onBlur,
    type,
    // type_ is here, because when we bind from Reason with [@react.component] we cannot use [@bs.as], as [@bs.obj] doesn't support it yet
    type_,
    placeholderText,
    status,
    prepend,
    append,
    onFocus,
    ...etc
  } = props
  return (
    <StyledInputField {...etc}>
      {labelText && (
        <Label
          labelText={labelText}
          optionalText={optionalText}
          tooltipText={tooltipText}
          mb="7px"
        />
      )}
      <StyledInputHolder>
        {prepend && <StyledPrepend>{prepend}</StyledPrepend>}
        <StyledInput
          value={initialValue}
          onChange={e => {
            if (onChange) {
              onChange(e.target.rawValue)
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type || type_ || 'text'}
          placeholder={placeholderText}
          status={status}
          as={Cleave}
          options={{ creditCard: true }}
        />
        {append && <StyledAppend>{append}</StyledAppend>}
      </StyledInputHolder>
    </StyledInputField>
  )
}

export default InputField
