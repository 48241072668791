import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

import View from '../../helpers/View'

const StyledWrapper = styled(View)`
  border-bottom: 1px solid ${props => props.theme.colors.alto};
  background: ${props => props.theme.colors.white};
  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.alto};
  }
  &:nth-child(odd) {
    background: ${props => props.theme.colors.alabasterAlt};
  }
`

const HistoryItem = ({ title, subtitle, description, children }) => (
  <StyledWrapper p={15} lineHeight={1.5} fontSize={14} alignItems="flex-start">
    <div>
      {title && (
        <View
          color={themeGet('colors.tuatara')}
          fontSize={14}
          lineHeight={1.25}
          fontWeight={600}
          textTransform="uppercase"
          mb={10}
        >
          {title}
        </View>
      )}
      {subtitle && (
        <View color={themeGet('colors.mineShaft')} mb="5px">
          {subtitle}
        </View>
      )}
      {description && (
        <View color={themeGet('colors.gray')} dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
    {children && (
      <View flexShrink={0} pl={15}>
        {children}
      </View>
    )}
  </StyledWrapper>
)

export default HistoryItem
