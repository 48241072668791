import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { themeGet, space } from 'styled-system'
import { fontWeight } from 'uikit/styles/theme'

import SvgIcon from '../SvgIcon'
import { mediaBreakpointUp } from '../../styles/media'

const StyledPageTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ centered }) => centered && 'text-align: center;'};
  margin: 0 0 15px 0;
  ${mediaBreakpointUp('md')`
    margin: 0 0 20px 0;
  `};
  ${mediaBreakpointUp('md')`
    margin: 0 0 25px 0;
  `};
  b {
    font-weight: ${fontWeight.bold};
  }
  i {
    font-style: italic;
  }
  cite {
    color: ${props => props.theme.colors.gray};
    margin-left: 10px;
    font-style: normal;
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
  ${space};
`

const StyledIconHolder = styled.div`
  margin-right: 20px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.tuatara};
  padding: 10px;
  display: flex;
  flex-shrink: 0;
`

const StyledTitlesHolder = styled.div`
  display: flex;
  flex-direction: column;
  ${({ centered }) => centered && 'align-items: center'};
`

const StyledTitle = styled.h2`
  ${({ huge }) => `font-size: ${huge ? '26px' : '22px'}`};
  line-height: 1.25;
  font-weight: ${fontWeight.light};
  color: ${props => props.theme.colors.tuatara};
  margin: 0;
  ${mediaBreakpointUp('md')`
    ${({ huge }) => `font-size: ${huge ? '36px' : '26px'}`};
  `};
  ${mediaBreakpointUp('lg')`
    ${({ huge }) => `font-size: ${huge ? '42px' : '32px'}`};
  `};
`

const StyledSubtitle = styled.h6`
  ${({ huge }) => `font-size: ${huge ? '14px' : '13px'}`};
  font-weight: ${fontWeight.regular};
  line-height: 1.5;
  color: ${props => props.theme.colors.gray};
  margin: 10px 0 0 0;
  ${mediaBreakpointUp('md')`
    max-width: 80%;
    ${({ huge }) => `font-size: ${huge ? '22px' : '14px'}`};
  `};
`

const PageTitle = ({ title, subtitle, huge, centered, icon, className, ...rest }) => (
  <StyledPageTitle centered={centered} huge={huge} className={className} {...rest}>
    {icon && (
      <StyledIconHolder>
        <SvgIcon icon={icon} size={40} color={themeGet('colors.tuatara')} />
      </StyledIconHolder>
    )}
    <StyledTitlesHolder centered={centered}>
      <StyledTitle huge={huge} dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && <StyledSubtitle huge={huge} dangerouslySetInnerHTML={{ __html: subtitle }} />}
    </StyledTitlesHolder>
  </StyledPageTitle>
)

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  huge: PropTypes.bool,
  centered: PropTypes.bool,
}

export default PageTitle
