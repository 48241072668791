import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDown } from 'styles/media'

const StyledTogglesGroup = styled.div`
  display: flex;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  align-items: flex-start;
  & > :not(:last-child) {
    margin: ${props => (props.inline ? '0 20px 0 0' : '0 0 10px 0')};
  }
  ${mediaBreakpointDown('sm')`
    flex-direction: column;
    & > :not(:last-child) {
      margin: 0 0 10px 0;
    }
  `};
`

const TogglesGroup = ({ inline, children }) => (
  <StyledTogglesGroup inline={inline}>{children}</StyledTogglesGroup>
)

export default TogglesGroup
