export const BUYER_NEEDS_ADD_LOCATION = 'BUYER_NEEDS_ADD_LOCATION'
export const BUYER_NEEDS_REMOVE_LOCATION = 'BUYER_NEEDS_REMOVE_LOCATION'

export const PROPERTY_ADD_SHOWING = 'PROPERTY_ADD_SHOWING'
export const PROPERTY_REMOVE_SHOWING = 'PROPERTY_REMOVE_SHOWING'
export const PROPERTY_SAVE_REQUEST = 'PROPERTY_SAVE_REQUEST'
export const PROPERTY_SAVE_SUCCESS = 'PROPERTY_SAVE_SUCCESS'
export const PROPERTY_SAVE_ERROR = 'PROPERTY_SAVE_ERROR'

export const LOGIN = 'LOGIN'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR'

export const EXTERNAL_CREATE_SESSION_SUCCESS = 'EXTERNAL_CREATE_SESSION_SUCCESS'

export const DISMISS_MATCH = 'BUYERS/DISMISS_MATCH'
export const DISMISS_MATCH_SUCCESS = 'BUYERS/DISMISS_SUCCESS'
export const DISMISS_MATCH_ERROR = 'BUYERS/DISMISS_ERROR'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR'

export const INIT = 'INIT'
export const INIT_COMPLETE = 'INIT_COMPLETE'

export const LOCATION_SEARCH = 'LOCATION_SEARCH'
export const LOCATION_LOAD = 'LOCATION_LOAD'
export const LOCATION_CHANGE_CITY = 'LOCATION_CHANGE_CITY'
export const NEIGHBORHOOD_LOCATION_SEARCH = 'NEIGHBORHOOD_LOCATION_SEARCH'
export const LOCATION_AUTO_COMPLETE_CHANGE_SEARCH_TERM = 'LOCATION_AUTO_COMPLETE_CHANGE_SEARCH_TERM'
export const LOCATION_AUTO_COMPLETE_SEARCH = 'LOCATION_AUTO_COMPLETE_SEARCH'
export const LOCATION_AUTO_COMPLETE_SEARCH_RESET = 'LOCATION_AUTO_COMPLETE_SEARCH_RESET'
export const LOCATION_AUTO_COMPLETE_SEARCH_FETCH = 'LOCATION_AUTO_COMPLETE_SEARCH_FETCH'
export const LOCATION_AUTO_COMPLETE_SEARCH_RESULTS = 'LOCATION_AUTO_COMPLETE_SEARCH_RESULTS'
export const LOCATION_AUTO_COMPLETE_SEARCH_FAILED = 'LOCATION_AUTO_COMPLETE_SEARCH_FAILED'

export const LOCATION_FETCH = 'LOCATION_FETCH'
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS'
export const LOCATION_FAILURE = 'LOCATION_FAILURE'

export const FETCH_BUYER = 'FETCH_BUYER'
export const FETCH_BUYER_REQUEST = 'FETCH_BUYER_REQUEST'
export const FETCH_BUYER_SUCCESS = 'FETCH_BUYER_SUCCESS'
export const FETCH_BUYER_ERROR = 'FETCH_BUYER_ERROR'

export const SAVE_PRIVATE_BUYER_NEED = 'SAVE_PRIVATE_BUYER_NEED'
export const CREATE_BUYER_NEEDS_REQUEST = 'CREATE_BUYER_NEEDS_REQUEST'
export const CREATE_BUYER_NEEDS_SUCCESS = 'CREATE_BUYER_NEEDS_SUCCESS'
export const CREATE_BUYER_NEEDS_ERROR = 'CREATE_BUYER_NEEDS_ERROR'

export const ADD_SYSTEM_MESSAGE = 'ADD_SYSTEM_MESSAGE'
export const DISMISS_SYSTEM_MESSAGE = 'DISMISS_SYSTEM_MESSAGE'

export const SEARCH_BUYER_NEED_REQUEST = 'SEARCH_BUYER_NEED_REQUEST'
export const SEARCH_BUYER_NEED_SUCCESS = 'SEARCH_BUYER_NEED_SUCCESS'
export const SEARCH_BUYER_NEED_ERROR = 'SEARCH_BUYER_NEED_ERROR'

export const FETCH_BUYER_NEED_MATCHES = 'FETCH_BUYER_NEED_MATCHES'
export const FETCH_BUYER_NEED_MATCHES_SUCCESS = 'FETCH_BUYER_NEED_MATCHES_SUCCESS'
export const FETCH_BUYER_NEED_MATCHES_ERROR = 'FETCH_BUYER_NEED_MATCHES_ERROR'
export const FETCH_BUYER_NEED_MATCHES_REQUEST = 'FETCH_BUYER_NEED_MATCHES_REQUEST'

export const FETCH_BUYER_NEED_MATCHES_WITH_REFRESH = 'FETCH_BUYER_NEED_MATCHES_WITH_REFRESH'
export const CANCEL_BUYER_NEED_REFRESH = 'CANCEL_BUYER_NEED_REFRESH'
export const REFRESH_BUYER_NEED = 'REFRESH_BUYER_NEED'

export const NEW_ALERT = 'NEW_ALERT'
export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST'
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS'
export const FETCH_ALERTS_ERROR = 'FETCH_ALERTS_ERROR'

export const REASON_LOGIN_SUCCESS = 'REASON_LOGIN_SUCCESS'
