import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makeDarkSecondaryButton = component => {
  const DarkSecondaryButton = styled(component)`
    && {
      border-color: ${props =>
        props.disabled ? rgba(props.theme.colors.alto, 0.5) : props.theme.colors.bigStoneAlt};
      color: ${props =>
        props.disabled ? rgba(props.theme.colors.doveGray, 0.5) : props.theme.colors.alto};
      background-color: ${props =>
        props.disabled ? rgba(props.theme.colors.white, 0.5) : props.theme.colors.bigStoneAlt};
      @media (hover: hover) {
        &:hover {
          border-color: ${props => props.theme.colors.stTropaz};
          color: ${props => props.theme.colors.white};
          background-color: ${props => props.theme.colors.stTropaz};
        }
      }
    }
  `

  DarkSecondaryButton.propTypes = {
    appearance: PropTypes.string,
  }

  DarkSecondaryButton.defaultProps = {
    appearance: 'secondary',
  }

  DarkSecondaryButton.displayName = 'DarkSecondaryButton'
  return DarkSecondaryButton
}
export default makeDarkSecondaryButton(Button)
