import {
  SEARCH_BUYER_NEED_REQUEST,
  SEARCH_BUYER_NEED_SUCCESS,
  SEARCH_BUYER_NEED_ERROR,
} from '../constants/actionTypes'

const initialState = {
  isLoading: false,
  properties: [],
}

export default function buyerNeedsReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_BUYER_NEED_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case SEARCH_BUYER_NEED_ERROR:
      return {
        ...state,
        isLoading: false,
        properties: [],
      }

    case SEARCH_BUYER_NEED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        properties: action.data,
      }

    default:
      return state
  }
}
