import { normalize, schema } from 'normalizr'

const alert = new schema.Entity('alerts')
const agentMatchLog = new schema.Entity('agentMatchLogs')
const match = new schema.Entity('matches')
const property = new schema.Entity('properties')
const client = new schema.Entity('clients')
const contact = new schema.Entity('contacts')
const buyerNeed = new schema.Entity('buyerNeeds')
const agent = new schema.Entity('agents')

alert.define({
  agentMatchLog,
  agent,
  clients: [client],
})

client.define({
  contacts: [contact],
})

const normalizeAlerts = data => normalize(data, [alert])
const normalizeAlert = data => normalize(data, alert)

export {
  normalizeAlerts,
  normalizeAlert,
  alert,
  agentMatchLog,
  match,
  property,
  client,
  contact,
  buyerNeed,
}
