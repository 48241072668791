import React from 'react'
import styled from 'styled-components'

import Avatar from 'uikit/atoms/Avatar'
import { fontWeight } from 'uikit/styles/theme'
import PropTypes from 'prop-types'
import View from '../../helpers/View'

const mapSizeToAvatarSize = {
  xSmall: '40px',
  small: '50px',
  medium: '60px',
  large: '90px',
}

const mapSizeToAgentFontSize = {
  small: '14px',
  medium: '14px',
  large: '16px',
}

const NameHolder = styled(View)`
  a {
    :hover {
      text-decoration: underline;
    }
  }
`

const id = x => x

const AgentInfo = ({
  onClick,
  avatar,
  size = 'small',
  name,
  brokerage,
  wrapName = id,
  wrapAvatar = id,
  children,
  ...restProps
}) => (
  <View alignItems={[children ? 'flex-start' : 'center']} {...restProps}>
    {wrapAvatar(
      <Avatar
        src={avatar}
        name={name}
        onClick={onClick}
        size={
          Array.isArray(size) ? size.map(s => mapSizeToAvatarSize[s]) : mapSizeToAvatarSize[size]
        }
        mr={15}
      />
    )}
    <View flexDirection="column">
      <NameHolder
        onClick={onClick}
        color="mineShaft"
        fontWeight={fontWeight.bold}
        fontSize={
          Array.isArray(size)
            ? size.map(s => mapSizeToAgentFontSize[s])
            : mapSizeToAgentFontSize[size]
        }
        lineHeight={1}
        style={{ cursor: onClick ? 'pointer' : 'unset' }}
      >
        {wrapName(name)}
      </NameHolder>
      {brokerage ? (
        <View color="gray" fontWeight={fontWeight.regular} fontSize={13} lineHeight={1} mt={8}>
          {brokerage}
        </View>
      ) : null}
      {children}
    </View>
  </View>
)

AgentInfo.propTypes = {
  onClick: PropTypes.func,
  /** useful props to render links */
  wrapName: PropTypes.func,
  wrapAvatar: PropTypes.func,
  avatar: PropTypes.string,
  /** responsive size prop */
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(['xSmall', 'small', 'medium', 'large'])),
    PropTypes.oneOf(['xSmall', 'small', 'medium', 'large']),
  ]),
  name: PropTypes.string,
  brokerage: PropTypes.string,
  children: PropTypes.element,
}

export default AgentInfo
