// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var AvatarSrcUrl = require("../../utils/AvatarSrcUrl.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var GraphQLConverter = require("../common/GraphQLConverter.bs.js");

function make(id, key) {
  return {
          id: id,
          key: key
        };
}

function decode(obj) {
  var partial_arg = GraphQLConverter.Helpers.some(obj.key)("key was None");
  var partial_arg$1 = GraphQLConverter.Helpers.$$const(obj.id);
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(make)(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query = "fragment Avatar on Attachment   {\nid  \nkey  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "key");
    var field_key = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    tmp = {
      id: field_id,
      key: field_key
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

var name = "Avatar";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Avatar = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function url(width, height, v) {
  return AvatarSrcUrl.from_key(width, height, v.key);
}

var Avatar$1 = {
  make: make,
  decode: decode,
  Avatar: Avatar,
  Fragment: undefined,
  url: url
};

function decodeMaybeAnonymousUser(a) {
  return Core__Option.mapOr(a, {
              TAG: "Ok",
              _0: "AnonymousUser"
            }, (function (user) {
                return Core__Result.map(user, (function (u) {
                              return {
                                      TAG: "User",
                                      _0: u
                                    };
                            }));
              }));
}

function anonymousUserToOption(x) {
  if (typeof x !== "object") {
    return ;
  } else {
    return x._0;
  }
}

function make$1(id, avatar, firstName, lastName, displayName, company, email, phone, account, membershipStatus) {
  return {
          id: id,
          avatar: avatar,
          firstName: firstName,
          lastName: lastName,
          displayName: displayName,
          company: company,
          email: email,
          phone: phone,
          account: account,
          membershipStatus: membershipStatus
        };
}

function name$1(v) {
  return v.firstName + (" " + v.lastName);
}

function label(v) {
  return Core__Option.getOr(v.displayName, v.firstName + (" " + v.lastName));
}

function shortLabel(v) {
  return Core__Option.getOr(v.displayName, v.firstName);
}

function avatarURL(width, height, v) {
  return AvatarSrcUrl.from_key(width, height, Core__Option.map(v.avatar, (function (a) {
                    return a.key;
                  })));
}

function decode$1(obj) {
  var partial_arg = GraphQLConverter.Helpers.some(obj.membershipStatus)("membershipStatus was None");
  var partial_arg$1 = GraphQLConverter.Helpers.$$const(obj.account);
  var partial_arg$2 = GraphQLConverter.Helpers.$$const(obj.phone);
  var partial_arg$3 = GraphQLConverter.Helpers.$$const(obj.email);
  var partial_arg$4 = GraphQLConverter.Helpers.$$const(obj.company);
  var partial_arg$5 = GraphQLConverter.Helpers.$$const(obj.displayName);
  var partial_arg$6 = GraphQLConverter.Helpers.some(obj.lastName)("lastName was None");
  var partial_arg$7 = GraphQLConverter.Helpers.some(obj.firstName)("firstName was None");
  var partial_arg$8 = GraphQLConverter.Helpers.optOk(obj.avatar);
  var partial_arg$9 = GraphQLConverter.Helpers.$$const(obj.id);
  var partial_arg$10 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$9, param);
  };
  var partial_arg$11 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$10, partial_arg$8, param);
  };
  var partial_arg$12 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$11, partial_arg$7, param);
  };
  var partial_arg$13 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$12, partial_arg$6, param);
  };
  var partial_arg$14 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$13, partial_arg$5, param);
  };
  var partial_arg$15 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$14, partial_arg$4, param);
  };
  var partial_arg$16 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$15, partial_arg$3, param);
  };
  var partial_arg$17 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$16, partial_arg$2, param);
  };
  var partial_arg$18 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$17, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(make$1)(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$18, partial_arg, param);
            });
}

var ppx_printed_query$1 = "fragment user on User   {\nid  \nfirstName  \nlastName  \ndisplayName  \ncompany  \nemail  \navatar  {\n...Avatar   \n}\n\nphone  \nmembershipStatus  \naccount  {\nid  \n}\n\n}\nfragment Avatar on Attachment   {\nid  \nkey  \n}\n";

function parse$1(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "firstName");
    var field_firstName = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
    var value$4 = Js_dict.get(value$1, "lastName");
    var field_lastName = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
    var value$5 = Js_dict.get(value$1, "displayName");
    var field_displayName = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
    var value$6 = Js_dict.get(value$1, "company");
    var field_company = value$6 !== undefined && !(value$6 == null) ? value$6 : undefined;
    var value$7 = Js_dict.get(value$1, "email");
    var field_email = value$7 !== undefined ? value$7 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$8 = Js_dict.get(value$1, "avatar");
    var field_avatar = value$8 !== undefined && !(value$8 == null) ? parse(value$8) : undefined;
    var value$9 = Js_dict.get(value$1, "phone");
    var field_phone = value$9 !== undefined && !(value$9 == null) ? value$9 : undefined;
    var value$10 = Js_dict.get(value$1, "membershipStatus");
    var field_membershipStatus;
    if (value$10 !== undefined && !(value$10 == null)) {
      var tmp$1 = value$10 === "ACTIVE" ? "ACTIVE" : "TERMINATED";
      field_membershipStatus = tmp$1;
    } else {
      field_membershipStatus = undefined;
    }
    var value$11 = Js_dict.get(value$1, "account");
    var field_account;
    if (value$11 !== undefined && !(value$11 == null)) {
      var value$12 = Js_json.decodeObject(value$11);
      var tmp$2;
      if (value$12 !== undefined) {
        var value$13 = Js_dict.get(value$12, "id");
        var field_id$1 = value$13 !== undefined ? value$13 : Js_exn.raiseError("Unexpected GraphQL query response");
        tmp$2 = {
          id: field_id$1
        };
      } else {
        tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
      }
      field_account = tmp$2;
    } else {
      field_account = undefined;
    }
    tmp = {
      id: field_id,
      firstName: field_firstName,
      lastName: field_lastName,
      displayName: field_displayName,
      company: field_company,
      email: field_email,
      phone: field_phone,
      avatar: field_avatar,
      membershipStatus: field_membershipStatus,
      account: field_account
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$1(tmp);
}

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var User = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: "user",
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var Fragment = {
  User: User
};

exports.Avatar = Avatar$1;
exports.decodeMaybeAnonymousUser = decodeMaybeAnonymousUser;
exports.anonymousUserToOption = anonymousUserToOption;
exports.make = make$1;
exports.name = name$1;
exports.label = label;
exports.shortLabel = shortLabel;
exports.avatarURL = avatarURL;
exports.decode = decode$1;
exports.Fragment = Fragment;
/* AvatarSrcUrl Not a pure module */
