// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../utils/Utils.bs.js");
var UTCDate = require("../types/UTCDate.bs.js");
var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Float = require("@rescript/core/src/Core__Float.bs.js");
var GraphqlTag = require("graphql-tag").default;
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function query(apolloClient, query$1) {
  return apolloClient.query({
              query: Curry._1(GraphqlTag, query$1.query),
              variables: query$1.variables
            });
}

function defaultToEmptyArray(x) {
  return Core__Array.filterMap(Core__Option.getOr(x, []), (function (x) {
                return x;
              }));
}

function defaultToFalse(x) {
  return Core__Option.getOr(x, false);
}

function decodeOptionNumber(x) {
  return Core__Option.flatMap(x, Core__JSON.Decode.$$float);
}

function floatDateDecoder(x) {
  return Core__Option.map(Core__Option.flatMap(x, Core__JSON.Decode.$$float), (function (prim) {
                return new Date(prim);
              }));
}

function floatUTGDateDecoder(x) {
  return Core__Option.flatMap(x, (function (a) {
                return Utils.Result.toOption(UTCDate.parseFromFloat(a));
              }));
}

function stringDateDecoder(x) {
  return Core__Option.map(x, (function (prim) {
                return new Date(prim);
              }));
}

function accumulate_error(result, errors) {
  if (result.TAG === "Ok") {
    return errors;
  } else {
    return {
            hd: result._0,
            tl: errors
          };
  }
}

function accumulateResultsList(results) {
  var match = Core__List.reduceReverse(results, [
        /* [] */0,
        /* [] */0
      ], (function (param, result) {
          var errors = param[1];
          var oks = param[0];
          if (result.TAG === "Ok") {
            return [
                    {
                      hd: result._0,
                      tl: oks
                    },
                    errors
                  ];
          } else {
            return [
                    oks,
                    {
                      hd: result._0,
                      tl: errors
                    }
                  ];
          }
        }));
  return [
          match[0],
          match[1]
        ];
}

function accumulateResultsArray(results) {
  var match = Core__Array.reduceRight(results, [
        /* [] */0,
        /* [] */0
      ], (function (param, result) {
          var errors = param[1];
          var oks = param[0];
          if (result.TAG === "Ok") {
            return [
                    {
                      hd: result._0,
                      tl: oks
                    },
                    errors
                  ];
          } else {
            return [
                    oks,
                    {
                      hd: result._0,
                      tl: errors
                    }
                  ];
          }
        }));
  return [
          Core__List.toArray(match[0]),
          Core__List.toArray(match[1])
        ];
}

function resultArrayToResult(results) {
  var match = Core__Array.reduce(results, [
        /* [] */0,
        /* [] */0
      ], (function (param, result) {
          var errors = param[1];
          var oks = param[0];
          if (result.TAG === "Ok") {
            return [
                    {
                      hd: result._0,
                      tl: oks
                    },
                    errors
                  ];
          } else {
            return [
                    oks,
                    {
                      hd: result._0,
                      tl: errors
                    }
                  ];
          }
        }));
  var errors = match[1];
  if (errors) {
    return {
            TAG: "Error",
            _0: Core__List.toArray(errors)
          };
  } else {
    return {
            TAG: "Ok",
            _0: Core__List.toArray(match[0])
          };
  }
}

function make(input) {
  return {
          input: input
        };
}

var Input = {
  make: make
};

function decodeOptToError(f, v) {
  if (v !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(v));
  } else {
    return {
            TAG: "Error",
            _0: ["unexpected none"]
          };
  }
}

function decodeOptFloat(v) {
  return Core__Option.flatMap(v, Core__Float.fromString);
}

function containsError(errorName, errors) {
  return Core__Option.isSome(Core__Array.filterMap(errors, (function (e) {
                      return Caml_option.nullable_to_opt(e.name);
                    })).find(function (a) {
                  return a === errorName;
                }));
}

function hasAuthorizationError(param) {
  return containsError("AuthorizationError", param);
}

var GraphQLErrors = {
  containsError: containsError,
  hasAuthorizationError: hasAuthorizationError
};

function containsError$1(errorName, errors) {
  return containsError(errorName, Core__Option.getOr(Caml_option.nullable_to_opt(errors.graphQLErrors), []));
}

function hasAuthorizationError$1(param) {
  return containsError$1("AuthorizationError", param);
}

var ApolloError = {
  containsError: containsError$1,
  hasAuthorizationError: hasAuthorizationError$1
};

function toQueryObj(prim) {
  return prim;
}

function toError(prim) {
  return prim;
}

exports.query = query;
exports.toQueryObj = toQueryObj;
exports.defaultToEmptyArray = defaultToEmptyArray;
exports.defaultToFalse = defaultToFalse;
exports.decodeOptionNumber = decodeOptionNumber;
exports.floatDateDecoder = floatDateDecoder;
exports.floatUTGDateDecoder = floatUTGDateDecoder;
exports.stringDateDecoder = stringDateDecoder;
exports.accumulate_error = accumulate_error;
exports.accumulateResultsList = accumulateResultsList;
exports.accumulateResultsArray = accumulateResultsArray;
exports.resultArrayToResult = resultArrayToResult;
exports.Input = Input;
exports.decodeOptToError = decodeOptToError;
exports.toError = toError;
exports.decodeOptFloat = decodeOptFloat;
exports.GraphQLErrors = GraphQLErrors;
exports.ApolloError = ApolloError;
/* Utils Not a pure module */
