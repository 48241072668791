import React from 'react'
import styled from 'styled-components'
import { themeGet, space } from 'styled-system'
import { rgba } from 'polished'

const StyledDateBlock = styled.div`
  width: 46px;
  height: 50px;
  box-shadow: 0 1px 3px 0px ${props => rgba(props.theme.colors.silverSand, 0.9)};
  font-weight: ${themeGet('fontWeight.bold')};
  text-transform: uppercase;
  line-height: 1;
  background: ${props => props.theme.colors.white};
  flex-shrink: 0;
  ${space};
`

const StyledMonth = styled.div`
  color: ${props => props.theme.colors.white};
  height: 18px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.stTropaz};
  font-size: 12px;
`

const StyledDate = styled.div`
  color: ${props => props.theme.colors.tuatara};
  height: 32px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`

const DateBlock = ({ month, date, ...restProps }) => (
  <StyledDateBlock {...restProps}>
    <StyledMonth>{month}</StyledMonth>
    <StyledDate>{date}</StyledDate>
  </StyledDateBlock>
)

export default DateBlock
