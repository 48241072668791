import { startsWith } from 'ramda'

const PREFIX = '@@webSocket'

export const CONNECTED = `${PREFIX}/CONNECTED`
export const DISCONNECTED = `${PREFIX}/DISCONNECTED`
export const ERROR = `${PREFIX}/ERROR`
export const UPDATE = `${PREFIX}/UPDATE`

export const SUBSCRIPTION_UPDATE = `${PREFIX}/SUBSCRIPTION_UPDATE`
export const SUBSCRIPTION_ERROR = `${PREFIX}/SUBSCRIPTION_ERROR`

export const CONNECT_ERROR = `${PREFIX}/CONNECT_ERROR`

export const isWebSocketType = startsWith(`${PREFIX}/`)
