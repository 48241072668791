import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFocusVisible, focusStyle } from '../../helpers/FocusVisible'

const Label = styled.label`
  position: relative;
  display: flex;
  width: 60px;
  height: 24px;
`

const StyledSwitch = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const StyledSwitchSlider = styled.span`
  position: absolute;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.15s ease-in-out;
  background-color: ${props => {
    if (props.disabled) {
      return props.theme.colors.wildSand
    }

    return props['data-on'] ? props.theme.colors.tussock : props.theme.colors.gallery
  }};
  border-radius: 15px;
  -webkit-transform: translateZ(0);

  [data-focus-visible] + & {
    ${focusStyle}
  }

  &:before {
    content: ' ';
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${props =>
      props.disabled ? props.theme.colors.gallery : props.theme.colors.white};
    transition: transform 0.15s ease-in-out;
    transform: ${props => (props['data-on'] ? 'translateX(38px)' : 'translateX(2px)')};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    backface-visibility: hidden;
  }
`

const StyledOnOffTextHolder = styled.span`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`

const StyledTextOn = styled.span`
  color: ${props => props.theme.colors.white};
  opacity: ${props => (props['data-on'] ? 1 : 0)};
  transform: translateX(${props => (props['data-on'] ? '0' : '-10px')});
  transition: all 0.15s ease-in-out;
`

const StyledTextOff = styled.span`
  color: ${props => (props.disabled ? props.theme.colors.silver : props.theme.colors.gray)};
  opacity: ${props => (props['data-on'] ? 0 : 1)};
  transform: translateX(${props => (props['data-on'] ? '10px' : '0px')});
  transition: all 0.15s ease-in-out;
`

const OnOffTextHolder = ({ on, onText = 'On', offText = 'Off', disabled }) => (
  <StyledOnOffTextHolder>
    <StyledTextOn data-on={on}>{onText}</StyledTextOn>
    <StyledTextOff data-on={on} disabled={disabled}>
      {offText}
    </StyledTextOff>
  </StyledOnOffTextHolder>
)

OnOffTextHolder.propTypes = {
  on: PropTypes.bool.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
}

const Switch = ({ on, id, onText, offText, onToggle, onFocus, onBlur, disabled }) => {
  const focusVisibleProps = useFocusVisible({ onFocus, onBlur })
  const onChange = () => {
    onToggle(!on)
  }

  return (
    <Label>
      <StyledSwitch
        id={id}
        type="checkbox"
        onChange={onChange}
        checked={on}
        disabled={disabled}
        {...focusVisibleProps}
      />
      <StyledSwitchSlider data-on={on} disabled={disabled} />
      <OnOffTextHolder on={on} onText={onText} offText={offText} disabled={disabled} />
    </Label>
  )
}

Switch.propTypes = {
  on: PropTypes.bool,
  onToggle: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onText: PropTypes.string,
  offText: PropTypes.string,
}

export const UncontrolledSwitch = ({ defaultValue = true, ...props }) => {
  const [on, setOn] = React.useState(defaultValue)
  return (
    <Switch
      on={on}
      {...props}
      onToggle={v => {
        setOn(_ => v)
      }}
    />
  )
}
UncontrolledSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  onToggle: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onText: PropTypes.string,
  offText: PropTypes.string,
}

export default Switch
