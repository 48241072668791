import Nes from 'nes/client'
import { eventChannel, END } from 'redux-saga'
import { all, call, cancelled, fork, put, take, takeEvery, spawn } from 'redux-saga/effects'
import { compose, lensProp, lift, map, merge, over, pick } from 'ramda'
import uuid from 'uuid/v4'

import { action as createAction } from '../../utils/action'

import * as handlers from './handlers'
import { CONNECT_ERROR, CONNECTED, DISCONNECTED } from './types'

const { isWebSocketAction } = handlers

const wrapHandler =
  client =>
  handler =>
  (...args) =>
    handler(...args, client)
const createWrapHandlersFn = (fn, handlerNames) => compose(map(fn), pick(handlerNames))

export default class SagaWebSocket {
  constructor(host, authToken) {
    this.id = uuid()

    this._host = host
    this._authToken = authToken
    this._client = null
    this._task = null
    this._connected = false
    this._willReconnect = false
  }

  *getClient() {
    if (this._client) {
      return this._client
    }

    this._client = yield call(() => new Nes.Client(this._host))
    this._task = yield spawn([this, '_listenToClient'])

    return this._client
  }

  setAuthToken(authToken) {
    this._authToken = authToken

    if (this._client) {
      this._client.overrideReconnectionAuth(this._getAuth())
    }
  }

  _createWebSocketChannel() {
    return eventChannel(emit => {
      const client = this._client
      const { onConnect, onDisconnect, onError, onUpdate } = client
      const wrapHandlers = createWrapHandlersFn(compose(lift(emit), wrapHandler(this)), [
        'handleConnect',
        'handleDisconnect',
        'handleError',
        'handleUpdate',
      ])

      const h = wrapHandlers(handlers)

      client.onConnect = h.handleConnect
      client.onDisconnect = h.handleDisconnect
      client.onError = h.handleError
      client.onUpdate = h.handleUpdate

      return () => {
        client.onConnect = onConnect
        client.onDisconnect = onDisconnect
        client.onError = onError
        client.onUpdate = onUpdate
      }
    })
  }

  _createSubscriptionChannel(path) {
    return eventChannel(emit => {
      const handler = update => {
        emit([null, update])
      }

      this._client.subscribe(path, handler).catch(err => {
        emit([err])
        emit(END)
      })

      return () => {
        this._client.unsubscribe(path, handler)
      }
    })
  }

  *_forwardSubscription(path, chan) {
    const channel = yield call([this, '_createSubscriptionChannel'], path)
    const wrapHandlers = createWrapHandlersFn(wrapHandler(this), [
      'handleSubscriptionError',
      'handleSubscriptionUpdate',
    ])
    const h = wrapHandlers(handlers)

    try {
      for (;;) {
        const [err, value] = yield take(channel)

        let action = err ? h.handleSubscriptionError(err) : h.handleSubscriptionUpdate(value)

        action = this._addMetaToAction(action)

        yield put(chan, action)
      }
    } finally {
      if (yield cancelled()) {
        yield call([channel, 'close'])
      }
    }
  }

  *_forwardGlobal(chan, action) {
    if (action.meta.id === this.id) {
      yield put(chan, action)
    }
  }

  *subscribe(path, chan) {
    yield call([this, 'getClient'])
    yield all([
      takeEvery(isWebSocketAction, [this, '_forwardGlobal'], chan),
      fork([this, '_forwardSubscription'], path, chan),
    ])

    if (!this.isConnected() && !this.willReconnect()) {
      yield call([this, 'connect'])
    }
  }

  _addMetaToAction(action) {
    return over(
      lensProp('meta'),
      merge({ willReconnect: this._willReconnect, connected: this._connected }),
      action
    )
  }

  *_listenToClient() {
    const channel = yield call([this, '_createWebSocketChannel'])

    try {
      for (;;) {
        let action = yield take(channel)

        switch (action.type) {
          case CONNECTED:
            this._connected = true
            this._willReconnect = true
            break
          case DISCONNECTED:
            this._connected = false
            this._willReconnect = action.payload.willReconnect
            break

          default:
          // Do nothing
        }

        action = this._addMetaToAction(action)

        yield put(action)
      }
    } finally {
      if (yield cancelled()) {
        yield call([channel, 'close'])
      }
    }
  }

  *connect() {
    if (!this.isConnected() && !this.willReconnect()) {
      yield* this._connect()
    }
  }

  *_connect() {
    const client = yield call([this, 'getClient'])
    const options = {}

    if (this._authToken) {
      options.auth = this._getAuth()
    }

    this._willReconnect = true

    try {
      yield call([client, 'connect'], options)
    } catch (err) {
      this._willReconnect = false
      yield put(
        createAction(CONNECT_ERROR, err, {
          id: this.id,
          host: this._host,
          connected: this._connected,
          willReconnect: this._willReconnect,
          options,
        })
      )
    }
  }

  _getAuth() {
    if (!this._authToken) {
      return null
    }

    return {
      headers: {
        authorization: `Bearer ${this._authToken}`,
      },
    }
  }

  isConnected() {
    return this._connected
  }

  willReconnect() {
    return this._willReconnect
  }
}
