import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const loaderAnimation = keyframes`
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  35.0001% {
    opacity: 0.5;
  }
  66% {
    opacity: 0.5;
  }
  66.0001% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

const sizeToScale = {
  mini: 0.2,
  small: 0.5,
  medium: 1,
  large: 2,
}

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  transform: ${props => (props.size ? `scale(${sizeToScale(props.size)})` : 'scale(1)')};
  .inner {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
  }
  .loader {
    width: 56px;
    height: 48px;
    margin: auto;
  }
  .text {
    color: var(--color-gray);
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
    &:not(:empty) {
      margin-top: 15px;
    }
  }
  .part-top {
    animation: ${loaderAnimation} 6s 1.5s infinite;
  }
  .part-middle-top {
    animation: ${loaderAnimation} 6s 1s infinite;
  }
  .part-middle-bottom {
    animation: ${loaderAnimation} 6s 0.5s infinite;
  }
  .part-bottom {
    animation: ${loaderAnimation} 6s 0s infinite;
  }
`

const Loader = ({ loadingText, size }) => (
  <StyledLoader size={size}>
    <div className="inner">
      <svg width="48" height="56" viewBox="0 0 56 48" className="loader">
        <polygon className="part-top" points="23,9 28,0 29,0 34,9" fill="#1b3549" />
        <polygon className="part-middle-top" points="15,21 20,13 37,13 42,21" fill="#b8b8b8" />
        <polygon className="part-middle-bottom" points="8,34 12,26 45,26 49,34" fill="#b8b8b8" />
        <polygon className="part-bottom" points="0,48 5,39 52,39 56,48" fill="#b8b8b8" />
      </svg>
      {size !== 'mini' && <div className="text">{loadingText}</div>}
    </div>
  </StyledLoader>
)

Loader.propTypes = {
  loadingText: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
}

Loader.defaultProps = {
  loadingText: 'Loading',
}

export default Loader
