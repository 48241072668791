import {
  compose,
  equals,
  head,
  flip,
  findIndex,
  when,
  defaultTo,
  complement,
  always,
  find,
  gte,
  nth,
  inc,
  ifElse,
  isNil,
  dec,
  last,
} from 'ramda'
import { css } from 'styled-components'

/**
 * Breakpoints expressed in minimum sizes. The max size will be calculated
 * as the min size of the next point - 1.
 *
 * For example `sm` has a min size of 576 and a max size of 767.
 */
const breakPoints = [
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200],
]

// (size, arr) => boolean
const isBreakpoint = bp => compose(equals(bp), head)
const findBreakpointIndex = compose(flip(findIndex)(breakPoints), isBreakpoint)
const findBreakpoint = compose(flip(find)(breakPoints), isBreakpoint)

const getBreakpointValue = when(complement(isNil), last)
const ignoreZero = when(equals(0), always(null))
const isFound = flip(gte)(0)
const nextBreakpoint = compose(defaultTo(null), flip(nth)(breakPoints), inc)
const returnUndefined = always(undefined)

const findMaxSize = compose(
  when(complement(isNil), compose(dec, last)),
  ifElse(isFound, nextBreakpoint, returnUndefined),
  findBreakpointIndex
)

const findMinSize = compose(ignoreZero, getBreakpointValue, findBreakpoint)

export const mediaBreakpointUp = bp => {
  const size = findMinSize(bp)

  if (size) {
    return (...args) => css`
      @media (min-width: ${size}px) {
        ${css(...args)};
      }
    `
  }

  return css
}

export const mediaBreakpointDown = bp => {
  const size = findMaxSize(bp)

  if (size) {
    return (...args) => css`
      @media (max-width: ${size}px) {
        ${css(...args)};
      }
    `
  }

  return css
}

export const mediaBreakpoint = bp => {
  const sizeMin = findMinSize(bp)
  const sizeMax = findMaxSize(bp)

  if (sizeMin && sizeMax) {
    return (...args) => css`
      @media (min-width: ${sizeMin}px) and (max-width: ${sizeMax}px) {
        ${css(...args)};
      }
    `
  }

  return css
}
