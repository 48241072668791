import { compose, prop } from 'ramda'
import { createSelector } from 'reselect'

export const sessionSelector = state => state.session

export const selectCurrentUser = createSelector([sessionSelector], session => ({
  currentUser: session.user,
}))

export const selectCurrentChapter = createSelector([sessionSelector], session => ({
  currentChapter: session.chapter,
}))

export const getCurrentChapter = compose(prop('currentChapter'), selectCurrentChapter)

export const getCurrentUser = compose(prop('currentUser'), selectCurrentUser)
