// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function string_of_length(x) {
  if (typeof x !== "object") {
    return "0";
  }
  var variant = x.NAME;
  if (variant === "pxFloat") {
    return x.VAL.toString() + "px";
  }
  if (variant === "vmax") {
    return x.VAL.toString() + "vmax";
  }
  if (variant === "vmin") {
    return x.VAL.toString() + "vmin";
  }
  if (variant === "percent") {
    return x.VAL.toString() + "%";
  }
  if (variant === "ch") {
    return x.VAL.toString() + "ch";
  }
  if (variant === "cm") {
    return x.VAL.toString() + "cm";
  }
  if (variant === "em") {
    return x.VAL.toString() + "em";
  }
  if (variant === "ex") {
    return x.VAL.toString() + "ex";
  }
  if (variant === "mm") {
    return x.VAL.toString() + "mm";
  }
  if (variant === "pt") {
    return String(x.VAL) + "pt";
  }
  if (variant === "px") {
    return String(x.VAL) + "px";
  }
  if (variant === "vh") {
    return x.VAL.toString() + "vh";
  }
  if (variant === "vw") {
    return x.VAL.toString() + "vw";
  }
  if (variant === "rem") {
    return x.VAL.toString() + "rem";
  }
  var match = x.VAL;
  if (match[0] === "sub") {
    return "calc(" + (string_of_length(match[1]) + (" - " + (string_of_length(match[2]) + ")")));
  } else {
    return "calc(" + (string_of_length(match[1]) + (" + " + (string_of_length(match[2]) + ")")));
  }
}

function hex_to_string(x) {
  return "#" + x.VAL;
}

exports.string_of_length = string_of_length;
exports.hex_to_string = hex_to_string;
/* No side effect */
