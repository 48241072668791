import React from 'react'
import styled from 'styled-components'

import GreatBrowsers from 'components/GreatBrowsers'

import { Container } from 'components/MarkupHelpers'
import { fontWeight } from 'uikit/styles/theme'
import PageTitle from 'components/PageTitle'
import PageTitleBs from 'reason/common/PageTitle.bs'
import unsupportedBrowserLogo from './images/unsupported-browser-logo.svg'
import { mediaBreakpointUp } from '../../../styles/media'

const StyledWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  ${mediaBreakpointUp('md')`
    margin: 0 auto 50px auto;
  `};
`

const StyledUnsupportedBrowserLogo = styled.img`
  display: block;
  width: 148px;
`

const StyledText = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.mineShaft};
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  font-weight: ${fontWeight.light};
  ${mediaBreakpointUp('md')`
    font-size: 24px;
  `};
`

const BrowserWarningPage = () => (
  <Container>
    <PageTitleBs title="Upgrade Your Browser" />
    <StyledWrapper>
      <StyledUnsupportedBrowserLogo src={unsupportedBrowserLogo} alt="" />
      <PageTitle
        title="Upgrade your browser to use Top Agent Network"
        centered
        huge
        mt={[20, 20, 25, 30]}
        mb={[40, 40, 50, 60]}
      />
      <StyledText>
        We built Top Agent Network using the latest technology, which makes TAN faster and easier to
        use. Your current browser doesn't support these technologies.
      </StyledText>
    </StyledWrapper>
    <GreatBrowsers />
  </Container>
)

export default BrowserWarningPage
