import { contains, flip, pathOr, propSatisfies } from 'ramda'
import { channel, delay } from 'redux-saga'
import { call, cancel, cancelled, flush, fork, take } from 'redux-saga/effects'

import createSubscription from './createSubscription'
import * as socketTypes from './types'

const INIT_BACKOFF = 1000
const incrementBackoff = b => (b <= 30000 ? b * 2 : b)

function* poller(fn) {
  let backoff = INIT_BACKOFF

  for (;;) {
    yield call(delay, backoff)
    yield fork(fn)
    backoff = incrementBackoff(backoff)
  }
}

const isErrorAction = propSatisfies(
  flip(contains)([
    socketTypes.DISCONNECTED,
    socketTypes.CONNECT_ERROR,
    socketTypes.SUBSCRIPTION_ERROR,
    socketTypes.ERROR,
  ]),
  'type'
)

const NORMAL = 'NORMAL'
const TEMP_DISCONNECTED = 'TEMP_DISCONNECTED'
const PERM_DISCONNECTED = 'PERM_DISCONNECTED'

const willReconnect = pathOr(false, ['meta', 'willReconnect'])
const connected = pathOr(false, ['meta', 'connected'])

export default function* subscribe(path, handler, { poll, reconnect } = {}) {
  const chan = yield call(channel)
  let task
  let pollerTask
  let backoff = INIT_BACKOFF
  let state = NORMAL

  function* doReconnect() {
    if (pollerTask) {
      yield cancel(pollerTask)
      pollerTask = null
    }

    if (reconnect) {
      yield fork(reconnect)
    }

    yield flush(chan)

    state = NORMAL
    backoff = INIT_BACKOFF
  }

  function* waitAndKillTask() {
    yield call(delay, backoff)
    yield cancel(task)

    backoff = incrementBackoff(backoff)
    task = null
  }

  try {
    // Initial load
    for (;;) {
      if (!task || !task.isRunning()) {
        if (task) {
          yield cancel(task)
        }
        task = yield fork(createSubscription, path, chan)
      }

      const action = yield take(chan)
      if (action.type === socketTypes.SUBSCRIPTION_UPDATE) {
        yield fork(handler, action.payload)
      }

      switch (state) {
        case NORMAL: {
          if (isErrorAction(action) && !connected(action)) {
            state = willReconnect(action) ? TEMP_DISCONNECTED : PERM_DISCONNECTED
            if (poll) {
              pollerTask = yield fork(poller, poll)
            }
          }

          if (state === PERM_DISCONNECTED) {
            yield* waitAndKillTask()
          }

          break
        }

        case TEMP_DISCONNECTED: {
          if (connected(action)) {
            yield* doReconnect()
          } else if (!willReconnect(action)) {
            state = PERM_DISCONNECTED
          }

          break
        }

        case PERM_DISCONNECTED: {
          if (connected(action)) {
            yield* doReconnect()
          } else {
            yield* waitAndKillTask()
          }

          break
        }

        default:
        // Do Nothing
      }
    }
  } finally {
    if (yield cancelled()) {
      if (task) {
        yield cancel(task)
      }
      yield call([chan, 'close'])
    }
  }
}
