import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { mediaBreakpointUp } from 'styles/media'
import Image from 'next/image'

import Icon from '../../atoms/Icon'
import { ContentHolder as ContentHolder_, CardWrapper } from '../Card'

const ContentHolder = styled(ContentHolder_)`
  padding: 10px 15px;
`

const ImageHolder = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 150px;
  min-height: 120px;
  background: ${props => props.theme.colors.alto};
  display: flex;
  align-items: center;
  justify-content: center;
  & + ${ContentHolder} {
    border-width: 0 1px 1px 1px;
  }
  ${mediaBreakpointUp('md')`
    height: auto;
    width: 176px;
    & + ${ContentHolder} {
      border-width: 1px 1px 1px 0;
    }
  `};
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
`

const SlimCard = ({ imageSrc, imageOnClick, fallbackIcon, children }) => (
  <CardWrapper>
    <ImageHolder onClick={imageOnClick}>
      {imageSrc ? (
        <Image alt="" layout="fill" objectFit="cover" unoptimized src={imageSrc} />
      ) : (
        <Icon icon={fallbackIcon || 'image'} size={42} color={themeGet('gray')} />
      )}
    </ImageHolder>
    <ContentHolder>{children}</ContentHolder>
  </CardWrapper>
)

export default SlimCard
