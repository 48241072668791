import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

import DateBlock from 'uikit/atoms/DateBlock'

const StyledShowingCard = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.colors.gallery};
  background: ${props => props.theme.colors.alabasterAlt};
  display: flex;
  & + & {
    margin-top: 15px;
  }
`

const StyledInfoHolder = styled.div`
  flex-grow: 1;
  padding-left: 15px;
`

const StyledAddress = styled.div`
  font-size: 16px;
  line-height: 1.25;
  font-weight: ${themeGet('fontWeight.bold')};
  color: ${props => props.theme.colors.tuatara};
  margin-top: 5px;
`

const StyledDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 1.25;
  color: ${props => props.theme.colors.gray};
  margin-top: 5px;
  text-transform: capitalize;
`

const ShowingCard = ({ month, date, address, day, time, renderAddress }) => (
  <StyledShowingCard>
    <DateBlock month={month} date={date} />
    <StyledInfoHolder>
      <StyledAddress>{address || renderAddress()}</StyledAddress>
      <StyledDate>
        {day && <span>{day},&nbsp;</span>}
        {month && date && (
          <span>
            {month} {date}&nbsp;&nbsp;
          </span>
        )}
        {time && <span>{time}</span>}
      </StyledDate>
    </StyledInfoHolder>
  </StyledShowingCard>
)

ShowingCard.defaultProps = {
  renderAddress: () => null,
}

export default ShowingCard
