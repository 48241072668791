import { call, select, spawn, take } from 'redux-saga/effects'

import { CREATE_SESSION_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../constants/actionTypes'
import loginToken from '../loginToken'

import SagaWebSocket from './SagaWebSocket'

function* monitorAuth(client) {
  for (;;) {
    yield take([CREATE_SESSION_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS])

    const token = yield select(state => state.session.token)

    client.setAuthToken(token)
  }
}

let client
let authTask
export default function* createSubscription(path, chan) {
  if (!client) {
    client = new SagaWebSocket(process.env.NEXT_PUBLIC_WS_HOST, loginToken.get())
  }

  if (!authTask || !authTask.isRunning()) {
    authTask = yield spawn(monitorAuth, client)
  }

  yield call([client, 'subscribe'], path, chan)
}
