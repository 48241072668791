import { themeGet, space } from 'styled-system'
import styled from 'styled-components'

const HelpLabel = styled.small`
  font-size: 13px;
  line-height: 18px;
  color: ${themeGet('colors.doveGray')};
  line-height: 18px;
  margin-bottom: 7px;
  display: inline-block;
  ${space}
`
HelpLabel.displayName = 'HelpLabel'

export default HelpLabel
