import { ApolloLink } from 'apollo-link'
import gql from 'graphql-tag'
import fetch from 'unfetch'
import * as Sentry from '@sentry/nextjs'

const environment = process.env.NEXT_PUBLIC_REAL_ENV
const buildId = process.env.NEXT_PUBLIC_BUILD_ID

const isDev = environment === 'development'

class CodeVersionLink extends ApolloLink {
  constructor() {
    super()
    this.lastUpdatedAt = null
    this.isFetching = false
  }
  setApolloClient(client) {
    this.apolloClient = client
  }
  request(operation, forward) {
    const { cache } = operation.getContext()
    const query = gql`
      query GetCodeHasChanged {
        codeHasChanged @client
      }
    `
    const { codeHasChanged } = cache.readQuery({ query })

    const shouldFetch =
      (!this.lastUpdatedAt ||
        new Date().getTime() - this.lastUpdatedAt.getTime() > 5 * 60 * 1000) &&
      !codeHasChanged

    if (!isDev && shouldFetch && !this.isFetching) {
      this.isFetching = true
      fetch(`/api/build-id`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error fetching buildId: ${response.statusText}`)
          }
          return response
        })
        .then(r => r.json())
        .then(({ buildId: latestVersion }) => {
          if (buildId && buildId !== latestVersion && this.apolloClient) {
            this.apolloClient.writeData({ data: { codeHasChanged: true } })
          }
          this.lastUpdatedAt = new Date()
          this.isFetching = false
        })
        .catch(error => {
          Sentry.captureException(error)
        })
    }
    return forward(operation)
  }
}

export default CodeVersionLink
