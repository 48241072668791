import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makeCtaButton = component => {
  const CtaButton = styled(component)`
    && {
      border-color: ${props => (props.disabled ? 'transparent' : props.theme.colors.tussock)};
      color: ${props => props.theme.colors.white};
      background-color: ${props =>
        props.disabled ? rgba(props.theme.colors.tussock, 0.5) : props.theme.colors.tussock};
      @media (hover: hover) {
        &:hover {
          border-color: ${props => props.theme.colors.tussock};
          color: ${props => props.theme.colors.tussock};
          background-color: ${props => props.theme.colors.white};
        }
      }
    }
  `

  CtaButton.propTypes = {
    appearance: PropTypes.string,
  }

  CtaButton.defaultProps = {
    appearance: 'cta',
  }

  CtaButton.displayName = 'CtaButton'
  return CtaButton
}

export default makeCtaButton(Button)
