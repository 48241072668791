import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makeDangerButton = component => {
  const DangerButton = styled(component)`
    border-color: ${props => (props.disabled ? 'transparent' : props.theme.colors.crimson)};
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
      props.disabled ? rgba(props.theme.colors.crimson, 0.5) : props.theme.colors.crimson};
    @media (hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.monza};
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.monza};
      }
    }
  `

  DangerButton.propTypes = {
    appearance: PropTypes.string,
  }

  DangerButton.defaultProps = {
    appearance: 'danger',
  }

  DangerButton.displayName = 'DangerButton'
  return DangerButton
}

export default makeDangerButton(Button)
