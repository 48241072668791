import React from 'react'

import styled from 'styled-components'
import {
  style,
  space,
  fontSize,
  textAlign,
  lineHeight,
  fontWeight,
  fontStyle,
  display,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { getFromThemeIfKey } from './themeColor'

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})
const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
})

const Text = styled.span.withConfig({ shouldForwardProp })`
  ${space}
  ${fontSize}
  color: ${getFromThemeIfKey('color')};
  ${textAlign}
  ${lineHeight}
  ${whiteSpace}
  ${fontWeight}
  ${fontStyle}
  ${textTransform}
  ${display}
  ${props =>
    props.lineClamp
      ? `
    display: -webkit-box;
    -webkit-line-clamp: ${props.lineClamp};
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break:break-word;
  `
      : ''}
`

Text.displayName = 'Text'

export const TextWithOverflow = props => {
  const element = React.useRef(null)
  React.useEffect(() => {
    if (element.current && props.hasOverflowCallback) {
      const resizeCheck = (hasOverflowCallback, currentEl) => {
        if (
          currentEl.offsetHeight < currentEl.scrollHeight ||
          currentEl.offsetWidth < currentEl.scrollWidth
        ) {
          hasOverflowCallback(true)
        } else {
          hasOverflowCallback(false)
        }
      }
      resizeCheck(props.hasOverflowCallback, element.current)
      const handleResize = () => resizeCheck(props.hasOverflowCallback, element.current)
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [element, props.hasOverflowCallback])

  return <Text ref={element} {...props} />
}

export default Text
