// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var FocusVisible = require("../../helpers/FocusVisible");

function useFocusVisible_(prim) {
  return FocusVisible.useFocusVisible(prim);
}

function useFocusVisible(i) {
  var rawProps = FocusVisible.useFocusVisible(i);
  return {
          onFocus: rawProps.onFocus,
          onBlur: rawProps.onBlur,
          "data-focus-visible": rawProps["data-focus-visible"],
          className: rawProps["data-focus-visible"] ? " data-focus-visible " : ""
        };
}

var focusVisibleDropShadowColor = {
  NAME: "hex",
  VAL: "005de1"
};

var focusVisibleFilterShadow = Css.filter({
      hd: {
        NAME: "dropShadow",
        VAL: [
          "zero",
          "zero",
          {
            NAME: "px",
            VAL: 6
          },
          focusVisibleDropShadowColor
        ]
      },
      tl: /* [] */0
    });

var focusStyle_0 = Css.focus({
      hd: Css.outlineStyle("none"),
      tl: /* [] */0
    });

var focusStyle_1 = {
  hd: Css.selector("&.data-focus-visible", {
        hd: focusVisibleFilterShadow,
        tl: /* [] */0
      }),
  tl: /* [] */0
};

var focusStyle = {
  hd: focusStyle_0,
  tl: focusStyle_1
};

exports.useFocusVisible_ = useFocusVisible_;
exports.useFocusVisible = useFocusVisible;
exports.focusVisibleDropShadowColor = focusVisibleDropShadowColor;
exports.focusVisibleFilterShadow = focusVisibleFilterShadow;
exports.focusStyle = focusStyle;
/* focusVisibleFilterShadow Not a pure module */
