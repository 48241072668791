import { createSelector } from 'reselect'

const selectAlertEntities = state => state.alertEntities

const selectAlerts = createSelector(selectAlertEntities, entities => entities.alerts)

const selectAgentMatchLogs = createSelector(
  selectAlertEntities,
  entities => entities.agentMatchLogs
)

const selectAgents = createSelector(selectAlertEntities, entities => entities.agents)

const selectClients = createSelector(selectAlertEntities, entities => entities.clients)

const selectContacts = createSelector(selectAlertEntities, entities => entities.contacts)

const denormalizeClient = (client, contacts) => {
  if (!contacts || contacts.size === 0) return client

  return client.withMutations(c => {
    c.set(
      'contacts',
      c.contacts.map(id => contacts.get(id.toString()))
    )
  })
}

const selectDenormalizedClients = createSelector(
  [selectClients, selectContacts],
  (clients, contacts) => clients.map(c => denormalizeClient(c, contacts))
)

const denormalizeAlert = (alert, agents, agentMatchLogs, clients) =>
  alert.withMutations(a => {
    if (agents && a.agent) {
      a.set('agent', agents.get(a.agent.toString()))
    }

    if (agentMatchLogs && a.agentMatchLog) {
      a.set('agentMatchLog', agentMatchLogs.get(a.agentMatchLog.toString()))
    }

    if (clients && a.clients) {
      a.set(
        'clients',
        a.clients.map(id => clients.get(id.toString()))
      )
    }
  })

export const selectDenormalizedAlerts = createSelector(
  [selectAlerts, selectAgents, selectAgentMatchLogs, selectDenormalizedClients],
  (alerts, agents, agentMatchLogs, clients) =>
    alerts.map(a => denormalizeAlert(a, agents, agentMatchLogs, clients))
)

export const selectNewAlerts = createSelector([selectDenormalizedAlerts], alerts =>
  alerts
    .filter(a => a.alertStatus === 'new')
    .sortBy(a => a.createdAt)
    .reverse()
)
