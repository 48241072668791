// This is a hack to let us send color values from react/reason without changing too much of our structure. It's essentially a specialized `themeGet`.
// Usage: <View background="alabaster" /> or
// <View background="#f00" />,
// assuming View implements this function for the `background` prop
export function getFromThemeIfKey(prop, fallback) {
  return props => {
    const val = Array.isArray(prop) ? prop.map(name => props[name]).find(Boolean) : props[prop]

    if (typeof val === 'function' || (typeof val === 'string' && val.indexOf('#') > -1)) {
      return val
    }
    return props.theme.colors[val || fallback]
  }
}
