// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var FeaturesV3 = require("./FeaturesV3.bs.js");

var useFeatureFlipper = FeaturesV3.useFeatureFlipper;

var JsProvider = {};

var jsProvider = FeaturesV3.JsProvider.make;

exports.useFeatureFlipper = useFeatureFlipper;
exports.JsProvider = JsProvider;
exports.jsProvider = jsProvider;
/* FeaturesV3 Not a pure module */
