import { normalizeAlert } from './alertNormalizers'

const normalizers = {
  PrivateBuyerNeedMatch: normalizeAlert,
  PrivatePropertyMatch: normalizeAlert,
  BuyerNeedMatch: normalizeAlert,
  PropertyMatch: normalizeAlert,
  RentalPropertyMatch: normalizeAlert,
  RentalNeedMatch: normalizeAlert,
  ConversationMessageNotification: normalizeAlert,
}

const normalizerFor = alertType => normalizers[alertType]

export default normalizerFor
