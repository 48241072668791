// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function toString(x) {
  if (x === "half") {
    return "50%";
  } else if (x === "fourth") {
    return "25%";
  } else if (x === "fifth") {
    return "20%";
  } else if (x === "third") {
    return "33.33%";
  } else {
    return "100%";
  }
}

var Percentage = {
  toString: toString
};

var none = "0px";

var xs = "4px";

var s = "8px";

var m = "16px";

var l = "32px";

var xl = "64px";

var xxl = "128px";

function toString$1(v) {
  return v;
}

function ofString(v) {
  return v;
}

function mapProp(x) {
  if (typeof x !== "object") {
    if (x === "none") {
      return none;
    } else if (x === "l") {
      return l;
    } else if (x === "m") {
      return m;
    } else if (x === "s") {
      return s;
    } else if (x === "xl") {
      return xl;
    } else if (x === "xs") {
      return xs;
    } else if (x === "xxl") {
      return xxl;
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "percentage") {
    return toString(x.VAL);
  } else if (variant === "float" || variant === "n") {
    return x.VAL;
  } else if (variant === "em") {
    return String(x.VAL) + "em";
  } else if (variant === "px") {
    return String(x.VAL) + "px";
  } else {
    return x.VAL.map(mapProp);
  }
}

var Space = {
  none: none,
  xs: xs,
  s: s,
  m: m,
  l: l,
  xl: xl,
  xxl: xxl,
  toString: toString$1,
  ofString: ofString,
  mapProp: mapProp
};

function toString$2(v) {
  return v;
}

function ofString$1(v) {
  return v;
}

var Breakpoint = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  toString: toString$2,
  ofString: ofString$1
};

exports.Percentage = Percentage;
exports.Space = Space;
exports.Breakpoint = Breakpoint;
/* No side effect */
