// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");

function toJs(x) {
  if (typeof x !== "object") {
    return Css_AtomicTypes.Color.toString(x);
  }
  var variant = x.NAME;
  if (variant === "var" || variant === "varDefault") {
    return Css_AtomicTypes.Var.toString(x);
  } else {
    return Css_AtomicTypes.Color.toString(x);
  }
}

function optToJs(v) {
  return Core__Option.map(v, toJs);
}

var Color;

var Var;

exports.Color = Color;
exports.Var = Var;
exports.toJs = toJs;
exports.optToJs = optToJs;
/* No side effect */
