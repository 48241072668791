import React from 'react'
import styled from 'styled-components'
import { themeGet, space } from 'styled-system'

import Tooltip from '../Tooltip'

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: ${themeGet('fontWeight.bold')};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 7px;
  flex-wrap: wrap;
  color: ${themeGet('colors.mineShaft')};
  ${space}
`

const StyledOptionalText = styled.small`
  font-weight: ${themeGet('fontWeight.regular')};
  color: ${themeGet('colors.doveGray')};
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }
`
const StyledLabelText = styled.span`
  margin-right: 4px;
`

const Label = props => {
  const { labelText, optionalText, tooltipText, ...restProps } = props
  return (
    <StyledLabel {...restProps}>
      <StyledLabelText>{labelText}</StyledLabelText>
      {optionalText && <StyledOptionalText>{optionalText}</StyledOptionalText>}
      {tooltipText && <Tooltip text={tooltipText} ml="8px" />}
    </StyledLabel>
  )
}

Label.displayName = 'Label'

export default Label
