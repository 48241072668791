import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import PropTypes from 'prop-types'
import theme from 'uikit/styles/theme'
import icons from '../../../../assets/icons.json'

const getPath = name => {
  return icons[name]
}

// eslint-disable-next-line react/prop-types
const Base = ({ icon, size, className, onClick, ...rest }) => {
  const iconPath = getPath(icon)
  if (!iconPath) return false
  return (
    <svg
      className={className}
      viewBox={iconPath.viewBox}
      width={size}
      height={size}
      onClick={onClick}
      fill="currentColor"
      {...rest}
    >
      <path d={iconPath.path} />
    </svg>
  )
}

const Icon = styled(Base)`
  flex: none;
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'inherit')};
  ${space}
  color: ${props => props.color};
`

Icon.displayName = 'Icon'

Icon.defaultProps = {
  icon: 'Logo',
  size: 16,
  theme,
}

const allKeys = Object.keys(icons)

Icon.propTypes = {
  icon: ({ icon }) => {
    if (!allKeys.includes(icon)) {
      return new Error(
        `Failed prop type: Invalid prop icon of value '${icon}' supplied to Icon, expected one of ${allKeys.toString()} is expected`
      )
    }
    return null
  },
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  cursorPointer: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Icon
