import { externalCreateSessionSuccess } from '../actions/sessionActions'
import * as api from '../api'
import * as Sentry from '@sentry/nextjs'

const internals = {}

internals.get = () => internals.token

internals.getWithExpiryCheck = () => {
  if (internals.isUnexpired()) {
    return Promise.resolve(internals.token)
  }
  Sentry.addBreadcrumb({
    category: 'auth',
    message: 'token is expired, generating new token from loginToken.js',
  })

  return api.createSession().then(results => {
    internals.store.dispatch(externalCreateSessionSuccess(results))
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'generated a token from loginToken.js',
    })

    return results.accessToken
  })
}

internals.set = ({ expiresAt, token }) => {
  internals.expiresAt = expiresAt
  internals.token = token
}

internals.isUnexpired = () => {
  if (internals.expiresAt) {
    return Date.now() < internals.expiresAt
  }

  // Technically it's probably better to return false here, but we'll
  // assume that if there never was a login token then it is unexpired, this
  // allows to code to work as it did before except in the case of there being
  // an expired token in the store.
  return true
}

internals.attachToStore = store => {
  const selectToken = state => state.session.token
  const selectExpiresAt = state => state.session.expiresAt

  const handleChange = () => {
    const state = store.getState()

    const expiresAt = selectExpiresAt(state)
    Sentry.addBreadcrumb({
      category: 'auth',
      message: `received new token from the store. expires at: ${expiresAt}`,
    })
    internals.set({
      token: selectToken(state),
      expiresAt,
    })
  }

  if (internals.unsubcribe) {
    internals.unsubscribe()
  }

  internals.store = store
  internals.unsubscribe = store.subscribe(handleChange)
}

const loginToken = {
  attachToStore: internals.attachToStore,
  get: internals.get,
  getWithExpiryCheck: internals.getWithExpiryCheck,
}

export default loginToken

export const getWithExpiryCheck = internals.getWithExpiryCheck
export const get = internals.get
