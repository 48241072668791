// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../reason/common/Env.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AttachmentUrlJs = require("./attachmentUrl.js").default;

function internal_makeAttachmentUrl(prim) {
  return AttachmentUrlJs(prim);
}

function makeAttachmentUrl(width, height, crop, embed, ratio, background, key) {
  var tmp = {
    key: key,
    width: width
  };
  if (height !== undefined) {
    tmp.height = Caml_option.valFromOption(height);
  }
  if (crop !== undefined) {
    tmp.crop = Caml_option.valFromOption(crop);
  }
  if (embed !== undefined) {
    tmp.embed = Caml_option.valFromOption(embed);
  }
  if (ratio !== undefined) {
    tmp.ratio = Caml_option.valFromOption(ratio);
  }
  if (background !== undefined) {
    tmp.background = Caml_option.valFromOption(background);
  }
  return AttachmentUrlJs(tmp);
}

function baseAttachmentUrl(key) {
  return Env.attachment_prefix + ("/" + encodeURIComponent(key));
}

exports.internal_makeAttachmentUrl = internal_makeAttachmentUrl;
exports.makeAttachmentUrl = makeAttachmentUrl;
exports.baseAttachmentUrl = baseAttachmentUrl;
/* Env Not a pure module */
