import styled from 'styled-components'

const ButtonsHolder = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ButtonsHolder
