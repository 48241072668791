// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("./reason/common/Env.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var GraphQLWSClient = require("./GraphQLWSClient.bs.js");
var Utils__LoginToken = require("./utils/Utils__LoginToken.bs.js");

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("RelayEnv.Graphql_error");

function fetchQuery(operation, variables, _cacheConfig, _uploadables) {
  return Utils__LoginToken.getWithExpiryCheck().then(function (token) {
                var headers = Core__Array.keepSome([
                      [
                        "content-type",
                        "application/json"
                      ],
                      [
                        "accept",
                        "application/json"
                      ],
                      token !== undefined ? [
                          "Authorization",
                          "Bearer " + token
                        ] : undefined
                    ]);
                return fetch(Env.apiPath + "/graphql", {
                            method: "POST",
                            body: Caml_option.some(JSON.stringify(Object.fromEntries([
                                          [
                                            "query",
                                            operation.text
                                          ],
                                          [
                                            "variables",
                                            variables
                                          ],
                                          [
                                            "operationName",
                                            operation.name
                                          ]
                                        ]))),
                            headers: Caml_option.some(new Headers(headers))
                          });
              }).then(function (resp) {
              if (resp.ok) {
                return resp.json();
              } else {
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Request failed: " + resp.statusText
                          });
              }
            });
}

var network = RelayRuntime.Network.create(fetchQuery, Caml_option.option_get(Core__Option.map(GraphQLWSClient.client, (function (wsClient) {
                return function (param, param$1, param$2) {
                  var subscriptionQuery_operationName = param.name;
                  var subscriptionQuery_query = param.text;
                  var subscriptionQuery = {
                    operationName: subscriptionQuery_operationName,
                    query: subscriptionQuery_query,
                    variables: param$1
                  };
                  return RelayRuntime.Observable.create(function (sink) {
                              var unsubscribe = wsClient.subscribe(subscriptionQuery, sink);
                              return {
                                      unsubscribe: unsubscribe,
                                      closed: false
                                    };
                            });
                };
              }))));

var environment = RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined, undefined), (function (nodeObj) {
        return nodeObj.nodeId;
      }), undefined, undefined, undefined, undefined, undefined);

exports.network = network;
exports.environment = environment;
/* network Not a pure module */
