// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var LoginTokenJs = require("./loginToken.js");

function getWithExpiryCheck_(prim) {
  return LoginTokenJs.getWithExpiryCheck();
}

function get_(prim) {
  return LoginTokenJs.get();
}

function getWithExpiryCheck(param) {
  return LoginTokenJs.getWithExpiryCheck().then(function (x) {
              return Promise.resolve((x == null) ? undefined : Caml_option.some(x));
            });
}

exports.getWithExpiryCheck_ = getWithExpiryCheck_;
exports.get_ = get_;
exports.getWithExpiryCheck = getWithExpiryCheck;
/* ./loginToken.js Not a pure module */
