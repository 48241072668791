import React from 'react'
import { View } from 'uikit'

const Container = props => (
  <View
    id="container"
    width="100%"
    px="16px"
    mx="auto"
    maxWidth={['none', 'none', '720px', '960px', '1140px', '1320px']}
    {...props}
  />
)

export default Container
