// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var stripe_key = process.env.NEXT_PUBLIC_STRIPE_KEY;

var google_key = process.env.NEXT_PUBLIC_GOOGLE_KEY;

var attachment_prefix = process.env.NEXT_PUBLIC_ATTACHMENT_PREFIX;

var node_env = process.env.NODE_ENV;

var apiPath = process.env.NEXT_PUBLIC_API_PATH;

var graphqlWsHost = process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_WS_HOST;

exports.stripe_key = stripe_key;
exports.google_key = google_key;
exports.attachment_prefix = attachment_prefix;
exports.node_env = node_env;
exports.apiPath = apiPath;
exports.graphqlWsHost = graphqlWsHost;
/* stripe_key Not a pure module */
