import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fontWeight } from 'uikit/styles/theme'
import Icon from 'uikit/atoms/Icon'
import View from 'helpers/View'

const StyledLi = styled.li`
  display: flex;
`

const StyledList = styled(View)`
  list-style: none;
  ${StyledLi} + ${StyledLi} {
    margin-top: ${props => props.itemSpacing};
  } ;
`

const List = ({
  items,
  type,
  fontSize,
  iconColor,
  iconSize,
  iconMarginTop,
  itemSpacing,
  ...restProps
}) => {
  return (
    <StyledList
      as="ul"
      itemSpacing={itemSpacing}
      fontSize={fontSize}
      lineHeight={1.5}
      m={0}
      p={0}
      fontWeight={fontWeight.medium}
      {...restProps}
    >
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledLi key={index}>
          <Icon
            icon={type}
            size={iconSize}
            color={iconColor}
            mr={type === 'bullet' ? '5px' : '10px'}
            mt={iconMarginTop || '3px'}
          />
          {item}
        </StyledLi>
      ))}
    </StyledList>
  )
}

List.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['bullet', 'unable', 'ok-semicircled', 'ok-circled-alt']),
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  itemSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

List.defaultProps = {
  type: 'bullet',
  iconColor: 'stTropaz',
  iconSize: 24,
  fontSize: '16px',
  itemSpacing: '35px',
  iconMarginTop: 0,
}

List.displayName = 'List'

export default List
