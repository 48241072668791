import React from 'react'
import styled from 'styled-components'

import { iconDefault } from '../../styles/svgIcons'

const StyledG = styled.g`
  transition: 150ms;
`

const SvgIcon = props => {
  const { icon = iconDefault, size = 16, color = 'currentColor', className } = props
  return (
    <svg
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill={color}
      className={className}
    >
      <StyledG dangerouslySetInnerHTML={{ __html: icon }} />
    </svg>
  )
}

export default SvgIcon
