// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../utils/Utils.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function fromList(x) {
  if (x) {
    return [
            x.hd,
            x.tl
          ];
  }
  
}

function toList(param) {
  return {
          hd: param[0],
          tl: param[1]
        };
}

function fromArray(arr) {
  return fromList(Core__List.fromArray(arr));
}

function toArray(t) {
  return Core__List.toArray(toList(t));
}

function map(f, param) {
  return [
          Curry._1(f, param[0]),
          List.map(f, param[1])
        ];
}

function findFirst(f, param) {
  var x = param[0];
  if (Curry._1(f, x)) {
    return Caml_option.some(x);
  } else {
    return Utils.List.findFirst(f, param[1]);
  }
}

function head(param) {
  return param[0];
}

function tail(param) {
  return param[1];
}

function make(x, xs) {
  return [
          x,
          xs
        ];
}

exports.fromList = fromList;
exports.toList = toList;
exports.fromArray = fromArray;
exports.toArray = toArray;
exports.map = map;
exports.findFirst = findFirst;
exports.head = head;
exports.tail = tail;
exports.make = make;
/* Utils Not a pure module */
