import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fontWeight, defaultInputStyles } from 'uikit/styles/theme'

const StyledFormGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.mineShaft};
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  margin-bottom: 10px;
  line-height: 1;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

const StyledInput = styled.input`
  ${defaultInputStyles}
  transition: 150ms;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => {
    if (!props.validationState) {
      return props.theme.colors.alto
    }

    return props.validationState === 'isValid'
      ? props.theme.colors.chelseaCucumber
      : props.theme.colors.crimson
  }};

  appearance: none;
  box-shadow: none;
  border-radius: 0;
  color: ${props => props.theme.colors.mineShaft};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  width: 100%;
  &::placeholder {
    color: ${props => props.theme.colors.gray};
    opacity: 0.5;
  }
  &:focus {
    border-color: ${props => props.theme.colors.goldTips};
  }
`

const StyledErrorMessage = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.crimson};
  display: block;
`

const StyledHelpText = styled.div`
  color: ${props => props.theme.colors.doveGray};
  font-size: 13px;
  line-height: 1.5;
  margin-top: 10px;
`

const FormGroup = ({ label, validationState, errorMessage, helpText, ...props }) => (
  <StyledFormGroup>
    <StyledLabel>{label}</StyledLabel>
    <StyledInput validationState={validationState} {...props} />
    {validationState === 'isError' && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
  </StyledFormGroup>
)

FormGroup.propTypes = {
  errorMessage: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  validationState: PropTypes.string,
}

export default FormGroup

export {
  StyledInput as Input,
  StyledLabel as Label,
  StyledFormGroup as FormGroupContainer,
  StyledErrorMessage as ErrorMessage,
  StyledHelpText as HelpText,
}
