import { fromJS } from 'immutable'
import { combineReducers } from 'redux'

import {
  Agent,
  AgentMap,
  BuyerNeed,
  BuyerNeedMap,
  BuyerNeedLocation,
  BuyerNeedLocationMap,
  Client,
  ClientMap,
  Contact,
  ContactMap,
  Match,
  MatchMap,
  Property,
  PropertyMap,
} from './models'

import {
  CREATE_BUYER_NEEDS_SUCCESS,
  FETCH_BUYER_SUCCESS,
  FETCH_BUYER_NEED_MATCHES_SUCCESS,
  DISMISS_MATCH,
} from '../../constants/actionTypes'

const createEntityReducer = (entityKey, EntityClass, MapClass) => {
  const mergeEntities = (state, newEntities) =>
    state.merge(newEntities.map(entity => new EntityClass(entity)))

  const initialState = new MapClass()

  return (state = initialState, action) => {
    switch (action.type) {
      case CREATE_BUYER_NEEDS_SUCCESS:
      case FETCH_BUYER_SUCCESS:
      case FETCH_BUYER_NEED_MATCHES_SUCCESS: {
        if (!action.data.entities[entityKey]) {
          return state
        }

        return mergeEntities(state, fromJS(action.data.entities[entityKey]))
      }

      default:
        return state
    }
  }
}

const enhanceMatches = reducer => (state, action) => {
  const newState = reducer(state, action)

  switch (action.type) {
    case DISMISS_MATCH: {
      const { id } = action.payload

      return newState.setIn([id.toString(), 'dismissed'], true)
    }

    default:
      return newState
  }
}

const agents = createEntityReducer('agents', Agent, AgentMap)
const buyerNeeds = createEntityReducer('buyerNeeds', BuyerNeed, BuyerNeedMap)
const buyerNeedLocations = createEntityReducer(
  'buyerNeedLocations',
  BuyerNeedLocation,
  BuyerNeedLocationMap
)
const clients = createEntityReducer('clients', Client, ClientMap)
const contacts = createEntityReducer('contacts', Contact, ContactMap)
const properties = createEntityReducer('properties', Property, PropertyMap)
const matches = createEntityReducer('matches', Match, MatchMap)

const clientEntities = combineReducers({
  agents,
  buyerNeeds,
  buyerNeedLocations,
  clients,
  contacts,
  matches: enhanceMatches(matches),
  properties,
})

export default clientEntities
