import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import View from '../../helpers/View'
import Icon from '../Icon'

const StyledTooltip = styled(View)`
  bottom: 100%;
  left: 8px;
  transform: translate(-50%, -10px);
  &:before {
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translate(-50%, 0);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 8px 0 8px;
    border-color: ${props => props.theme.colors.mineShaft} transparent transparent transparent;
  }
`

const Tooltip = ({ text, ...restProps }) => {
  const node = useRef()
  const [isOpened, toggle] = useState(false)
  const toggleTooltip = () => toggle(!isOpened)
  const closeTooltip = () => toggle(false)

  useEffect(() => {
    const outsideClick = e => {
      if (node.current.contains(e.target)) {
        return
      }
      closeTooltip()
    }
    document.addEventListener('click', outsideClick)
    return () => {
      document.removeEventListener('click', outsideClick)
    }
  }, [])

  return (
    <View {...restProps} position="relative" width="16px" height="16px" ref={node}>
      {isOpened && (
        <StyledTooltip
          position="absolute"
          background="mineShaft"
          p="10px 15px"
          color="white"
          fontSize={['12px', '14px']}
          lineHeight={1.5}
          width={['160px', '200px']}
        >
          {text}
        </StyledTooltip>
      )}
      <Icon
        icon="question"
        size="16px"
        color={isOpened ? 'tussock' : 'silver'}
        onClick={toggleTooltip}
        cursorPointer
      />
    </View>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Tooltip
