import { Record, List, OrderedMap } from 'immutable'

export const Agent = Record({
  id: undefined,
  company: undefined,
  email: undefined,
  phone: undefined,
  firstName: undefined,
  lastName: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  avatar: undefined,
})

export const Client = Record({
  id: undefined,
  contacts: List(),
  buyerNeeds: List(),
  properties: List(),
  notes: undefined,
  createdAt: undefined,
})

export const Contact = Record({
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined,
  createdAt: undefined,
})

export const BuyerNeed = Record({
  id: undefined,
  agent: undefined,
  client: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  propertyTypes: undefined,
  initialMatchCompleted: undefined,
  remarks: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  visibility: undefined,
  locations: List(),
  matches: List(),
  status: undefined,
  statusChangeAt: undefined,
  statusChangeComment: undefined,
})

export const BuyerNeedLocation = Record({
  id: undefined,
  position: undefined,
  cityId: undefined,
  city: undefined,
  neighborhoodIds: List(),
  neighborhoods: List(),
})

export const Property = Record({
  id: undefined,
  address: undefined,
  agent: undefined,
  client: undefined,
  baths: undefined,
  beds: undefined,
  city: undefined,
  cityId: undefined,
  createdAt: undefined,
  description: undefined,
  hideAddress: undefined,
  initialMatchCompleted: undefined,
  livingArea: undefined,
  goingOnMls: undefined,
  lotSize: undefined,
  maxPrice: undefined,
  minPrice: undefined,
  neighborhood: undefined,
  neighborhoodId: undefined,
  numberOfUnits: undefined,
  onMlsAt: undefined,
  price: undefined,
  priceRange: undefined,
  propertyType: undefined,
  updatedAt: undefined,
  visibility: undefined,
  images: List(),
  matches: List(),
  isPrivate() {
    return this.visibility === 'private'
  },
  status: undefined,
  statusChangeAt: undefined,
  statusChangeComment: undefined,
  soldPrice: undefined,
  hideSoldPrice: undefined,
})

export const Match = Record({
  id: undefined,
  agent: undefined,
  source: undefined,
  sourceId: undefined, // deprecate
  sourceType: undefined,
  target: undefined,
  targetType: undefined,
  property: undefined,
  buyerNeed: undefined,
  dismissed: undefined,
  matchTiming: undefined,
  notes: undefined,
  matchedAt: undefined,
  createdAt: undefined,
  updatedAt: undefined,
})

export {
  OrderedMap as AgentMap,
  OrderedMap as BuyerNeedMap,
  OrderedMap as BuyerNeedLocationMap,
  OrderedMap as ClientMap,
  OrderedMap as ContactMap,
  OrderedMap as PropertyMap,
  OrderedMap as MatchMap,
}
