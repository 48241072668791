/* eslint-disable prefer-destructuring */
import * as colors from './colors'
import * as darkColors from './darkColors'

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
}

const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],

  space: [0, 4, 8, 16, 32, 64, 128],

  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],

  colors: { ...colors },

  fontWeight,

  shadows: {
    2: '1px 1px 3px rgba(51,51,51,.08)',
    3: '0 1px 3px rgba(221, 221, 221, 0.6)',
  },
  radi: [0, 2, 4, 6],
  name: 'light',
}
export const defaultInputStyles = props => `
  border-width: 1px;
  border-style: solid;
  background-color: ${props.theme.colors.white};
  border-color: ${props.theme.colors.alto};
  color: ${props.theme.colors.black};
  width: 100%;
  padding: 8px 12px;
  box-shadow: none;
  font-size: 14px;
  line-height: 1.25;
  transition: 150ms;
  margin: 0;
  -webkit-appearance: none;
  border-radius: 0;
  &:hover {
    outline: none;
    border-color: ${props.theme.colors.silver};
  }
  &:focus {
    outline: none;
    border-color: ${props.theme.colors.goldTips};
  }
`

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
theme.breakpoints.xl = theme.breakpoints[3]
theme.breakpoints.xxl = theme.breakpoints[4]

export default theme
export const darkTheme = { ...theme, colors: darkColors, name: 'dark' }
