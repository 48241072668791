import React from 'react'
import styled from 'styled-components'
import { space, themeGet } from 'styled-system'
import { mediaBreakpointUp } from 'styles/media'

const StyledAttributeList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  font-size: ${props => props.fontSize || '14px'};
  line-height: 1.25;
  color: ${themeGet('colors.jumbo')};
  ${space}
`

const StyledAttributeListItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    &:after {
      display: block;
      content: '';
      width: 1px;
      height: 14px;
      background: ${themeGet('colors.jumbo')};
      margin: 0 10px;
    }
  }
  margin-top: 4px;
  ${mediaBreakpointUp('sm')`
    margin-top: 0px;
  `};
`

const StyledItemValue = styled.span`
  margin-right: 4px;
  color: ${themeGet('colors.tuatara')};
  font-weight: ${themeGet('fontWeight.medium')};
`

const StyledItemTitle = styled.span`
  white-space: nowrap;
`

const AttributeList = ({ data, ...restProps }) => (
  <StyledAttributeList {...restProps}>
    {data.map((item, index) => (
      <StyledAttributeListItem key={index}>
        {item.value && <StyledItemValue>{item.value}</StyledItemValue>}
        <StyledItemTitle>{item.title}</StyledItemTitle>
      </StyledAttributeListItem>
    ))}
  </StyledAttributeList>
)

export default AttributeList
