/* eslint-disable new-cap */
import React from 'react'
import { useIntercom } from 'react-use-intercom'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { sessionSelector } from './selectors'
import { INITIALIZED } from './constants/initializationStatus'

import { fetchIntercomHash } from './api'

const mapStateToProps = state => ({
  session: sessionSelector(state),
})

function IntercomComponent({ session: { initializationStatus, isLoggedIn, user } }) {
  const { boot, shutdown, update } = useIntercom()
  const hasBooted = React.useRef(false)
  const { pathname } = useRouter()

  React.useEffect(() => {
    if (initializationStatus !== INITIALIZED) return
    if (isLoggedIn && user) {
      if (!user.impersonated) {
        fetchIntercomHash().then(({ hash: userHash }) => {
          const attrs = {
            // clientAccount ids need to be prefixed to avoid clashes with user ids.
            userId: user.role === 'client' ? `client-${user.id}` : user.id,
            email: user.email,
            userHash,
          }
          if (hasBooted.current) {
            update(attrs)
          } else {
            boot(attrs)
            hasBooted.current = true
          }
        })
      } else {
        const attrs = {
          customAttributes: { is_impersonating: true },
        }

        if (hasBooted.current) {
          update(attrs)
        } else {
          boot(attrs)
          hasBooted.current = true
        }
      }
    } else {
      if (hasBooted.current) shutdown()
      boot(null)
      hasBooted.current = true
    }
  }, [initializationStatus, isLoggedIn, user, boot, shutdown, update])

  React.useEffect(() => {
    if (hasBooted.current) {
      update()
    }
  }, [pathname, update])

  return null
}

const connected = connect(mapStateToProps)(IntercomComponent)
export default connected
