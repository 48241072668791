import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { space, width } from 'styled-system'
import { mediaBreakpointUp } from 'styles/media'
import { getFromThemeIfKey } from 'uikit/helpers/themeColor'

const paddingFull = mediaBreakpointUp('sm')`
  padding: 20px;
`

const paddingDouble = mediaBreakpointUp('sm')`
  padding: 40px;
`

const paddingReducedBottom = `
  padding-bottom: 0 !important;
`

// Needed to generate a class name so that `& + &` works correctly
const BaseContentBox = styled.div`
  padding: 15px;
  ${mediaBreakpointUp('sm')`
    padding: 5px 20px 5px 20px;
  `};
`

const ContentBox = styled(BaseContentBox)`
  background-color: ${getFromThemeIfKey(['background', 'bg', 'backgroundColor'], 'white')};
  height: ${props => (props.height ? props.height : 'auto')};
  border-radius: ${props => (props.borderRadius ? '5px' : 0)};
  ${props => (props.paddingFull ? paddingFull : '')}
  ${props => (props.paddingDouble ? paddingDouble : '')}
  ${props => (props.paddingReducedBottom ? paddingReducedBottom : '')}
  ${props =>
    props.hasShadow
      ? `
  box-shadow: 1px 1px 3px ${rgba(props.theme.colors.mineShaft, 0.08)};
`
      : ''}
  ${props => (props.hasBorder ? `border: 1px solid ${props.theme.colors.alto};` : '')}
  ${space};
  ${width};
`

ContentBox.propTypes = {
  paddingFull: PropTypes.bool,
  paddingDouble: PropTypes.bool,
  paddingReducedBottom: PropTypes.bool,
  hasShadow: PropTypes.bool,
  hasBorder: PropTypes.bool,
}

ContentBox.defaultProps = {
  paddingFull: false,
  paddingDouble: false,
  paddingReducedBottom: false,
  hasShadow: false,
  hasBorder: false,
}

ContentBox.displayName = 'ContentBox'

export default ContentBox
