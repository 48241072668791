// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Sentry = require("../../Sentry.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var GraphqlTag = require("graphql-tag").default;
var GraphQLHelpers = require("../GraphQL/GraphQLHelpers.bs.js");
var TanApolloConsumer = require("../GraphQL/TanApolloConsumer.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CurrentUser__Types = require("./CurrentUser__Types.bs.js");
var ClientAccess__Types__Avatar = require("../../ClientAccess/types/ClientAccess__Types__Avatar.bs.js");
var CurrentUser__Context__Query = require("./CurrentUser__Context__Query.bs.js");

var initialLegacyValue_plan = CurrentUser__Types.Plan.defaultPlan;

var initialLegacyValue = {
  userRole: undefined,
  chapter: undefined,
  user: undefined,
  role: undefined,
  accountOwner: undefined,
  plan: initialLegacyValue_plan,
  tanStaff: false,
  terminatedAccount: false,
  isLoggedIn: false,
  initialized: false,
  setUser: undefined,
  reload: undefined
};

var legacyUnauthenticatedValue_plan = CurrentUser__Types.Plan.defaultPlan;

var legacyUnauthenticatedValue = {
  userRole: undefined,
  chapter: undefined,
  user: undefined,
  role: undefined,
  accountOwner: undefined,
  plan: legacyUnauthenticatedValue_plan,
  tanStaff: false,
  terminatedAccount: false,
  isLoggedIn: false,
  initialized: true,
  setUser: undefined,
  reload: undefined
};

var context = React.createContext("NotCalled");

var make = context.Provider;

var Provider = {
  make: make
};

var _map = {"IDLE":"IDLE","INITIALIZING":"INITIALIZING","INITIALIZED":"INITIALIZED"};

var _revMap = {"IDLE":"IDLE","INITIALIZING":"INITIALIZING","INITIALIZED":"INITIALIZED"};

function reduxInitStatusToJs(param) {
  return _map[param];
}

function reduxInitStatusFromJs(param) {
  return _revMap[param];
}

function CurrentUser__Context$Provider$Component(props) {
  var client = props.client;
  var initializationStatus = props.initializationStatus;
  var token = props.token;
  var match = React.useState(function (param) {
        return "NotCalled";
      });
  var setValue = match[1];
  var value = match[0];
  var updateState = function (param) {
    var q = CurrentUser__Context__Query.GQL.make();
    var exit = 0;
    if (typeof value !== "object") {
      switch (value) {
        case "NotCalled" :
        case "Unauthenticated" :
            exit = 1;
            break;
        default:
          
      }
    }
    if (exit === 1) {
      Curry._1(setValue, (function (param) {
              return "Loading";
            }));
    }
    return client.query(GraphQLHelpers.toQueryObj({
                        query: Curry._1(GraphqlTag, q.query),
                        variables: q.variables,
                        fetchPolicy: "network-only",
                        errorPolicy: "all"
                      })).then(function (x) {
                  return Promise.resolve(Curry._1(CurrentUser__Context__Query.Query.convertJsInputToReason, x));
                }).then(function (result) {
                var data = result.data;
                var tmp;
                if (result.loading) {
                  tmp = Curry._1(setValue, (function (param) {
                          return "Loading";
                        }));
                } else if (data !== undefined) {
                  var errors = Caml_option.valFromOption(data).session;
                  if (errors.TAG === "Ok") {
                    var match = errors._0;
                    if (typeof match !== "object") {
                      tmp = Curry._1(setValue, (function (param) {
                              return "Unauthenticated";
                            }));
                    } else if (match.TAG === "Agent") {
                      var isImpersonated = match.isImpersonated;
                      var agent = match.agent;
                      tmp = Curry._1(setValue, (function (param) {
                              return {
                                      TAG: "Agent",
                                      _0: {
                                        chapter: agent.chapter,
                                        user: agent.user,
                                        role: agent.role,
                                        accountOwner: agent.accountOwner,
                                        plan: agent.plan,
                                        tanStaff: agent.tanStaff,
                                        permissions: agent.permissions,
                                        terminatedAccount: agent.accountStatus === "TERMINATED",
                                        isImpersonated: isImpersonated,
                                        setUser: (function (user) {
                                            Curry._1(setValue, (function (x) {
                                                    if (typeof x !== "object") {
                                                      return "Error";
                                                    }
                                                    if (x.TAG !== "Agent") {
                                                      return "Error";
                                                    }
                                                    var prev = x._0;
                                                    return {
                                                            TAG: "Agent",
                                                            _0: {
                                                              chapter: prev.chapter,
                                                              user: user,
                                                              role: prev.role,
                                                              accountOwner: prev.accountOwner,
                                                              plan: prev.plan,
                                                              tanStaff: prev.tanStaff,
                                                              permissions: prev.permissions,
                                                              terminatedAccount: prev.terminatedAccount,
                                                              isImpersonated: prev.isImpersonated,
                                                              setUser: prev.setUser,
                                                              reload: prev.reload
                                                            }
                                                          };
                                                  }));
                                          }),
                                        reload: (function (param) {
                                            return updateState();
                                          })
                                      }
                                    };
                            }));
                    } else {
                      var isImpersonated$1 = match.isImpersonated;
                      var match$1 = match.client;
                      var hasAnAutonotifyBuyerNeed = match$1.hasAnAutonotifyBuyerNeed;
                      var status = match$1.status;
                      var agent$1 = match$1.agent;
                      var avatar = match$1.avatar;
                      var email = match$1.email;
                      var lastName = match$1.lastName;
                      var firstName = match$1.firstName;
                      var id = match$1.id;
                      tmp = Curry._1(setValue, (function (param) {
                              return {
                                      TAG: "Client",
                                      _0: {
                                        id: id,
                                        firstName: firstName,
                                        lastName: lastName,
                                        email: email,
                                        avatar: ClientAccess__Types__Avatar.make(avatar, firstName, lastName),
                                        agent: agent$1,
                                        hasAnAutonotifyBuyerNeed: hasAnAutonotifyBuyerNeed,
                                        isImpersonated: isImpersonated$1,
                                        setProfileInfo: (function (param) {
                                            var avatar = param.avatar;
                                            var email = param.email;
                                            var lastName = param.lastName;
                                            var firstName = param.firstName;
                                            Curry._1(setValue, (function (x) {
                                                    if (typeof x !== "object") {
                                                      return "Error";
                                                    }
                                                    if (x.TAG !== "Client") {
                                                      return "Error";
                                                    }
                                                    var prev = x._0;
                                                    return {
                                                            TAG: "Client",
                                                            _0: {
                                                              id: prev.id,
                                                              firstName: firstName,
                                                              lastName: lastName,
                                                              email: email,
                                                              avatar: avatar,
                                                              agent: prev.agent,
                                                              hasAnAutonotifyBuyerNeed: prev.hasAnAutonotifyBuyerNeed,
                                                              isImpersonated: prev.isImpersonated,
                                                              setProfileInfo: prev.setProfileInfo,
                                                              reload: prev.reload,
                                                              status: prev.status
                                                            }
                                                          };
                                                  }));
                                          }),
                                        reload: (function (param) {
                                            return updateState();
                                          }),
                                        status: status
                                      }
                                    };
                            }));
                    }
                  } else {
                    Sentry.captureErrorMessage("Decoder errors while decoding CurrentUserContext query", false, true, "CurrentUser__Context.res", 108, Caml_option.some(errors._0), "error", undefined);
                    tmp = Curry._1(setValue, (function (param) {
                            return "Error";
                          }));
                  }
                } else {
                  tmp = Curry._1(setValue, (function (param) {
                          return "Error";
                        }));
                }
                return Promise.resolve(tmp);
              });
  };
  React.useEffect((function (param) {
          if (token !== undefined) {
            if (initializationStatus === "INITIALIZED") {
              Sentry.addBreadcrumb("Refetching user context", "info", "auth");
              updateState();
            }
            
          } else if (initializationStatus === "INITIALIZED") {
            Curry._1(setValue, (function (param) {
                    return "Unauthenticated";
                  }));
          }
          
        }), [
        token,
        initializationStatus
      ]);
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var Component = {
  make: CurrentUser__Context$Provider$Component
};

function CurrentUser__Context$Provider$WithApollo(props) {
  var children = props.children;
  var initializationStatus = props.initializationStatus;
  var token = props.token;
  return JsxRuntime.jsx(TanApolloConsumer.make, {
              children: (function (client) {
                  return JsxRuntime.jsx(CurrentUser__Context$Provider$Component, {
                              children: children,
                              token: (token == null) ? undefined : Caml_option.some(token),
                              initializationStatus: _revMap[initializationStatus],
                              client: client
                            });
                })
            });
}

var WithApollo = {
  make: CurrentUser__Context$Provider$WithApollo
};

var Provider$1 = {
  Provider: Provider,
  reduxInitStatusToJs: reduxInitStatusToJs,
  reduxInitStatusFromJs: reduxInitStatusFromJs,
  Component: Component,
  WithApollo: WithApollo
};

var Query;

var initialValue = "NotCalled";

var currentUserProvider = CurrentUser__Context$Provider$WithApollo;

exports.Query = Query;
exports.initialLegacyValue = initialLegacyValue;
exports.legacyUnauthenticatedValue = legacyUnauthenticatedValue;
exports.initialValue = initialValue;
exports.context = context;
exports.Provider = Provider$1;
exports.currentUserProvider = currentUserProvider;
/* context Not a pure module */
