import React from 'react'
import styled from 'styled-components'
import { space, themeGet } from 'styled-system'

import { mediaBreakpointUp } from 'styles/media'

const StyledPriceAttribute = styled.div`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${themeGet('fontWeight.medium')};
  line-height: 1;
  word-break: keep-all;
  color: ${props => props.color || props.theme.colors.tussock};
  ${space}
  ${mediaBreakpointUp('sm')`
    font-size: ${props => {
      if (props.fontSize) return props.fontSize
      return props.size === 'large' ? '22px' : '18px'
    }}
  `};
`

const PriceAttribute = ({ value, size = 'large', ...restProps }) => (
  <StyledPriceAttribute size={size} {...restProps}>
    {value}
  </StyledPriceAttribute>
)

export default PriceAttribute
