import React from 'react'
import { themeGet } from 'styled-system'
import TextLabel from './TextLabel'

const ProLabel = props => {
  const { children, ...restProps } = props
  return (
    <TextLabel
      borderRadius={0}
      borderColor={themeGet('colors.tussock')}
      bg={themeGet('colors.tussock')}
      color={themeGet('colors.white')}
      fontSize="14px"
      textTransform="none"
      {...restProps}
    >
      {children}
    </TextLabel>
  )
}

export default ProLabel
