import { Record, List, OrderedMap } from 'immutable'

export const Alert = Record({
  id: undefined,
  alertStatus: undefined,
  alertType: undefined,
  alertCause: undefined,
  grouping: undefined,
  agent: undefined,
  agentMatchLog: undefined,
  createdAt: undefined,
  clients: List(),
  isDismissed() {
    return this.alertStatus === 'dismissed'
  },
  conversation: undefined,
})

export const AgentMatchLog = Record({
  id: undefined,
  matchedAt: undefined,
})

export const Agent = Record({
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  avatar: undefined,
})

Agent.prototype.displayName = function displayName() {
  return `${this.firstName} ${this.lastName}`
}

export const Client = Record({
  id: undefined,
  contacts: undefined,
  address: undefined,
  city: undefined,
  neighborhood: undefined,
  updatedAt: undefined,
  displayShortName() {
    const contact = this.contacts.get(0)
    const { firstName } = contact

    if (firstName) {
      return firstName
    }

    return this.address
  },
})

export const Contact = Record({
  id: undefined,
  firstName: undefined,
  lastName: undefined,
})

export {
  OrderedMap as AlertMap,
  OrderedMap as AgentMatchLogMap,
  OrderedMap as ClientMap,
  OrderedMap as ContactMap,
  OrderedMap as AgentMap,
}
