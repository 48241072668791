import React from 'react'
import styled from 'styled-components'
import { themeGet, space } from 'styled-system'

const StyledWarningSection = styled.section`
  font-weight: ${themeGet('fontWeight.medium')};
  font-size: 14px;
  line-height: 1.25;
  border-style: solid;
  border-width: 1px;
  border-color: ${themeGet('colors.chino')};
  background: ${themeGet('colors.citrineWhite')};
  color: #7e784e;
  padding: 20px;
  text-align: center;
  ${space};
  p {
    margin: 0 0 10px 0;
  }
  a {
    color: ${themeGet('colors.stTropaz')};
    &:hover {
      text-decoration: none;
    }
  }
`

const WarningSection = ({ children }) => {
  return <StyledWarningSection>{children}</StyledWarningSection>
}

WarningSection.displayName = 'WarningSection'

export default WarningSection
