import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mediaBreakpointUp } from 'styles/media'
import verifiedMemberLogo from 'images/verified-member-logo.svg'
import View from '../../helpers/View'

const StyledLogo = styled.img`
  display: block;
  width: 154px;
  height: 34px;
  ${mediaBreakpointUp('md')`
    width: 264px;
    height: 58px;
  `};
`

const VerifiedMemberLogo = () => (
  <StyledLogo src={verifiedMemberLogo} alt="Top Agent Network Verified Member" />
)

const VerifiedAgentCard = ({ agentInfo, title, bodyText }) => (
  <View p="20px 0">
    <View fontSize={22} color="tuatara" fontWeight={500} lineHeight={1}>
      {title}
    </View>
    <View
      mt={20}
      alignItems={['flex-start', 'flex-start', 'center']}
      flexDirection={['column', 'column', 'row']}
    >
      <View flexShrink={0}>{agentInfo && agentInfo}</View>
      <View
        display={['none', 'none', 'flex']}
        flexGrow={1}
        justifyContent={'flex-end'}
        alignSelf={'flex-start'}
      >
        <VerifiedMemberLogo />
      </View>
    </View>
    <View color="doveGray" fontSize={14} lineHeight={1.5} mt={20}>
      {bodyText}
    </View>
    <View display={['flex', 'flex', 'none']} mt={'20px'}>
      <VerifiedMemberLogo />
    </View>
  </View>
)

VerifiedAgentCard.propTypes = {
  /** Title to render above the agent info */
  title: PropTypes.string,
  /** Help text rendered at the bottom of the card below the agent info and the TAN logo */
  bodyText: PropTypes.string,
  /** Function to render the agent info below the title */
  agentInfo: PropTypes.element,
}

export default VerifiedAgentCard
