import { assoc, compose, defaultTo, propEq, propSatisfies } from 'ramda'
import * as t from './types'

import { action as _action } from '../../utils/action'

const addClientMetadata = client => compose(assoc('id', client.id), defaultTo({}))
const action = (client, type, payload, meta, error) =>
  _action(type, payload, addClientMetadata(client)(meta), error)

export const handleConnect = client => action(client, t.CONNECTED)

export const handleDisconnect = (willReconnect, log, client) =>
  action(client, t.DISCONNECTED, {
    log,
    willReconnect,
  })

export const handleError = (err, client) => action(client, t.ERROR, err)
export const handleUpdate = (msg, client) => action(client, t.UPDATE, { msg })
export const handleSubscriptionUpdate = (msg, client) => action(client, t.SUBSCRIPTION_UPDATE, msg)

export const handleSubscriptionError = (err, client) => action(client, t.SUBSCRIPTION_ERROR, err)

export const isWebSocketAction = propSatisfies(t.isWebSocketType, 'type')
export const isConnectAction = propEq(t.CONNECTED, 'type')
