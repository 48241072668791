import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { mediaBreakpointDown } from 'styles/media'
import { useFocusVisible, focusStyle } from '../../helpers/FocusVisible'

const mobileStyles = checked => {
  return mediaBreakpointDown('sm')`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${checked ? themeGet('colors.stTropaz') : themeGet('colors.alto')};
    width: 100%;
  `
}

const StyledLabel = styled.label`
  min-height: 18px;
  font-size: 14px;
  line-height: 1.25;
  display: flex;
  color: ${props => (props.checked ? themeGet('colors.mineShaft') : themeGet('colors.gray'))};
  font-weight: ${themeGet('fontWeight.regular')};
  margin: 0;
  position: relative;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  ${props => (props.mobileStyles ? mobileStyles(props.checked) : '')};
`

const HiddenInput = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border: none;
  z-index: 0;
`

const StyledCheckMark = styled.div`
  margin-top: 1px;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${props => (props.checked ? themeGet('colors.stTropaz') : themeGet('colors.alto'))};
  background-color: ${props => themeGet(props.checked ? 'colors.stTropaz' : 'colors.white')};
  color: ${props => (props.checked ? themeGet('colors.white') : 'transparent')};
  [data-focus-visible] + & {
    ${focusStyle};
  }
`

const CheckMark = ({ checked }) => (
  <StyledCheckMark checked={checked}>
    <svg width={10} height={10} viewBox="0 0 32 32">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M27.2 3.2l-16 15.808-6.4-6.016-4.8 4.8 11.2 11.008 20.8-20.8-4.8-4.8z"
      />
    </svg>
  </StyledCheckMark>
)

const Checkbox = ({
  onChange,
  onFocus,
  onBlur,
  name,
  id,
  checked,
  disabled,
  label,
  mobileStyles: mStyles,
}) => {
  const focusProps = useFocusVisible({ onFocus, onBlur })
  return (
    <StyledLabel htmlFor={id} checked={checked} disabled={disabled} mobileStyles={mStyles}>
      <HiddenInput
        type="checkbox"
        name={name}
        id={id}
        checked={checked ? 'checked' : ''}
        disabled={disabled}
        onChange={onChange}
        {...focusProps}
      />
      <CheckMark checked={checked} />
      {label && <span>{label}</span>}
    </StyledLabel>
  )
}

export default Checkbox
