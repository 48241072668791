// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $dot$dot$slash$dot$dot$slash$dot$dot$slash = require("../../../");
var Css_Color = require("../../../../reason/common/Theme/Css_Color.bs.js");
var Icon__Jsx3 = require("../Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function MakeJsx3Button(Component) {
  var Buttons__Jsx3$MakeJsx3Button = function (props) {
    var __centerContent = props.centerContent;
    var centerContent = __centerContent !== undefined ? __centerContent : true;
    return JsxRuntime.jsx(Component.make, {
                onClick: props.onClick,
                disabled: props.disabled,
                size: Core__Option.map(props.size, (function (x) {
                        if (x === "large") {
                          return "large";
                        } else if (x === "medium") {
                          return "medium";
                        } else if (x === "small") {
                          return "small";
                        } else {
                          return "huge";
                        }
                      })),
                width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
                icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
                tooltipText: props.tooltipText,
                renderAs: props.renderAs,
                overlayText: props.overlayText,
                justifyContent: centerContent ? "center" : undefined,
                className: props.className,
                ariaExpanded: props.ariaExpanded,
                ariaOwns: props.ariaOwns,
                id: props.id,
                type: props._type,
                children: props.children
              });
  };
  return {
          make: Buttons__Jsx3$MakeJsx3Button
        };
}

var make = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Button;

function Buttons__Jsx3$MakeJsx3Button(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var Button = {
  make: Buttons__Jsx3$MakeJsx3Button
};

var make$1 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.PrimaryButton;

function Buttons__Jsx3$MakeJsx3Button$1(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$1, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var PrimaryButton = {
  make: Buttons__Jsx3$MakeJsx3Button$1
};

var make$2 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.SecondaryButton;

function Buttons__Jsx3$MakeJsx3Button$2(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$2, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var SecondaryButton = {
  make: Buttons__Jsx3$MakeJsx3Button$2
};

var make$3 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.DarkSecondaryButton;

function Buttons__Jsx3$MakeJsx3Button$3(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$3, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var DarkSecondaryButton = {
  make: Buttons__Jsx3$MakeJsx3Button$3
};

var make$4 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.CtaButton;

function Buttons__Jsx3$MakeJsx3Button$4(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$4, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var CtaButton = {
  make: Buttons__Jsx3$MakeJsx3Button$4
};

var make$5 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.LinkButton;

function Buttons__Jsx3$MakeJsx3Button$5(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$5, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var LinkButton = {
  make: Buttons__Jsx3$MakeJsx3Button$5
};

var make$6 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.DangerButton;

function Buttons__Jsx3$MakeJsx3Button$6(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$6, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              children: props.children
            });
}

var DangerButton = {
  make: Buttons__Jsx3$MakeJsx3Button$6
};

var make$7 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.OutlineButton;

var JS = {
  make: make$7
};

function Buttons__Jsx3$OutlineButton(props) {
  var __centerContent = props.centerContent;
  var centerContent = __centerContent !== undefined ? __centerContent : true;
  return JsxRuntime.jsx(make$7, {
              onClick: props.onClick,
              disabled: props.disabled,
              size: Core__Option.map(props.size, (function (x) {
                      if (x === "large") {
                        return "large";
                      } else if (x === "medium") {
                        return "medium";
                      } else if (x === "small") {
                        return "small";
                      } else {
                        return "huge";
                      }
                    })),
              width: Core__Option.map(props.width, View__Jsx3.Width.$$eval),
              icon: Core__Option.map(props.icon, Icon__Jsx3.iconToString),
              tooltipText: props.tooltipText,
              renderAs: props.renderAs,
              overlayText: props.overlayText,
              justifyContent: centerContent ? "center" : undefined,
              className: props.className,
              ariaExpanded: props.ariaExpanded,
              ariaOwns: props.ariaOwns,
              id: props.id,
              type: props._type,
              hoverColor: Css_Color.optToJs(props.hoverColor),
              color: Css_Color.optToJs(props.color),
              children: Caml_option.some(props.children)
            });
}

var OutlineButton = {
  JS: JS,
  make: Buttons__Jsx3$OutlineButton
};

var make$8 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.PrimaryButtonLink;

var PrimaryButtonLink = {
  make: make$8
};

var make$9 = $dot$dot$slash$dot$dot$slash$dot$dot$slash.SecondaryButtonLink;

var SecondaryButtonLink = {
  make: make$9
};

var Icon;

exports.Icon = Icon;
exports.MakeJsx3Button = MakeJsx3Button;
exports.Button = Button;
exports.PrimaryButton = PrimaryButton;
exports.SecondaryButton = SecondaryButton;
exports.DarkSecondaryButton = DarkSecondaryButton;
exports.CtaButton = CtaButton;
exports.LinkButton = LinkButton;
exports.DangerButton = DangerButton;
exports.OutlineButton = OutlineButton;
exports.PrimaryButtonLink = PrimaryButtonLink;
exports.SecondaryButtonLink = SecondaryButtonLink;
/* make Not a pure module */
