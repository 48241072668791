// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function ifTrue(element, condition) {
  if (condition) {
    return element;
  } else {
    return null;
  }
}

function ifFalse(element, condition) {
  if (condition) {
    return null;
  } else {
    return element;
  }
}

function fromOption(opt, f) {
  return Core__Option.getOr(Core__Option.map(opt, f), null);
}

exports.ifTrue = ifTrue;
exports.ifFalse = ifFalse;
exports.fromOption = fromOption;
/* No side effect */
