import { rgba } from 'polished'
import styled from 'styled-components'

import View from '../../helpers/View'

const InlineWarning = styled(View)`
  color: ${props => props.theme.colors.crimson};
  font-size: 14px;
  line-height: 1.5;
  padding: 20px 15px;
  border-bottom: 1px solid ${props => props.theme.colors.crimson};
  background: ${props => rgba(props.theme.colors.cherub, 0.7)};
`

export default InlineWarning
