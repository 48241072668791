import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { useFocusVisible, focusStyle } from '../../helpers/FocusVisible'
import Heading from '../../helpers/Heading'
import View from '../../helpers/View'
import Text from '../../helpers/Text'

const CircleIconWrapper = styled.div`
  border: 1px solid ${themeGet('colors.alto')};
  color: ${themeGet('colors.white')};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${themeGet('colors.white')};
  margin-right: 8px;
  [data-focus-visible] + & {
    ${focusStyle}
  }
`

const HiddenRadio = styled.input`
  border: 0px;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  opacity: 0;
  &:checked + label ${CircleIconWrapper} {
    color: ${themeGet('colors.stTropaz')};
  }
`

const CircleIcon = ({ ...props }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" {...props}>
    <circle fill="currentColor" cx="6" cy="6" r="4" />
  </svg>
)

const StyledLabel = styled.label`
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: ${themeGet('colors.mineShaft')};
  position: relative;
  font-weight: ${themeGet('fontWeight.regular')};
  margin: 0;
`

const Radio = ({
  onFocus,
  onBlur,
  checked,
  label,
  name,
  onChange,
  className,
  children,
  id,
  disabled,
}) => {
  const focusProps = useFocusVisible({ onFocus, onBlur })
  const id_ = id || label
  return (
    <>
      <HiddenRadio
        onChange={onChange}
        checked={checked}
        type="radio"
        name={name}
        {...focusProps}
        id={id_}
        disabled={disabled}
      />
      <StyledLabel className={className} htmlFor={id_}>
        <CircleIconWrapper>
          <CircleIcon />
        </CircleIconWrapper>
        {label && <span>{label}</span>}
        {children && children}
      </StyledLabel>
    </>
  )
}

const RadioGroupStyledItem = styled(Radio)`
  padding: 16px;
  border-color: var(--color-gallery);
  ${HiddenRadio}:checked+& {
    /* The color below takes the HEX from the theme and adds an opacity value.
     * This is supported  in all our targetted browsers. https://caniuse.com/css-rrggbbaa
     */
    border-color: ${themeGet('colors.stTropaz')}59;
    background-color: aliceblue;
  }
  ${HiddenRadio}:checked+&:not(:first-of-type) {
    /* This is a blue shadow added to cover the bottom border of the 
     * item above the selected one. It's necessary because otherwise there's
     * to target a "previous sibling" in CSS to remove its bottom border.
     */
    box-shadow: 0px -1px 0px 0px ${themeGet('colors.stTropaz')}59;
  }
  ${HiddenRadio}:disabled+& {
    cursor: not-allowed;
  }
  border-style: solid;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0;
  &:first-of-type {
    border-top-width: 1px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  display: flex;
  align-items: flex-start;
  & ${CircleIconWrapper} {
    margin-top: 2px;
  }
  & span {
    line-height: 1.25;
  }
`

const OverlayLabel = styled.span`
  white-space: nowrap;
  background-color: var(--color-tussock);
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.2;
  border-radius: 3px;
  padding: 1px 6px;
  border: 1px solid var(--color-tussock);
`

export const RadioGroupItem = ({ title, description, id, overlay, disabled, ...props }) => {
  return (
    <RadioGroupStyledItem {...props} id={id} disabled={disabled}>
      <View width="100%">
        <View justifyContent="space-between">
          <Heading as="h6" color={disabled ? 'gray' : ''} mb="0">
            {title}
          </Heading>
          {overlay && (
            <div>
              <OverlayLabel>{overlay}</OverlayLabel>
            </div>
          )}
        </View>
        {description ? (
          <Text color={disabled ? 'gray' : ''} mt="8px" as="div">
            {description}
          </Text>
        ) : null}
      </View>
    </RadioGroupStyledItem>
  )
}

export const RadioGroup = ({ children }) => {
  return <View flexDirection="column">{children}</View>
}

export const RadioGroupExample = () => {
  return (
    <RadioGroup>
      <RadioGroupItem
        title="Share the property and my identity"
        description="The buyer agents that match will have access to the property information and your identity."
        id="radio-group-1"
        name="radio-group"
      />
      <RadioGroupItem
        title="Hide my name"
        description="The buyer agents won't know who you are. You can choose to share your identity on conversations"
        id="radio-group-2"
        name="radio-group"
      />
      <RadioGroupItem
        title="Hide the property (NEW!)"
        description="You'll be shown matching buyer needs, but the buyer agents won't know about the match."
        id="radio-group-3"
        name="radio-group"
      />
    </RadioGroup>
  )
}

export default Radio
