// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Router = require("next/router");

function push(url, scroll, shallow, t) {
  t.push(url, scroll !== undefined || shallow !== undefined ? ({
            scroll: scroll,
            shallow: shallow
          }) : undefined);
}

function replace(url, scroll, shallow, t) {
  t.replace(url, scroll !== undefined || shallow !== undefined ? ({
            scroll: scroll,
            shallow: shallow
          }) : undefined);
}

function prefetch(url, t) {
  t.prefetch(url);
}

function Router_query(prim) {
  return prim.query;
}

function Router_pathname(prim) {
  return prim.pathname;
}

function Router_asPath(prim) {
  return prim.asPath;
}

function Router_back(prim) {
  prim.back();
}

function Router_isReady(prim) {
  return prim.isReady;
}

function Router_basePath(prim) {
  return prim.basePath;
}

var Router_Events = {
  on: (function (prim0, prim1) {
      prim0.on(prim1.NAME, prim1.VAL);
    }),
  off: (function (prim0, prim1) {
      prim0.off(prim1.NAME, prim1.VAL);
    }),
  emit: (function (prim0, prim1) {
      prim0.off(prim1.NAME, prim1.VAL);
    })
};

function Router_events(prim) {
  return prim.events;
}

var Router$1 = {
  push: push,
  replace: replace,
  prefetch: prefetch,
  query: Router_query,
  pathname: Router_pathname,
  asPath: Router_asPath,
  back: Router_back,
  isReady: Router_isReady,
  basePath: Router_basePath,
  Events: Router_Events,
  events: Router_events
};

function useRouter(prim) {
  return Router.useRouter();
}

var Link;

var LinkWithSpread;

var Atoms;

var Head;

var $$Image;

exports.Router = Router$1;
exports.useRouter = useRouter;
exports.Link = Link;
exports.LinkWithSpread = LinkWithSpread;
exports.Atoms = Atoms;
exports.Head = Head;
exports.$$Image = $$Image;
/* next/router Not a pure module */
