/* Colors */

/* http://chir.ag/projects/name-that-color is used to get colors names */
// Basic colors
export const white = '#ffffff'
export const black = '#000000'

// Gray colors
export const alabaster = '#fafafa' // zinc 50
export const alabasterAlt = '#fafafa' // zinc 50
export const wildSand = '#f4f4f5' // zinc 100
export const gallery = '#e4e4e7' // zinc 200
export const alto = '#d4d4d8' // zinc 300
export const silverSand = '#a1a1aa' // zinc 400
export const silver = '#a1a1aa' // zinc 400
export const gray = '#71717a' // zinc 500
export const jumbo = '#71717a' // zinc 500
export const doveGray = '#52525b' // zinc 600
export const chicago = '#52525b' // zinc 600

// Dark colors
export const mineShaft = '#3f3f46' // zinc 700
export const tuatara = '#3f3f46' // zinc 700

// Yellow Colors
export const oldLace = '#fdfaec'
export const citrineWhite = '#f9f2d1'
export const chino = '#d2ccb0'
export const driftwood = '#a88542'
export const tussock = '#c99f50'
export const goldTips = '#e9be14'
export const galliano = '#dfbc0c'
export const darkGoldenrod = '#b68f11'

// Blue Colors
export const aquaHaze = '#e9eff3'
export const mystic = '#e3ebf0'
export const wedgewood = '#44799b'
export const calypso = '#2c6585'
export const stTropaz = '#28618d'
export const astral = '#2f71a5'
export const biscay = '#1e4a6b'
export const bigStone = '#1b3549'
export const bigStoneAlt = '#162b3b'
export const bigStoneDark = '#10202c'

// Green Colors
export const peppermint = '#eaf4e4'
export const chelseaCucumber = '#74b34c'
export const apple = '#61a03a'
export const vidaLoca = '#46861f'

// Red Colors
export const crimson = '#dc1a43'
export const monza = '#c20031'
export const carmine = '#a3001c'

// Pale Colors
export const cherub = '#fadde3'
export const whiteLinen = '#f7f1e5'

// Brand Colors
export const facebook = '#3b5998'
export const twitter = '#1da1f2'
export const instagram = '#e1306c'
export const yelp = '#af0606'
export const linkedin = '#0077b5'
export const youtube = '#cd201f'
export const pinterest = '#bd081c'
