import { PROPERTY_SAVE_SUCCESS } from '../constants/actionTypes'

const initialState = {
  draftPropertyId: undefined,
  draftMessageId: undefined,
  draftBuyerNeedId: undefined,
}

export function draft(state = initialState, action) {
  if (!action.meta || !action.meta.draftProperty) {
    return state
  }

  switch (action.type) {
    case PROPERTY_SAVE_SUCCESS:
      return {
        ...state,
        draftPropertyId: action.data.property.id,
      }
    default:
      return state
  }
}
