// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../utils/Utils.bs.js");
var Sentry = require("../Sentry.bs.js");
var TanDate = require("./types/TanDate.bs.js");
var UTCDate = require("./types/UTCDate.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CalendarDate = require("./types/CalendarDate.bs.js");
var NonEmptyList = require("./types/NonEmptyList.bs.js");

function $$return(v, s) {
  return [
          s,
          v
        ];
}

function flatMap(m, k, s) {
  var match = Curry._1(m, s);
  return Curry._2(k, match[1], match[0]);
}

function get(s) {
  return [
          s,
          s
        ];
}

function put(s, param) {
  return [
          s,
          undefined
        ];
}

function runState(m, init) {
  return Curry._1(m, init);
}

var ParameterizedState = {
  $$return: $$return,
  flatMap: flatMap,
  get: get,
  put: put,
  runState: runState
};

function make(s) {
  return {
          hd: s,
          tl: /* [] */0
        };
}

function errors(t) {
  return t;
}

function runConverter(m, builder) {
  return Curry._1(m, {
                TAG: "Ok",
                _0: builder
              })[0];
}

function ok(f, a) {
  return function (param) {
    return flatMap((function (param) {
                  return flatMap(get, (function (builder) {
                                var match = Curry._1(f, a);
                                if (match.TAG === "Ok") {
                                  if (builder.TAG === "Ok") {
                                    var partial_arg = {
                                      TAG: "Ok",
                                      _0: Curry._1(builder._0, match._0)
                                    };
                                    return function (param) {
                                      return [
                                              partial_arg,
                                              undefined
                                            ];
                                    };
                                  }
                                  var partial_arg$1 = {
                                    TAG: "Error",
                                    _0: builder._0
                                  };
                                  return function (param) {
                                    return [
                                            partial_arg$1,
                                            undefined
                                          ];
                                  };
                                }
                                var e = match._0;
                                if (builder.TAG === "Ok") {
                                  var partial_arg$2 = {
                                    TAG: "Error",
                                    _0: e
                                  };
                                  return function (param) {
                                    return [
                                            partial_arg$2,
                                            undefined
                                          ];
                                  };
                                }
                                var partial_arg$3 = {
                                  TAG: "Error",
                                  _0: Core__List.concat(builder._0, e)
                                };
                                return function (param) {
                                  return [
                                          partial_arg$3,
                                          undefined
                                        ];
                                };
                              }), param);
                }), (function (param) {
                  return function (param) {
                    return [
                            param,
                            a
                          ];
                  };
                }), param);
  };
}

function some(f, msg) {
  return function (param) {
    return ok((function (value) {
                  var v = Curry._1(f, value);
                  if (v !== undefined) {
                    return {
                            TAG: "Ok",
                            _0: Caml_option.valFromOption(v)
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: {
                              hd: msg,
                              tl: /* [] */0
                            }
                          };
                  }
                }), param);
  };
}

function $$const(f) {
  return function (param) {
    return ok((function (value) {
                  return {
                          TAG: "Ok",
                          _0: Curry._1(f, value)
                        };
                }), param);
  };
}

var Converter_Error = {
  make: make,
  fromArray: Core__List.fromArray,
  concat: Core__List.concat,
  errors: errors,
  concatMany: Core__List.concatMany
};

var Converter = {
  $$Error: Converter_Error,
  flatMap: flatMap,
  source: $$return,
  runConverter: runConverter,
  ok: ok,
  some: some,
  $$const: $$const
};

function reportErrors(initialMessage, file, line, result) {
  return Utils.Result.mapError((function (error) {
                var msg = initialMessage + (" " + Core__List.toArray(Curry._1(Converter_Error.errors, error)).join(", "));
                Sentry.captureErrorMessage(msg, true, true, file, line, undefined, undefined, undefined);
                return error;
              }), result);
}

function stringOfError(error) {
  return Core__List.toArray(Curry._1(Converter_Error.errors, error)).join(", ");
}

function some$1(v) {
  return function (param) {
    return some((function (param) {
                  return v;
                }), param);
  };
}

function $$const$1(v) {
  return function (param) {
    return ok((function (value) {
                  return {
                          TAG: "Ok",
                          _0: v
                        };
                }), param);
  };
}

function ok$1(v) {
  return function (param) {
    return ok((function (param) {
                  return v;
                }), param);
  };
}

function run(make) {
  return function (param) {
    return Curry._1(param, {
                  TAG: "Ok",
                  _0: make
                })[0];
  };
}

function someOk(v, msg) {
  return function (param) {
    return ok((function (param) {
                  if (v !== undefined) {
                    return v;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(Converter_Error.make, msg)
                          };
                  }
                }), param);
  };
}

function optOk(v) {
  return function (param) {
    return ok((function (param) {
                  if (v !== undefined) {
                    if (v.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: Caml_option.some(v._0)
                            };
                    } else {
                      return {
                              TAG: "Error",
                              _0: v._0
                            };
                    }
                  } else {
                    return {
                            TAG: "Ok",
                            _0: undefined
                          };
                  }
                }), param);
  };
}

function makeError(msg) {
  return Curry._1(Converter_Error.make, msg);
}

function makeFromError(r) {
  return Utils.Result.mapError((function (e) {
                return Curry._1(Converter_Error.make, e);
              }), r);
}

var $$Error = {
  makeFromError: makeFromError
};

function decodeOption(decoder, obj) {
  if (obj === undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var v = Curry._1(decoder, Caml_option.valFromOption(obj));
  if (v.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: Caml_option.some(v._0)
          };
  } else {
    return v;
  }
}

function decodeRequired(message, decoder, obj) {
  if (obj !== undefined) {
    return Curry._1(decoder, Caml_option.valFromOption(obj));
  } else {
    return {
            TAG: "Error",
            _0: Curry._1(Converter_Error.make, message)
          };
  }
}

function decodeArray(decoder, arr) {
  if (arr.length === 0) {
    return {
            TAG: "Ok",
            _0: []
          };
  }
  var a = arr.map(decoder);
  var x = Core__Array.filterMap(a, (function (x) {
          if (x.TAG === "Ok") {
            return ;
          } else {
            return Caml_option.some(x._0);
          }
        }));
  if (x.length !== 0) {
    reportErrors("Errors while decoding array elements: ", "GraphQLConverter.res", 179, {
          TAG: "Error",
          _0: Curry._1(Converter_Error.concatMany, x)
        });
  }
  var a$1 = Core__Array.filterMap(a, Utils.Result.toOption);
  if (a$1.length !== 0) {
    return {
            TAG: "Ok",
            _0: a$1
          };
  } else {
    return {
            TAG: "Error",
            _0: Curry._2(Converter_Error.concat, Curry._1(Converter_Error.make, "Decoding failed for all elements: "), Curry._1(Converter_Error.concatMany, Core__Array.filterMap(a, (function (x) {
                            if (x.TAG === "Ok") {
                              return ;
                            } else {
                              return Caml_option.some(x._0);
                            }
                          }))))
          };
  }
}

function decodeAllOksArray(decoder, arr) {
  if (arr.length === 0) {
    return {
            TAG: "Ok",
            _0: []
          };
  }
  var a = arr.map(decoder);
  var errs = Core__Array.filterMap(a, (function (x) {
          if (x.TAG === "Ok") {
            return ;
          } else {
            return Caml_option.some(x._0);
          }
        }));
  if (errs.length !== 0) {
    return {
            TAG: "Error",
            _0: Curry._2(Converter_Error.concat, Curry._1(Converter_Error.make, "Error decoding array, encountered these errors:"), Curry._1(Converter_Error.concatMany, errs))
          };
  } else {
    return {
            TAG: "Ok",
            _0: Core__Array.filterMap(a, (function (x) {
                    if (x.TAG === "Ok") {
                      return Caml_option.some(x._0);
                    }
                    
                  }))
          };
  }
}

function decodeToNonEmptyList(decoder, arr) {
  if (arr.length === 0) {
    return {
            TAG: "Error",
            _0: Curry._1(Converter_Error.make, "Got empty array when decoding to NonEmptyList")
          };
  }
  var a = arr.map(decoder);
  var x = Core__Array.filterMap(a, (function (x) {
          if (x.TAG === "Ok") {
            return ;
          } else {
            return Caml_option.some(x._0);
          }
        }));
  if (x.length !== 0) {
    reportErrors("Errors while decoding NonEmptyList elements: ", "GraphQLConverter.res", 258, {
          TAG: "Error",
          _0: Curry._1(Converter_Error.concatMany, x)
        });
  }
  var a$1 = NonEmptyList.fromArray(Core__Array.filterMap(a, Utils.Result.toOption));
  if (a$1 !== undefined) {
    return {
            TAG: "Ok",
            _0: a$1
          };
  } else {
    return {
            TAG: "Error",
            _0: Curry._2(Converter_Error.concat, Curry._1(Converter_Error.make, "Decoding failed for all elements for NonEmptyList: "), Curry._1(Converter_Error.concatMany, Core__Array.filterMap(a, (function (x) {
                            if (x.TAG === "Ok") {
                              return ;
                            } else {
                              return Caml_option.some(x._0);
                            }
                          }))))
          };
  }
}

function decodeUTCDate(s) {
  return Utils.Result.mapError(makeError, UTCDate.decode(s));
}

function parseUTCDateFromFloat(s) {
  return Utils.Result.mapError(makeError, UTCDate.parseFromFloat(s));
}

function decodeFloatDate(json) {
  return Utils.Result.mapError(makeError, TanDate.fromFloat(json));
}

function decodeCalendarDate(s) {
  return Utils.Result.mapError(makeError, CalendarDate.decode(s));
}

var Helpers = {
  reportErrors: reportErrors,
  stringOfError: stringOfError,
  flatMap: flatMap,
  source: $$return,
  some: some$1,
  $$const: $$const$1,
  ok: ok$1,
  run: run,
  someOk: someOk,
  optOk: optOk,
  makeError: makeError,
  $$Error: $$Error,
  decodeOption: decodeOption,
  decodeRequired: decodeRequired,
  decodeArray: decodeArray,
  decodeAllOksArray: decodeAllOksArray,
  decodeToNonEmptyList: decodeToNonEmptyList,
  decodeUTCDate: decodeUTCDate,
  parseUTCDateFromFloat: parseUTCDateFromFloat,
  decodeFloatDate: decodeFloatDate,
  decodeCalendarDate: decodeCalendarDate
};

function reportErrors$1(initialMessage, file, line, result) {
  return Utils.Result.mapError((function (error) {
                var msg = initialMessage + (" " + Core__List.toArray(Curry._1(Converter_Error.errors, error)).join(", "));
                Sentry.captureErrorMessage(msg, true, true, file, line, undefined, undefined, undefined);
                return error;
              }), result);
}

function reportOnDecoding(initialMessage, file, line, decoder, obj) {
  return reportErrors$1(initialMessage, file, line, Curry._1(decoder, obj));
}

var Utils$1 = {
  reportErrors: reportErrors$1,
  reportOnDecoding: reportOnDecoding
};

exports.ParameterizedState = ParameterizedState;
exports.Converter = Converter;
exports.Helpers = Helpers;
exports.Utils = Utils$1;
/* Utils Not a pure module */
