import qs from 'qs'

const baseAttachmentUrl = key =>
  `${process.env.NEXT_PUBLIC_ATTACHMENT_PREFIX}/${encodeURIComponent(key)}`

const attachmentUrl = ({
  key,
  width,
  height,
  crop = false,
  embed = false,
  background = '#ebebeb',
  ratio,
}) => {
  const r = parseInt(
    ratio || (typeof window !== 'undefined' ? window.devicePixelRatio : null) || 1,
    10
  )
  const params = {
    w: width * r,
    h: height ? height * r : undefined,
  }

  params.a = false

  if (!crop) {
    params.a = 'max'
    params.c = 'portrait'
    if (embed) {
      params.e = true
      params.b = background
    }
  }

  return `${baseAttachmentUrl(key)}?${qs.stringify(params)}`
}

export default attachmentUrl
export { baseAttachmentUrl }
