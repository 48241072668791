// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var DateFnsTz = require("../DateFns/DateFnsTz.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Types__User = require("../../user/Types__User.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var ReasonApollo = require("reason-apollo/src/ReasonApollo.bs.js");
var GraphQLConverter = require("../GraphQLConverter.bs.js");
var Types__Attachment = require("../types/Types__Attachment.bs.js");

function decodePlan(obj) {
  return {
          type_: obj.type_,
          handle: obj.handle,
          onProFree: obj.handle === "PRO_FREE",
          features: Core__List.fromArray(obj.features)
        };
}

function decodeTimezone(v) {
  return Core__Option.map(v, DateFnsTz.Timezone.make);
}

function decodeAccountOwner(members) {
  var partial_arg = GraphQLConverter.Helpers.someOk(Core__Option.map(members[0], (function (member) {
              return member.user;
            })), "didn't get an account owner inside members");
  return GraphQLConverter.Helpers.run(function (x) {
                return x;
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap((function (param) {
                            return GraphQLConverter.Helpers.source(undefined, param);
                          }), partial_arg, param);
            });
}

function decode(obj) {
  var partial_arg = GraphQLConverter.Helpers.some(obj.chapter)("chapter was None");
  var partial_arg$1 = GraphQLConverter.Helpers.some(obj.account)("account was None");
  var partial_arg$2 = GraphQLConverter.Helpers.someOk(Core__Option.map(obj.account, (function (a) {
              return a.owner;
            })), "account was None");
  var partial_arg$3 = GraphQLConverter.Helpers.some(obj.tanStaff)("tanStaff was None");
  var membership = obj.membership;
  var partial_arg$4 = GraphQLConverter.Helpers.some(membership)("membership was None");
  var partial_arg$5 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.run(function (m) {
              return m.role;
            })(function (param) {
            return GraphQLConverter.Helpers.flatMap((function (param) {
                          return GraphQLConverter.Helpers.source(membership, param);
                        }), partial_arg$4, param);
          }));
  var partial_arg$6 = GraphQLConverter.Helpers.ok(obj.user);
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$6, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$5, param);
  };
  var partial_arg$9 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$3, param);
  };
  var partial_arg$10 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$9, partial_arg$2, param);
  };
  var partial_arg$11 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$10, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (user, role, tanStaff, accountOwner, account, chapter) {
                return {
                        chapter: chapter,
                        user: user,
                        accountOwner: accountOwner,
                        role: role,
                        plan: account.subscription.plan,
                        permissions: obj.permissions,
                        tanStaff: tanStaff,
                        accountStatus: Core__Option.getOr(account.accountStatus, "ACTIVE")
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$11, partial_arg, param);
            });
}

var ppx_printed_query = "fragment LoggedInAgent on User   {\n..." + Types__User.Fragment.User.name + "   \nmembership  {\nrole  \n}\n\npermissions  \ntanStaff  \naccount  {\nid  \naccountStatus  \nowner: members(role: OWNER)  {\nuser  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\nsubscription  {\nid  \nplan  {\nid  \nfeatures  \ntype_: type  \nhandle  \n}\n\n}\n\n}\n\nchapter: defaultChapter  {\nid  \ndisplayName  \npreferences  {\ngranularNotificationSettings  \nimprovedLocations  \nmlsComingSoon  \npayingChapter  \nanonymousMatching  \nhideChapterStats  \ndefaultToChapterPreferenceAreas  \n}\n\ntimeZone  \n}\n\n}\n" + Types__User.Fragment.User.query;

function parse(value) {
  var value$1 = value["membership"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["role"];
    var tmp$1;
    switch (value$2) {
      case "ASSISTANT" :
          tmp$1 = "ASSISTANT";
          break;
      case "ASSOCIATE" :
          tmp$1 = "ASSOCIATE";
          break;
      case "BUYER_SPECIALIST" :
          tmp$1 = "BUYER_SPECIALIST";
          break;
      case "INSIDE_SALES_AGENT" :
          tmp$1 = "INSIDE_SALES_AGENT";
          break;
      case "LISTING_MANAGER" :
          tmp$1 = "LISTING_MANAGER";
          break;
      case "LISTING_SPECIALIST" :
          tmp$1 = "LISTING_SPECIALIST";
          break;
      case "MARKETING_SPECIALIST" :
          tmp$1 = "MARKETING_SPECIALIST";
          break;
      case "OPERATIONS_MANAGER" :
          tmp$1 = "OPERATIONS_MANAGER";
          break;
      case "OWNER" :
          tmp$1 = "OWNER";
          break;
      case "PARTNER" :
          tmp$1 = "PARTNER";
          break;
      case "SHOWING_ASSISTANT" :
          tmp$1 = "SHOWING_ASSISTANT";
          break;
      case "TRANSACTION_COORDINATOR" :
          tmp$1 = "TRANSACTION_COORDINATOR";
          break;
      case "UNKNOWN" :
          tmp$1 = "UNKNOWN";
          break;
      default:
        tmp$1 = "CLIENT_CARE_COORDINATOR";
    }
    tmp = {
      role: tmp$1
    };
  }
  var value$3 = value["permissions"];
  var value$4 = value["tanStaff"];
  var value$5 = value["account"];
  var tmp$2;
  if (value$5 == null) {
    tmp$2 = undefined;
  } else {
    var value$6 = value$5["accountStatus"];
    var tmp$3;
    if (value$6 == null) {
      tmp$3 = undefined;
    } else {
      var tmp$4 = value$6 === "ACTIVE" ? "ACTIVE" : "TERMINATED";
      tmp$3 = tmp$4;
    }
    var value$7 = value$5["owner"];
    var value$8 = value$5["subscription"];
    var value$9 = value$8["plan"];
    var value$10 = value$9["features"];
    var value$11 = value$9["type_"];
    var tmp$5;
    switch (value$11) {
      case "FREE" :
          tmp$5 = "FREE";
          break;
      case "PRO" :
          tmp$5 = "PRO";
          break;
      case "PRO_ONLY" :
          tmp$5 = "PRO_ONLY";
          break;
      default:
        tmp$5 = "COMPLIMENTARY";
    }
    var value$12 = value$9["handle"];
    var tmp$6;
    switch (value$12) {
      case "COMPLIMENTARY" :
          tmp$6 = "COMPLIMENTARY";
          break;
      case "PRO_FREE" :
          tmp$6 = "PRO_FREE";
          break;
      case "PRO_MONTHLY" :
          tmp$6 = "PRO_MONTHLY";
          break;
      case "PRO_QUARTERLY" :
          tmp$6 = "PRO_QUARTERLY";
          break;
      case "PRO_TRIAL" :
          tmp$6 = "PRO_TRIAL";
          break;
      case "PRO_YEARLY" :
          tmp$6 = "PRO_YEARLY";
          break;
      default:
        tmp$6 = "FREE";
    }
    tmp$2 = {
      id: value$5["id"],
      accountStatus: tmp$3,
      owner: decodeAccountOwner(Js_array.map((function (value) {
                  return {
                          user: Curry._1(Types__User.Fragment.User.parse, value["user"])
                        };
                }), value$7)),
      subscription: {
        id: value$8["id"],
        plan: decodePlan({
              id: value$9["id"],
              features: Js_array.map((function (value) {
                      switch (value) {
                        case "CLIENT_ACCESS" :
                            return "CLIENT_ACCESS";
                        case "FORWARD_PROPERTY_EMAIL" :
                            return "FORWARD_PROPERTY_EMAIL";
                        case "GNS" :
                            return "GNS";
                        case "HIDE_PROPERTY" :
                            return "HIDE_PROPERTY";
                        case "INITIATE_PROPERTY_CONVERSATION" :
                            return "INITIATE_PROPERTY_CONVERSATION";
                        case "PROPERTY_DETAILS" :
                            return "PROPERTY_DETAILS";
                        case "SHARE_WITH_CLIENT" :
                            return "SHARE_WITH_CLIENT";
                        case "VIEWING_AGENT" :
                            return "VIEWING_AGENT";
                        default:
                          return "FULL_AGENT_PAGE";
                      }
                    }), value$10),
              type_: tmp$5,
              handle: tmp$6
            })
      }
    };
  }
  var value$13 = value["chapter"];
  var tmp$7;
  if (value$13 == null) {
    tmp$7 = undefined;
  } else {
    var value$14 = Js_json.decodeObject(value$13);
    var tmp$8;
    if (value$14 !== undefined) {
      var value$15 = Js_dict.get(value$14, "id");
      var field_id = value$15 !== undefined ? value$15 : Js_exn.raiseError("Unexpected GraphQL query response");
      var value$16 = Js_dict.get(value$14, "displayName");
      var field_displayName = value$16 !== undefined && !(value$16 == null) ? value$16 : undefined;
      var value$17 = Js_dict.get(value$14, "preferences");
      var field_preferences;
      if (value$17 !== undefined && !(value$17 == null)) {
        var value$18 = Js_json.decodeObject(value$17);
        var tmp$9;
        if (value$18 !== undefined) {
          var value$19 = Js_dict.get(value$18, "granularNotificationSettings");
          var field_granularNotificationSettings = value$19 !== undefined ? (
              value$19 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$20 = Js_dict.get(value$18, "improvedLocations");
          var field_improvedLocations = value$20 !== undefined ? (
              value$20 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$21 = Js_dict.get(value$18, "mlsComingSoon");
          var field_mlsComingSoon = value$21 !== undefined ? (
              value$21 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$22 = Js_dict.get(value$18, "payingChapter");
          var field_payingChapter = value$22 !== undefined ? (
              value$22 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$23 = Js_dict.get(value$18, "anonymousMatching");
          var field_anonymousMatching = value$23 !== undefined ? (
              value$23 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$24 = Js_dict.get(value$18, "hideChapterStats");
          var field_hideChapterStats = value$24 !== undefined ? (
              value$24 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          var value$25 = Js_dict.get(value$18, "defaultToChapterPreferenceAreas");
          var field_defaultToChapterPreferenceAreas = value$25 !== undefined ? (
              value$25 === "ON" ? "ON" : "OFF"
            ) : Js_exn.raiseError("Unexpected GraphQL query response");
          tmp$9 = {
            granularNotificationSettings: field_granularNotificationSettings,
            improvedLocations: field_improvedLocations,
            mlsComingSoon: field_mlsComingSoon,
            payingChapter: field_payingChapter,
            anonymousMatching: field_anonymousMatching,
            hideChapterStats: field_hideChapterStats,
            defaultToChapterPreferenceAreas: field_defaultToChapterPreferenceAreas
          };
        } else {
          tmp$9 = Js_exn.raiseError("Unexpected GraphQL query response");
        }
        field_preferences = tmp$9;
      } else {
        field_preferences = undefined;
      }
      var value$26 = Js_dict.get(value$14, "timeZone");
      var field_timeZone = value$26 !== undefined ? Core__Option.map(!(value$26 == null) ? value$26 : undefined, DateFnsTz.Timezone.make) : Js_exn.raiseError("Unexpected GraphQL query response");
      tmp$8 = {
        id: field_id,
        preferences: field_preferences,
        timeZone: field_timeZone,
        displayName: field_displayName
      };
    } else {
      tmp$8 = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    tmp$7 = tmp$8;
  }
  return decode({
              user: Curry._1(Types__User.Fragment.User.parse, value),
              membership: tmp,
              permissions: Js_array.map((function (value) {
                      if (value === "MEMBERSHIP_REQUESTS") {
                        return "MEMBERSHIP_REQUESTS";
                      } else {
                        return "SWITCH_CHAPTER";
                      }
                    }), value$3),
              tanStaff: !(value$4 == null) ? value$4 : undefined,
              account: tmp$2,
              chapter: tmp$7
            });
}

var name = "LoggedInAgent";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var LoggedInAgent = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var LoggedInAgent$1 = {
  decodePlan: decodePlan,
  decodeTimezone: decodeTimezone,
  decodeAccountOwner: decodeAccountOwner,
  decode: decode,
  LoggedInAgent: LoggedInAgent,
  Fragment: undefined
};

function decode$1(obj) {
  var partial_arg = GraphQLConverter.Helpers.optOk(obj.avatar);
  var partial_arg$1 = GraphQLConverter.Helpers.someOk(obj.agent, "agent was None");
  var partial_arg$2 = GraphQLConverter.Helpers.some(obj.clientEmail)("clientEmail was None");
  var partial_arg$3 = GraphQLConverter.Helpers.some(obj.lastName)("lastName was None");
  var partial_arg$4 = GraphQLConverter.Helpers.some(obj.firstName)("firstName was None");
  var partial_arg$5 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(undefined, param);
                }), partial_arg$4, param);
  };
  var partial_arg$6 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$5, partial_arg$3, param);
  };
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$6, partial_arg$2, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (firstName, lastName, email, agent, avatar) {
                return {
                        id: obj.id,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        avatar: avatar,
                        agent: agent,
                        status: obj.status,
                        hasAnAutonotifyBuyerNeed: obj.agentSavedSearches.length > 0
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg, param);
            });
}

var ppx_printed_query$1 = "fragment LoggedInClient on ClientAccount   {\nid  \nfirstName  \nlastName  \nclientEmail: email  \nstatus  \navatar  {\n..." + Types__Attachment.Fragment.DecodedAttachment.name + "   \n}\n\nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\nagentSavedSearches  {\nid  \n}\n\n}\n" + Types__Attachment.Fragment.DecodedAttachment.query + Types__User.Fragment.User.query;

function parse$1(value) {
  var value$1 = value["firstName"];
  var value$2 = value["lastName"];
  var value$3 = value["clientEmail"];
  var value$4 = value["status"];
  var tmp;
  switch (value$4) {
    case "ACTIVE" :
        tmp = "ACTIVE";
        break;
    case "AGENT_NOT_AUTHORIZED" :
        tmp = "AGENT_NOT_AUTHORIZED";
        break;
    default:
      tmp = "ACCESS_REVOKED";
  }
  var value$5 = value["avatar"];
  var value$6 = value["agent"];
  var value$7 = value["agentSavedSearches"];
  return decode$1({
              id: value["id"],
              firstName: !(value$1 == null) ? value$1 : undefined,
              lastName: !(value$2 == null) ? value$2 : undefined,
              clientEmail: !(value$3 == null) ? value$3 : undefined,
              status: tmp,
              avatar: !(value$5 == null) ? Curry._1(Types__Attachment.Fragment.DecodedAttachment.parse, value$5) : undefined,
              agent: !(value$6 == null) ? Curry._1(Types__User.Fragment.User.parse, value$6) : undefined,
              agentSavedSearches: Js_array.map((function (value) {
                      if (!(value == null)) {
                        return {
                                id: value["id"]
                              };
                      }
                      
                    }), value$7)
            });
}

var name$1 = "LoggedInClient";

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var LoggedInClient = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: name$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var LoggedInClient$1 = {
  decode: decode$1,
  LoggedInClient: LoggedInClient,
  Fragment: undefined
};

function decodeAuthenticatedUser(o) {
  return Core__Option.map(o, (function (x) {
                if (x.NAME === "User") {
                  return Core__Result.map(x.VAL, (function (v) {
                                return {
                                        NAME: "User",
                                        VAL: v
                                      };
                              }));
                } else {
                  return Core__Result.map(x.VAL, (function (v) {
                                return {
                                        NAME: "ClientAccount",
                                        VAL: v
                                      };
                              }));
                }
              }));
}

function decode$2(session) {
  var match = session.authenticatedUser;
  if (match === undefined) {
    if (session.isAuthenticated) {
      return {
              TAG: "Error",
              _0: GraphQLConverter.Helpers.makeError("authenticatedUser was None on response where isAuthenticate=true")
            };
    } else {
      return {
              TAG: "Ok",
              _0: "Unauthenticated"
            };
    }
  }
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: match._0
          };
  }
  var match$1 = match._0;
  if (match$1.NAME === "User") {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Agent",
              agent: match$1.VAL,
              isImpersonated: session.isImpersonated
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Client",
              client: match$1.VAL,
              isImpersonated: session.isImpersonated
            }
          };
  }
}

var ppx_printed_query$2 = "query sessionQuery  {\nsession  {\nauthenticatedUser  {\n__typename\n...on User   {\n...LoggedInAgent   \n}\n\n...on ClientAccount   {\n...LoggedInClient   \n}\n\n}\n\nisAuthenticated  \nisImpersonated  \n}\n\n}\n" + ppx_printed_query + ppx_printed_query$1;

function parse$2(value) {
  var value$1 = value["session"];
  var value$2 = Js_json.decodeObject(value$1);
  var tmp;
  if (value$2 !== undefined) {
    var value$3 = Js_dict.get(value$2, "authenticatedUser");
    var field_authenticatedUser;
    if (value$3 !== undefined) {
      var tmp$1;
      if (value$3 == null) {
        tmp$1 = undefined;
      } else {
        var typename_obj = Js_json.decodeObject(value$3);
        var tmp$2;
        if (typename_obj !== undefined) {
          var typename = Js_dict.get(typename_obj, "__typename");
          if (typename !== undefined) {
            var typename$1 = Js_json.decodeString(typename);
            if (typename$1 !== undefined) {
              switch (typename$1) {
                case "ClientAccount" :
                    tmp$2 = {
                      NAME: "ClientAccount",
                      VAL: parse$1(value$3)
                    };
                    break;
                case "User" :
                    tmp$2 = {
                      NAME: "User",
                      VAL: parse(value$3)
                    };
                    break;
                default:
                  tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
              }
            } else {
              tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
            }
          } else {
            tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
          }
        } else {
          tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
        }
        tmp$1 = tmp$2;
      }
      field_authenticatedUser = decodeAuthenticatedUser(tmp$1);
    } else {
      field_authenticatedUser = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    var value$4 = Js_dict.get(value$2, "isAuthenticated");
    var field_isAuthenticated = value$4 !== undefined ? value$4 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$5 = Js_dict.get(value$2, "isImpersonated");
    var field_isImpersonated = value$5 !== undefined ? value$5 : Js_exn.raiseError("Unexpected GraphQL query response");
    tmp = {
      authenticatedUser: field_authenticatedUser,
      isAuthenticated: field_isAuthenticated,
      isImpersonated: field_isImpersonated
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return {
          session: decode$2(tmp)
        };
}

function make(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables(param) {
  return null;
}

function definition_2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = [
  parse$2,
  ppx_printed_query$2,
  definition_2
];

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var GQL = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var Query = ReasonApollo.CreateQuery({
      query: ppx_printed_query$2,
      parse: parse$2
    });

exports.LoggedInAgent = LoggedInAgent$1;
exports.LoggedInClient = LoggedInClient$1;
exports.decodeAuthenticatedUser = decodeAuthenticatedUser;
exports.decode = decode$2;
exports.GQL = GQL;
exports.Query = Query;
/* Query Not a pure module */
