import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makePrimaryButton = component => {
  const PrimaryButton = styled(component)`
    border-color: ${props => (props.disabled ? 'transparent' : props.theme.colors.stTropaz)};
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
      props.disabled ? rgba(props.theme.colors.stTropaz, 0.5) : props.theme.colors.stTropaz};
    @media (hover: hover) {
      &:hover {
        border-color: ${props => props.theme.colors.tussock};
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.tussock};
      }
    }
    &[data-focus-visible],
    :focus {
      /* Prevent global styles on links from changing the color when rendered as a PrimaryButton */
      color: ${props => props.theme.colors.white};
    }
  `

  PrimaryButton.propTypes = {
    appearance: PropTypes.string,
  }

  PrimaryButton.defaultProps = {
    appearance: 'primary',
  }

  PrimaryButton.displayName = 'PrimaryButton'
  return PrimaryButton
}

export default makePrimaryButton(Button)
