// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function getFirstCharacter(str) {
  return str.slice(0, 1);
}

function make(profilePicture, firstName, lastName) {
  if (profilePicture !== undefined) {
    return {
            TAG: "ProfilePicture",
            _0: profilePicture
          };
  } else {
    return {
            TAG: "Initials",
            _0: firstName.slice(0, 1) + Core__Option.mapOr(lastName, "", getFirstCharacter)
          };
  }
}

exports.getFirstCharacter = getFirstCharacter;
exports.make = make;
/* No side effect */
