import React from 'react'
import styled from 'styled-components'
import { space, themeGet } from 'styled-system'

import Icon from '../Icon'

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${space}
`

const buttonStyles = `
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
`

const StyledFirstLastPageButton = styled.div`
  border: none;
  border-radius: 3px;
  opacity: ${props => (props.disabled ? 0.6 : 1.0)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${buttonStyles}
  ${space}
`

const StyledNextPrevPageButton = styled(StyledFirstLastPageButton)`
  border: 1px solid ${props => props.theme.colors.silver};
`

const StyledNumeration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${props => props.theme.colors.mineShaft};
  font-size: 14px;
  line-height: 1.25;
`

const Pagination = ({
  currentPage,
  totalPages,
  onClickFirst,
  onClickPrevious,
  onClickNext,
  onClickLast,
  ...otherProps
}) => {
  const prevDisabled = currentPage === 1
  const lastDisabled = currentPage === totalPages
  return (
    <StyledPagination {...otherProps}>
      {onClickFirst && (
        <StyledFirstLastPageButton
          disabled={prevDisabled}
          onClick={event => !prevDisabled && onClickFirst && onClickFirst(event)}
        >
          <Icon icon="arrow-double-left" size={15} color={themeGet('colors.mineShaft')} />
        </StyledFirstLastPageButton>
      )}
      <StyledNextPrevPageButton
        disabled={prevDisabled}
        onClick={event => !prevDisabled && onClickPrevious && onClickPrevious(event)}
        ml={10}
        mr={20}
      >
        <Icon icon="arrow-left" size={15} color={themeGet('colors.mineShaft')} />
      </StyledNextPrevPageButton>
      <StyledNumeration>
        Page {currentPage} of {totalPages}
      </StyledNumeration>
      <StyledNextPrevPageButton
        disabled={lastDisabled}
        onClick={event => !lastDisabled && onClickNext && onClickNext(event)}
        ml={20}
        mr={10}
      >
        <Icon icon="arrow-right" size={15} color={themeGet('colors.mineShaft')} />
      </StyledNextPrevPageButton>
      {onClickLast && (
        <StyledFirstLastPageButton
          disabled={lastDisabled}
          onClick={event => !lastDisabled && onClickLast && onClickLast(event)}
        >
          <Icon icon="arrow-double-right" size={15} color={themeGet('colors.mineShaft')} />
        </StyledFirstLastPageButton>
      )}
    </StyledPagination>
  )
}

export default Pagination
