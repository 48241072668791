import SelectImported from 'react-select'
import AsyncSelectImported from 'react-select/async'
import AsyncCreatableSelectImported from 'react-select/async-creatable'
import CreatableSelectImported from 'react-select/creatable'
import styled from 'styled-components'
import React from 'react'

const style = c => styled(c)`
  input {
    flex-grow: 1;
  }
  .autocomplete__input {
    flex-grow: 1;
    display: flex !important;
  }
  .autocomplete__value-container > div:last-child {
    flex: 1;
  }
`
// setting the prefix here rather than form Reason in order to have it next to where the CSS is declared.
const withPrefix = Comp => {
  const C = React.forwardRef((props, ref) => (
    <Comp ref={ref} {...props} classNamePrefix="autocomplete" />
  ))
  C.displayName = `withPrefix(${Comp.displayName || Comp.name || 'component'})`
  return C
}

export const Select = style(withPrefix(SelectImported))
export const AsyncSelect = style(withPrefix(AsyncSelectImported))
export const AsyncCreatableSelect = style(withPrefix(AsyncCreatableSelectImported))
export const CreatableSelect = style(withPrefix(CreatableSelectImported))
