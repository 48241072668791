import React from 'react'
import styled from 'styled-components'
import { themeGet, space } from 'styled-system'

const StyledHighlightSection = styled.section`
  font-weight: ${themeGet('fontWeight.regular')};
  font-size: 14px;
  line-height: 1.25;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.borderColor || themeGet('colors.alto')};
  background: ${props => props.background || themeGet('colors.oldLace')};
  color: ${props => props.color || themeGet('colors.doveGray')};
  padding: 15px;
  ${space}
`

const HighlightSection = ({ background, color, borderColor, children }) => {
  return (
    <StyledHighlightSection background={background} color={color} borderColor={borderColor}>
      {children}
    </StyledHighlightSection>
  )
}

HighlightSection.displayName = 'HighlightSection'

export default HighlightSection
