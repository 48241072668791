import styled from 'styled-components'
import {
  flexWrap,
  flexBasis,
  alignItems,
  justifyContent,
  flexDirection,
  alignSelf,
  order,
  style,
  space,
  borders,
  borderRadius,
  fontSize,
  textAlign,
  lineHeight,
  fontWeight,
  fontStyle,
  width,
  maxWidth,
  height,
  boxShadow,
  backgroundImage,
  position,
  display,
  opacity,
  minWidth,
  overflow,
} from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'

import { getFromThemeIfKey } from './themeColor'

const flexGrow = style({
  prop: 'flexGrow',
  cssProperty: 'flexGrow',
})

const flexShrink = style({
  prop: 'flexShrink',
  cssProperty: 'flexShrink',
})

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})

const flexProps = ['alignItems', 'justifyContent', 'flexDirection', 'flexWrap', 'flexBasis']

export const makeView = component => {
  const ViewComponent = styled(component).withConfig({
    shouldForwardProp,
  })`
    && {
      ${props =>
        flexProps.some(x => props[x] !== null && props[x] !== undefined) ? 'display: flex;' : ''}
      ${space} 
  ${borders} 
  ${borderRadius} 
  ${flexWrap} 
  ${flexBasis} 
  ${alignItems} 
  ${flexDirection} 
  ${justifyContent} 
  ${flexGrow} 
  ${flexShrink} 
  ${alignSelf}
  ${order} 
  ${fontSize} 
  ${textAlign}
  ${lineHeight} 
  ${fontWeight} 
  ${fontStyle} 
  ${textTransform} 
  ${width}
  ${maxWidth}
  ${height}
  ${boxShadow}
  ${backgroundImage}
  ${position}
  ${display}
  ${opacity}
  ${minWidth}
  ${overflow}
  color: ${getFromThemeIfKey('color')};
      background: ${getFromThemeIfKey(['background', 'bg', 'backgroundColor'])};
      border-color: ${getFromThemeIfKey('borderColor')};
    }
  `
  return ViewComponent
}

const View = makeView('div')

View.displayName = 'View'

export default View
