import PropTypes from 'prop-types'
import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'

import {
  space,
  width,
  maxWidth,
  fontSize,
  color,
  fontWeight,
  lineHeight,
  textAlign,
  border,
  style,
  display,
} from 'styled-system'

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})

const headingPropTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...maxWidth.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  textTransform: PropTypes.string,
  color: PropTypes.any,
}

const makeComponent = c => {
  const C = styled(c).withConfig({
    shouldForwardProp,
  })`
    &:last-child {
      margin-bottom: 0;
    }
    ${space} ${width} ${maxWidth} ${fontSize} ${color} ${fontWeight} ${lineHeight} ${textAlign} ${border} ${textTransform} ${display};
  `

  C.propTypes = headingPropTypes

  return C
}

const H1 = makeComponent('h1')
const H2 = makeComponent('h2')
const H3 = makeComponent('h3')
const H4 = makeComponent('h4')
const H5 = makeComponent('h5')
const H6 = makeComponent('h6')

const commonDefaultProps = {
  color: 'tuatara',
  mt: 0,
  mb: '0.5rem',
  lineHeight: 1.25,
  fontWeight: 500,
}

H1.defaultProps = {
  ...commonDefaultProps,
  fontWeight: 300,
  fontSize: ['22px', '22px', '26px', '42px'],
}
H2.defaultProps = {
  ...commonDefaultProps,
  fontWeight: 300,
  fontSize: ['22px', '22px', '26px', '32px'],
}
H3.defaultProps = {
  ...commonDefaultProps,
  fontWeight: 300,
  fontSize: ['20px', '20px', '24px', '26px'],
}
H4.defaultProps = {
  ...commonDefaultProps,
  fontSize: ['17px', '17px', '20px', '22px'],
}
H5.defaultProps = {
  ...commonDefaultProps,
  fontSize: '16px',
}
H6.defaultProps = {
  ...commonDefaultProps,
  fontSize: '14px',
}

export { H1, H2, H3, H4, H5, H6 }
