import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointUp } from 'styles/media'

const StyledStat = styled.div`
  flex-grow: 1;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.gallery};
  }
  ${mediaBreakpointUp('md')`
    width: auto;
    &:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid ${props => props.theme.colors.gallery};
    }
  `};
`

const StyledStatValue = styled.div`
  line-height: 1;
  color: ${props => props.theme.colors.tuatara};
  font-weight: 500;
  font-size: 42px;
`

const StyledStatTitle = styled.div`
  color: ${props => props.theme.colors.gray};
  font-size: 14px;
  line-height: 1.25;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const Stat = ({ label, value }) => {
  return (
    <StyledStat>
      <StyledStatValue>{value}</StyledStatValue>
      <StyledStatTitle>{label}</StyledStatTitle>
    </StyledStat>
  )
}

export default Stat
