import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import Button from './Button'

export const makeOutlineButton = component => {
  const OutlineButton = styled(component)`
    && {
      border-color: ${props =>
        props.disabled
          ? rgba(props.color || props.theme.colors.white, 0.5)
          : props.color || props.theme.colors.white};
      color: ${props =>
        props.disabled
          ? rgba(props.color || props.theme.colors.white, 0.5)
          : props.color || props.theme.colors.white};
      background-color: transparent;
      &:hover {
        color: ${props =>
          props.disabled
            ? rgba(props.hoverColor || props.theme.colors.tuatara, 0.5)
            : props.hoverColor || props.theme.colors.tuatara};
        background-color: ${props =>
          props.disabled
            ? rgba(props.color || props.theme.colors.white, 0.5)
            : props.color || props.theme.colors.white};
      }
      &[data-focus-visible] {
        box-shadow: 0 0 10px 4px ${({ color, theme }) => rgba(color || theme.colors.white, 0.6)};
        filter: none;
      }
    }
  `
  OutlineButton.propTypes = {
    appearance: PropTypes.string,
    color: PropTypes.string,
    hoverColor: PropTypes.string,
  }
  OutlineButton.defaultProps = {
    appearance: 'outline',
  }
  OutlineButton.displayName = 'OutlineButton'
  return OutlineButton
}
export default makeOutlineButton(Button)
