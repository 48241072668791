import axios from 'axios'
import { loginToken } from './utils'
import config from './config'

const u = path => `${config.apiPath}${path}`

export const login = (email, password, rememberMe) =>
  axios
    .post(
      '/api/login',
      {
        username: email,
        password,
        rememberMe,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data)

export const logout = () =>
  axios
    .post(
      '/api/logout',
      {},
      {
        withCredentials: true,
      }
    )
    .then(response => response.data)

export const createSession = async () => {
  const { data } = await axios.post(
    '/api/refresh',
    {},
    {
      withCredentials: true,
    }
  )

  return data
}

export const fetchIntercomHash = async () => {
  const token = loginToken.get()
  const requestConfig = {}

  if (token) {
    requestConfig.headers = {
      Authorization: `Bearer ${token}`,
    }
  }

  const response = await axios.get(u('/intercom_hash'), requestConfig)

  return response.data
}
