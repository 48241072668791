// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function make(v) {
  return v;
}

function toString(v) {
  return v;
}

var Timezone = {
  make: make,
  toString: toString
};

exports.Timezone = Timezone;
/* No side effect */
