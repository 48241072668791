import React from 'react'
import PropTypes from 'prop-types'
import { themeGet, space, width, justifyContent, alignSelf } from 'styled-system'
import styled from 'styled-components'
import Icon from 'uikit/atoms/Icon'
import View from 'helpers/View'
import { useFocusVisible, focusStyle } from '../../helpers/FocusVisible'

const smallButtonStyles = theme => `
  font-size: 13px;
  font-weight: ${theme.fontWeight.medium};
  padding: 4px 10px;
  min-height: 30px;
`

const mediumButtonStyles = theme => `
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeight.bold};
  padding: 6px 15px;
  text-transform: uppercase;
`
const largeButtonStyles = theme => `
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeight.bold};
  padding: 8px 20px;
  text-transform: uppercase;
`

const hugeButtonStyles = theme => `
  font-size: ${theme.fontSizes[2]}px;
  font-weight: ${theme.fontWeight.bold};
  padding: 10px 40px;
  text-transform: uppercase;
`

export const mapSizePropToStyles = {
  small: smallButtonStyles,
  medium: mediumButtonStyles,
  large: largeButtonStyles,
  huge: hugeButtonStyles,
}

export const makeStyledTooltip = component => styled(component)`
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -15px);
  display: none;
  text-transform: initial;
  &:before {
    position: absolute;
    left: 50%;
    bottom: -9px;
    transform: translate(-50%, 0);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 8px 0 8px;
    border-color: ${props => props.theme.colors.mineShaft} transparent transparent transparent;
  }
`
export const makeStyledOverlayText = component => styled(component)`
  top: 0;
  right: 0;
  white-space: nowrap;
  transform: translate(10%, -50%);
`

const makeStyledButton = (component, tooltip) => styled(component)`
  display: inline-flex;
  box-shadow: none;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  line-height: normal;
  align-items: center;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  transition: 150ms;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  background-color: transparent;

  &:disabled {
    pointer-events: none;
  }
  @media (hover: hover) {
    &:hover {
      ${tooltip} {
        display: block;
      }
    }
  }
  &:focus {
    outline: none;
  }

  &[data-focus-visible] {
    ${focusStyle};
  }
  ${props => mapSizePropToStyles[props.size](props.theme)};
  ${space};
  ${width};
  ${justifyContent};
  ${alignSelf};
`

export const makeButton = component => {
  const StyledTooltip = makeStyledTooltip(View)
  const StyledOverlayText = makeStyledOverlayText(View)
  const StyledButton = makeStyledButton(component, StyledTooltip)

  const Button = React.forwardRef(
    (
      {
        appearance,
        icon,
        tooltipText,
        overlayText,
        children,
        onFocus,
        onBlur,
        renderAs,
        ariaExpanded,
        ariaOwns,
        ...restProps
      },
      ref
    ) => {
      const focusVisibleProps = useFocusVisible({ onFocus, onBlur })
      let dependentStyles
      switch (appearance) {
        case 'primary':
          dependentStyles = {
            background: themeGet('colors.white'),
            color: themeGet('colors.stTropaz'),
            border: `1px solid var(--color-st-tropaz)`,
          }
          break
        case 'cta':
          dependentStyles = {
            background: themeGet('colors.white'),
            color: themeGet('colors.tussock'),
            border: `1px solid var(--color-tussock)`,
          }
          break
        default:
          dependentStyles = {}
          break
      }

      return (
        <StyledButton
          aria-expanded={ariaExpanded}
          aria-owns={ariaOwns}
          {...restProps}
          {...focusVisibleProps}
          as={renderAs}
          ref={ref}
        >
          {icon && <Icon icon={icon} mr={10} />}
          {tooltipText && (
            <StyledTooltip
              position="absolute"
              background={themeGet('colors.mineShaft')}
              color={themeGet('colors.white')}
              p="10px 15px"
              fontSize={['12px', '14px']}
              lineHeight={1.5}
              width={['160px', '200px']}
            >
              {tooltipText}
            </StyledTooltip>
          )}
          {overlayText && (
            <StyledOverlayText
              position="absolute"
              bg={themeGet('colors.tussock')}
              color={themeGet('colors.white')}
              textTransform="uppercase"
              fontSize="10px"
              lineHeight={1.2}
              borderRadius="3px"
              p="1px 6px"
              border="1px solid var(--color-tussock)"
              {...dependentStyles}
            >
              {overlayText}
            </StyledOverlayText>
          )}
          {children}
        </StyledButton>
      )
    }
  )

  Button.propTypes = {
    appearance: PropTypes.oneOf(['primary', 'cta', 'secondary', 'danger', 'outline']),
    icon: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    tooltipText: PropTypes.string,
    overlayText: PropTypes.string,
    children: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  }

  Button.defaultProps = {
    size: 'medium',
  }

  Button.displayName = 'Button'

  return Button
}

export default makeButton('button')
