import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import View from '../../helpers/View'

const StyledAvatar = styled(View)`
  object-fit: cover;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`

const Avatar = ({ onClick, src, name, size = '50px', ...restProps }) => (
  <StyledAvatar
    as="img"
    onClick={onClick}
    src={src}
    alt={name}
    title={name}
    width={size}
    height={size}
    flexShrink={0}
    display="block"
    borderRadius="50%"
    {...restProps}
  />
)

Avatar.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
}

export default Avatar
