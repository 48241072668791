// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Utils = require("./Utils.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var AvatarDefaultSvg = require("../../assets/images/elements/avatar-default.svg");

var _placeholder = AvatarDefaultSvg;

function from_key(widthOpt, heightOpt, key) {
  var width = widthOpt !== undefined ? widthOpt : 60;
  var height = heightOpt !== undefined ? heightOpt : 60;
  var partial_arg = true;
  var partial_arg$1 = height;
  return Core__Option.getOr(Core__Option.map(key, (function (eta) {
                    var param;
                    var param$1;
                    var param$2;
                    return Utils.makeAttachmentUrl(width, partial_arg$1, partial_arg, param, param$1, param$2, eta);
                  })), _placeholder);
}

var place_holder = from_key(undefined, undefined, undefined);

var placeholder = place_holder;

exports.from_key = from_key;
exports.place_holder = place_holder;
exports.placeholder = placeholder;
/* _placeholder Not a pure module */
